/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useContext } from "react";
// react-router components
import { useNavigate } from "react-router-dom";
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// Images
import LogoDCP from "assets/images/profile.jpg";

import dataState from "../../../../../context/data/dataContext";

export default function data() {
  const navigate = useNavigate();
  const DataContext = useContext(dataState);
  const { handleDiscapacitado, discapacitados, FormatoFecha, setValueIdCaratec } = DataContext;
  const [fechaRestar, setFechaRestar] = useState("");
  const buscarFecha = () => {
    let hoy = new Date();
    let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 7;
    let resta = hoy.getTime() - semanaEnMilisegundos; //getTime devuelve milisegundos de esa
    let ANNIO = new Date(resta).getFullYear();
    let f_mes = new Date(resta).getMonth() + 1;
    let f_dia = new Date(resta).getDay();
    let MES = f_mes >= 9 ? f_mes : "0" + f_mes;
    let DIAS = f_dia >= 9 ? f_dia : "0" + f_dia;
    let fechaDDF = ANNIO + "-" + MES + "-" + DIAS;
    setFechaRestar(fechaDDF);
  };

  useEffect(() => {
    handleDiscapacitado();
  }, []);

  useEffect(() => {
    buscarFecha();
  }, []);
  const caraterizar = (ID) => {
    setValueIdCaratec(ID);
    navigate("/caracterizar/" + ID);
  };

  const Discapacitado = ({ image, name, documento }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{documento}</MDTypography>
      </MDBox>
    </MDBox>
  );
  // console.log(fechaRestar);
  const ListaT = discapacitados
    .filter((x) => x.fecha >= fechaRestar)
    .map((item) => ({
      imagen: (
        <Discapacitado
          image={LogoDCP}
          name={`${item.nombres} ${item.apellidos}`}
          documento={`${item.Tipo_documento} ${item.documentos}`}
        />
      ),
      members: (
        <MDBox display="flex" py={1}>
          {`${item.Cuidador_nombres} ${item.Cuidador_apellidos}`}
        </MDBox>
      ),
      budget: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {FormatoFecha(item.fecha_aceptacion)}
        </MDTypography>
      ),
      completion: (
        <MDBox textAlign="left">
          <MDButton variant="outlined" color="info" onClick={() => caraterizar(item.ID)}>
            &nbsp;ADD CARACTERIZACION
          </MDButton>
        </MDBox>
      ),
    }));

  return {
    columns: [
      {
        Header: "Nombre de persona en condición de discapacidad",
        accessor: "imagen",
        width: "45%",
        align: "left",
      },
      { Header: "Nombre Cuidador", accessor: "members", width: "10%", align: "left" },
      { Header: "Fecha Aceptacion", accessor: "budget", align: "center" },
      { Header: "Acciones", accessor: "completion", align: "center", width: "10%" },
    ],

    rows: ListaT,
  };
}
