import {
  REGISTRO_USUARIOS,
  REGISTRO_USUARIOS_EXITOSO,
  REGISTRO_USUARIOS_ERROR,
  REGISTRO_USERPASS,
  REGISTRO_USERPASS_EXITOSO,
  REGISTRO_USERPASS_ERROR,
  ACTUALIZA_USUARIOS,
  ACTUALIZA_USUARIOS_EXITOSO,
  ACTUALIZA_USUARIOS_ERROR,
  DELETE_USUARIOS,
  DELETE_USUARIOS_EXITOSO,
  DELETE_USUARIOS_ERROR,
  REGISTRO_CARACTERIZACION,
  REGISTRO_CARACTERIZACION_EXITOSO,
  REGISTRO_CARACTERIZACION_ERROR,
  REGISTRO_DISCAPACITADO,
  REGISTRO_DISCAPACITADO_EXITOSO,
  REGISTRO_DISCAPACITADO_ERROR,
  REGISTRO_CUIDADOR,
  REGISTRO_CUIDADOR_EXITOSO,
  REGISTRO_CUIDADOR_ERROR,
  DELETE_CUIDADOR,
  DELETE_CUIDADOR_EXITOSO,
  DELETE_CUIDADOR_ERROR,
  OBTENER_USUARIOS,
  OBTENER_USUARIOS_SUCCESS,
  OBTENER_USUARIOS_ERROR,
  OBTENER_ROLUSER,
  OBTENER_ROLUSER_SUCCESS,
  OBTENER_ROLUSER_ERROR,
  OBTENER_DISCAPACITADO,
  OBTENER_DISCAPACITADO_EXITOSO,
  OBTENER_DISCAPACITADO_ERROR,
  DELETE_DISCAPACITADO,
  DELETE_DISCAPACITADO_EXITOSO,
  DELETE_DISCAPACITADO_ERROR,
  BUSCAR_DISCAPACITADO,
  BUSCAR_DISCAPACITADO_EXITOSO,
  BUSCAR_DISCAPACITADO_ERROR,
  OBTENER_CARACTERIZACION,
  OBTENER_CARACTERIZACION_EXITOSO,
  OBTENER_CARACTERIZACION_ERROR,
  OBTENER_MUNICIPIO,
  OBTENER_MUNICIPIO_SUCCESS,
  OBTENER_MUNICIPIO_ERROR,
  OBTENER_CORREGIMIENTO,
  OBTENER_CORREGIMIENTO_SUCCESS,
  OBTENER_BARRIO,
  OBTENER_BARRIO_SUCCESS,
  OBTENER_BARRIO_ERROR,
  OBTENER_TIPODOC,
  OBTENER_TIPODOC_SUCCESS,
  OBTENER_TIPODOC_ERROR,
  OBTENER_ESCOLAR,
  OBTENER_ESCOLAR_SUCCESS,
  OBTENER_ESCOLAR_ERROR,
  OBTENER_OSEXUAL,
  OBTENER_OSEXUAL_SUCCESS,
  OBTENER_OSEXUAL_ERROR,
  OBTENER_CUIDADOR,
  OBTENER_CUIDADOR_EXITOSO,
  OBTENER_CUIDADOR_ERROR,
  OBTENER_ETNIAS,
  OBTENER_ETNIAS_SUCCESS,
  OBTENER_ETNIAS_ERROR,
  OBTENER_PARENTESCO,
  OBTENER_PARENTESCO_SUCCESS,
  OBTENER_PARENTESCO_ERROR,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case OBTENER_USUARIOS_ERROR:
    case OBTENER_ROLUSER_ERROR:
    case OBTENER_CUIDADOR_ERROR:
    case OBTENER_DISCAPACITADO_ERROR:
    case OBTENER_MUNICIPIO_ERROR:
    case OBTENER_BARRIO_ERROR:
    case OBTENER_TIPODOC_ERROR:
    case OBTENER_ESCOLAR_ERROR:
    case REGISTRO_CUIDADOR_ERROR:
    case OBTENER_OSEXUAL_ERROR:
    case OBTENER_ETNIAS_ERROR:
    case OBTENER_PARENTESCO_ERROR:
    case OBTENER_CARACTERIZACION_ERROR:
    case REGISTRO_USUARIOS_ERROR:
    case REGISTRO_USERPASS_ERROR:
    case ACTUALIZA_USUARIOS_ERROR:
    case REGISTRO_CARACTERIZACION_ERROR:
    case REGISTRO_DISCAPACITADO_ERROR:
    case BUSCAR_DISCAPACITADO_ERROR:
    case DELETE_DISCAPACITADO_ERROR:
    case DELETE_CUIDADOR_ERROR:
    case DELETE_USUARIOS_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };
    case OBTENER_USUARIOS:
    case OBTENER_ROLUSER:
    case OBTENER_CUIDADOR:
    case OBTENER_DISCAPACITADO:
    case OBTENER_MUNICIPIO:
    case OBTENER_CORREGIMIENTO:
    case OBTENER_BARRIO:
    case OBTENER_ESCOLAR:
    case OBTENER_TIPODOC:
    case OBTENER_OSEXUAL:
    case OBTENER_ETNIAS:
    case OBTENER_PARENTESCO:
    case OBTENER_CARACTERIZACION:
    case BUSCAR_DISCAPACITADO:
      return {
        ...state,
        cargando: action.payload,
      };
    case OBTENER_USUARIOS_SUCCESS:
      return {
        ...state,
        usuarios: action.payload,
      };
    case OBTENER_ROLUSER_SUCCESS:
      return {
        ...state,
        perfiluser: action.payload,
      };
    case OBTENER_CUIDADOR_EXITOSO:
      return {
        ...state,
        cuidadores: action.payload,
      };
    case OBTENER_MUNICIPIO_SUCCESS:
      return {
        ...state,
        municipio: action.payload,
      };
    case OBTENER_CORREGIMIENTO_SUCCESS:
      return {
        ...state,
        corregimiento: action.payload,
      };
    case OBTENER_BARRIO_SUCCESS:
      return {
        ...state,
        barrio: action.payload,
      };
    case OBTENER_TIPODOC_SUCCESS:
      return {
        ...state,
        tipodoc: action.payload,
      };
    case OBTENER_OSEXUAL_SUCCESS:
      return {
        ...state,
        orentacionsexual: action.payload,
      };
    case OBTENER_ESCOLAR_SUCCESS:
      return {
        ...state,
        escolarida: action.payload,
      };
    case OBTENER_ETNIAS_SUCCESS:
      return {
        ...state,
        etnias: action.payload,
      };
    case OBTENER_PARENTESCO_SUCCESS:
      return {
        ...state,
        parentesco: action.payload,
      };
    case OBTENER_DISCAPACITADO_EXITOSO:
      return {
        ...state,
        discapacitados: action.payload,
      };
    case BUSCAR_DISCAPACITADO_EXITOSO:
      return {
        ...state,
        discapacitadoB: action.payload,
      };
    case REGISTRO_USUARIOS:
      return {
        ...state,
        enviando: action.payload,
      };
    case REGISTRO_USERPASS:
      return {
        ...state,
        enviando: action.payload,
      };
    case ACTUALIZA_USUARIOS:
      return {
        ...state,
        enviando: action.payload,
      };
    case REGISTRO_USUARIOS_EXITOSO:
      return {
        ...state,
        //usuarios: action.payload,
      };
    case REGISTRO_USERPASS_EXITOSO:
      return {
        ...state,
      };
    case ACTUALIZA_USUARIOS_EXITOSO:
      return {
        ...state,
      };
    case REGISTRO_CUIDADOR:
      return {
        ...state,
        enviando: action.payload,
      };
    case REGISTRO_CUIDADOR_EXITOSO:
      return {
        ...state,
        cuidadores: action.payload,
      };
    case REGISTRO_DISCAPACITADO:
      return {
        ...state,
        enviando: action.payload,
      };
    case REGISTRO_DISCAPACITADO_EXITOSO:
      return {
        ...state,
        discapacitados: action.payload,
      };
    case REGISTRO_CARACTERIZACION:
      return {
        ...state,
        enviando: action.payload,
      };
    case REGISTRO_CARACTERIZACION_EXITOSO:
      return {
        ...state,
        //caracterizacion: action.payload,
      };
    case OBTENER_CARACTERIZACION_EXITOSO:
      return {
        ...state,
        caracterizacion: action.payload,
      };
    case DELETE_USUARIOS:
      return {
        ...state,
        eliminar: action.payload,
      };
    case DELETE_USUARIOS_EXITOSO:
      return {
        ...state,
        usuarios: state.usuarios.filter((U) => U.ID_USER !== state.eliminar),
        eliminar: null,
      };

    case DELETE_DISCAPACITADO:
      return {
        ...state,
        eliminar: action.payload,
      };
    case DELETE_DISCAPACITADO_EXITOSO:
      return {
        ...state,
        discapacitados: state.discapacitados.filter((A) => A.ID !== state.eliminar),
        eliminar: null,
      };

    case DELETE_CUIDADOR:
      return {
        ...state,
        eliminar: action.payload,
      };
    case DELETE_CUIDADOR_EXITOSO:
      return {
        ...state,
        cuidadores: state.cuidadores.filter((A) => A.ID !== state.eliminar),
        eliminar: null,
      };
    default:
      return state;
  }
};
