import { useState, useEffect, forwardRef, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
//import FormInformation from "../../../../components/FormInformation";
import FormInformation from "../../cuidadores/components/FormInformationEdit";

import dataState from "../../../context/data/dataContext";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const DataContext = useContext(dataState);
  const { handleCuidador, setOpenFull, openFull, cuidadores, valueIdEditC, openFormdialog } =
    DataContext;

  useEffect(() => {
    handleCuidador();
  }, []);

  const handleClose = () => {
    setOpenFull(false);
  };

  const DataCuidador = cuidadores?.filter((x) => x.ID === valueIdEditC);
  return (
    <>
      <Dialog fullScreen open={openFull} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} style={{ backgroundColor: "#1a73e8" }}>
          <Toolbar style={{ color: "#fff" }}>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{ color: "#fff" }}
            >
              {openFormdialog === 0 ? "Detalles Cuidadores" : "Editar Cuidadores"}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon /> Cerrar
            </IconButton>
          </Toolbar>
        </AppBar>
        {openFormdialog === 0 ? (
          <List>
            <List></List>
            <Divider />
            <ListItemButton>
              <ListItemText primary="Default notification ringtone" secondary="Tethys" />
            </ListItemButton>
          </List>
        ) : (
          <FormInformation Cuidador={DataCuidador} IdEditC={valueIdEditC} />
        )}
      </Dialog>
    </>
  );
}
