import { useState, useEffect, useContext } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
//import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MDInput from "components/MDInput";
//import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";

function FormGeneral() {
  const { setTabForm, setTabValue } = useContext(dataState);
  const localStorageData = localStorage.getItem("I_GENERAL");
  const Data = localStorageData ? JSON.parse(localStorageData) : {};

  const [valueIG, setValueIG] = useState(Data.valueIG ? Data.valueIG : "");
  const [valueCD, setValueCD] = useState(Data.valueCD ? Data.valueCD : "");
  const [valueMO, setValueMO] = useState(Data.valueMO ? Data.valueMO : "");
  const [valueLA, setValueLA] = useState(Data.valueLA ? Data.valueLA : "");
  const [valueHO, setValueHO] = useState(Data.valueHO ? Data.valueHO : "");
  const [valueAE, setValueAE] = useState(Data.valueAE ? Data.valueAE : "");
  const [valueSG, setValueSG] = useState(Data.valueSG ? Data.valueSG : "");
  const [error, setError] = useState(false);
  const [Opcion, setNumOpcion] = useState(0);
  const [msgtext, setMsgtext] = useState(false);
  const [textomsg, setTextomsg] = useState("");
  const closeInfoMT = () => setMsgtext(false);

  useEffect(() => {
    if (Object.keys(Data).length > 0) {
      setNumOpcion(7);
    }
  }, [Data]);

  const handleRadioChangeIG = (event) => {
    setValueIG(event.target.value);
    if (valueIG === "") setNumOpcion(Opcion + 1);
  };
  const handleRadioChangeCD = (event) => {
    setValueCD(event.target.value);
    if (valueCD === "") setNumOpcion(Opcion + 1);
  };
  const handleRadioChangeMO = (event) => {
    setValueMO(event.target.value);
    if (valueMO === "") setNumOpcion(Opcion + 1);
  };
  const handleRadioChangeLA = (event) => {
    setValueLA(event.target.value);
    if (valueLA === "") setNumOpcion(Opcion + 1);
  };
  const handleRadioChangeHO = (event) => {
    setValueHO(event.target.value);
    if (valueHO === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeAE = (event) => {
    setValueAE(event.target.value);
    if (valueAE === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeSG = (event) => {
    setValueSG(event.target.value);
    if (valueSG === "") setNumOpcion(Opcion + 1);
  };

  const [datotexto, setDatoTexto] = useState({
    otroPE: Data?.datotexto?.otroPE ? Data.datotexto.otroPE : "",
    otroMO: Data?.datotexto?.otroMO ? Data.datotexto.otroMO : "",
    otroHO: Data?.datotexto?.otroHO ? Data.datotexto.otroHO : "",
    cual: Data?.datotexto?.cual ? Data.datotexto.cual : "",
    cargos: Data?.datotexto?.cargos ? Data.datotexto.cargos : "",
  });

  // Leer los datos del caja de texto
  const onChangeTexto = (e) => {
    setDatoTexto({
      ...datotexto,
      [e.target.name]: e.target.value,
    });
  };

  const handleAprobar = () => {
    const openinfo = () => setMsgtext(true);
    if (datotexto.otroPE === "" && valueIG === "OTRO") {
      setTextomsg("Escriba de que otra forma se capto persona en discapacidad ");
      openinfo();
      return false;
    }
    if (datotexto.otroMO === "" && valueMO === "SI") {
      setTextomsg("NO escribio el nombre de la Organización");
      openinfo();
      return false;
    }
    if (datotexto.otroHO === "" && valueHO === "OTROS") {
      setTextomsg("NO escribio otros gasto hogar");
      openinfo();
      return false;
    }
    if (datotexto.cual === "" && valueAE === "SI") {
      setTextomsg("NO escribio cual ayuda del gobierno");
      openinfo();
      return false;
    }
    if (datotexto.cargos === "") {
      setTextomsg("NO escribio cuantas personas tiene a su cargo");
      openinfo();
      return false;
    }

    const DataForm = {
      valueIG,
      valueCD,
      valueMO,
      valueLA,
      valueHO,
      valueAE,
      valueSG,
      datotexto,
    };

    localStorage.setItem("I_GENERAL", JSON.stringify(DataForm));
    setTabForm(1);
    setTabValue(1);
  };

  const renderInfoSB = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content={textomsg}
      open={msgtext}
      onClose={closeInfoMT}
      close={closeInfoMT}
    />
  );

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          COMO SE CAPTÓ LA PERSONA EN CONDICION DE DISCAPACIDAD
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <FormControl sx={{ m: 1 }} error={error} variant="standard">
          <RadioGroup
            aria-labelledby="demo-error-radios"
            defaultValue={valueIG}
            name="IG"
            value={valueIG}
            onChange={handleRadioChangeIG}
          >
            <FormControlLabel value="EN CASA" control={<Radio />} label="EN CASA" />
            <FormControlLabel
              value="SERVICIO DE SALUD COLECTIVO"
              control={<Radio />}
              label="SERVICIO DE SALUD COLECTIVO"
            />
            <FormControlLabel
              value="SERVICIOS DE REHABILITACIÓN"
              control={<Radio />}
              label="SERVICIOS DE REHABILITACIÓN"
            />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ m: 1 }} error={error} variant="standard">
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="IG"
            value={valueIG}
            onChange={handleRadioChangeIG}
          >
            <FormControlLabel
              value="REFERIDO POR LA ESE"
              control={<Radio />}
              label="REFERIDO POR LA ESE"
            />
            <FormControlLabel
              value="VIGILANCIA COMUNITARIA"
              control={<Radio />}
              label="VIGILANCIA COMUNITARIA"
            />
            <FormControlLabel
              value="POR LIDER COMUNITARIO"
              control={<Radio />}
              label="POR LIDER COMUNITARIO"
            />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ m: 1 }} error={error} variant="standard">
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="IG"
            value={valueIG}
            onChange={handleRadioChangeIG}
          >
            <FormControlLabel
              value="UNIDAD DE TRABAJO INFORMAL"
              control={<Radio />}
              label="UNIDAD DE TRABAJO INFORMAL"
            />
            <FormControlLabel value="INSTITUCIONAL" control={<Radio />} label="INSTITUCIONAL" />
            <FormControlLabel value="ESPACIO PÚBLICO" control={<Radio />} label="ESPACIO PÚBLICO" />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ m: 1 }} error={error} variant="standard" style={{ width: "450px" }}>
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="IG"
            value={valueIG}
            onChange={handleRadioChangeIG}
          >
            <Grid xs={6} md={4}>
              <FormControlLabel value="OTRO" control={<Radio />} label="OTRO/CUAL" />
            </Grid>
            <Grid xs={6} md={12}>
              <MDInput
                type="text"
                variant="standard"
                name="otroPE"
                value={datotexto.otroPE}
                onChange={onChangeTexto}
                disabled={valueIG === "OTRO" ? false : true}
                fullWidth
              />
            </Grid>
          </RadioGroup>
        </FormControl>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          IDENTIFICACION DE PERSONA EN CONDICION DE DISCAPACIDAD
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={3}>
            <MDTypography variant="h6">CUENTA CON CERTIFICADO DE DISCAPACIDAD</MDTypography>
          </Grid>
          <Grid item xs={2} md={2} style={{ marginTop: "-5px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="CD"
              value={valueCD}
              onChange={handleRadioChangeCD}
            >
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={3}>
            <MDTypography variant="h6">ES MIEMBRO DE UNA ORGANIZACIÓN</MDTypography>
          </Grid>
          <Grid item xs={2} md={2} style={{ marginTop: "-5px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="MO"
              value={valueMO}
              onChange={handleRadioChangeMO}
            >
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </Grid>
          <Grid item xs={2} md={3}>
            <MDTypography variant="h6">NOMBRE DE LA ORGANIZACIÓN:</MDTypography>
          </Grid>
          <Grid item xs={1} md={4} style={{ marginTop: "0px", marginLeft: "-120px" }}>
            <MDInput
              type="text"
              variant="standard"
              name="otroMO"
              value={datotexto.otroMO}
              onChange={onChangeTexto}
              disabled={valueMO === "SI" ? false : true}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "10px" }}>
          <Grid item xs={2} md={3} style={{ paddingRight: "0px" }}>
            <MDTypography variant="h6">SITUACION LABORAL ACTUALMENTE: </MDTypography>
          </Grid>
          <Grid item xs={2} md={3} style={{ marginTop: "-5px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="LA"
              value={valueLA}
              onChange={handleRadioChangeLA}
            >
              <FormControlLabel value="EMPLEADO" control={<Radio />} label="EMPLEADO" />
              <FormControlLabel value="DESEMPLEADO" control={<Radio />} label="DESEMPLEADO" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={12} md={4} style={{ paddingRight: "0px" }}>
            <MDTypography variant="h6">QUIEN APORTA PARA LOS GASTOS EN EL HOGAR:</MDTypography>
          </Grid>
          <Grid item xs={12} md={5} style={{ marginTop: "-5px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="HO"
              value={valueHO}
              onChange={handleRadioChangeHO}
            >
              <FormControlLabel value="PADRES/MADRES" control={<Radio />} label="PADRES/MADRES" />
              <FormControlLabel value="HIJO(A)" control={<Radio />} label="HIJO(A)" />
              <FormControlLabel value="FAMILIARES" control={<Radio />} label="FAMILIARES" />
              <FormControlLabel value="OTROS" control={<Radio />} label="OTROS/QUIENES" />
            </RadioGroup>
          </Grid>
          <Grid item xs={4} md={3} style={{ marginTop: "-1px", marginLeft: "-80px" }}>
            <MDInput
              type="text"
              variant="standard"
              name="otroHO"
              value={datotexto.otroHO}
              onChange={onChangeTexto}
              disabled={valueHO === "OTROS" ? false : true}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={12} md={4} style={{ paddingRight: "0px" }}>
            <MDTypography variant="h6">SEGURIDAD SOCIAL:</MDTypography>
          </Grid>
          <Grid item xs={12} md={6} style={{ marginTop: "-5px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="SG"
              value={valueSG}
              onChange={handleRadioChangeSG}
            >
              <FormControlLabel value="CONTRIBUTIVO" control={<Radio />} label="CONTRIBUTIVO" />
              <FormControlLabel value="SUBSIDIADO" control={<Radio />} label="SUBSIDIADO" />
              <FormControlLabel
                value="RÉGIMEN ESPECIAL "
                control={<Radio />}
                label="RÉGIMEN ESPECIAL"
              />
              <FormControlLabel value="NO ASEGURADO" control={<Radio />} label="NO ASEGURADO" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "10px" }}>
          <Grid item xs={2} md={4}>
            <MDTypography variant="h6">
              RECIBE ALGÚN TIPO DE AYUDA ECONÓMICA DEL GOBIERNO?
            </MDTypography>
          </Grid>
          <Grid item xs={2} md={2} style={{ marginTop: "-5px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="AE"
              value={valueAE}
              onChange={handleRadioChangeAE}
            >
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </Grid>
          <Grid item xs={2} md={1}>
            <MDTypography variant="h6">CUAL:</MDTypography>
          </Grid>
          <Grid item xs={4} md={5} style={{ marginTop: "0px", marginLeft: "-75px" }}>
            <MDInput
              type="text"
              variant="standard"
              name="cual"
              value={datotexto.cual}
              onChange={onChangeTexto}
              disabled={valueAE === "SI" ? false : true}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <MDTypography variant="h6">CUANTAS PERSONAS TIENE A SU CARGO:</MDTypography>
          </Grid>
          <Grid item xs={4} md={2}>
            <MDInput
              type="text"
              variant="standard"
              name="cargos"
              value={datotexto.cargos}
              onChange={onChangeTexto}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#fff", textAlign: "center" }}>
        <MDTypography variant="h2" color="white">
          <MDButton
            color="info"
            onClick={handleAprobar}
            style={{ float: "right" }}
            disabled={Opcion === 7 ? false : true}
          >
            Aprobar y Seguir &nbsp;
            <Icon fontSize="large" sx={{ fontWeight: "bold" }}>
              double_arrow_sharp
            </Icon>
          </MDButton>
        </MDTypography>
      </MDBox>
      {renderInfoSB}
    </Card>
  );
}
export default FormGeneral;
