/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import UsuarioNavbar from "examples/Navbars/UsuarioNavbar";
import Footer from "examples/Footer";
import FormInformation from "./components/FormInformation";
import MDButton from "components/MDButton";
// Data
function Form_discapacitados() {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <UsuarioNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Agregar Cuidador
                  <MDButton
                    variant="outlined"
                    color="white"
                    onClick={() => navigate("/cuidadores")}
                    style={{ float: "right" }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                    &nbsp;Cerrar
                  </MDButton>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {" "}
                <FormInformation />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Form_discapacitados;
