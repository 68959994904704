import { Login, Axios } from "../config/clienteAxios";

export const postLogin = async (Objdata) => {
  try {
    const { data } = await Login.post("/auth/login", Objdata, {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "X-Frame-Options": "sameorigin",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const postUsuarios = async (Objdata) => {
  try {
    const { data } = await Axios.post(`/usuarios/create-usuario`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const putUsuarios = async (Objdata, Id) => {
  try {
    const { data } = await Axios.put(`/usuarios/edit-usuario/${Id}`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const putUsuariosPass = async (Objdata, Id) => {
  try {
    const { data } = await Axios.patch(`/usuarios/editpass-usuario/${Id}`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const postCuidadores = async (Objdata) => {
  try {
    const { data } = await Axios.post(`/cuidador/create-cuidador`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const putCuidadores = async (Objdata, Id) => {
  try {
    const { data } = await Axios.put(`/cuidador/edit-cuidador/${Id}`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const postDiscapacitados = async (Objdata) => {
  try {
    const { data } = await Axios.post(`/discapacitados/create-discapacitado`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const putDiscapacitados = async (Objdata, Id) => {
  try {
    const { data } = await Axios.put(`/discapacitados/edit-discapacitado/${Id}`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const postDiscapacitadoB = async (Objdata) => {
  try {
    const { data } = await Login.post(`/discapacitados/search-discapacitado`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const postCuidadorParam = async (Objdata) => {
  try {
    const { data } = await Axios.post(`/cuidador/search-cuidadorDocNombres`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const postDiscapacitadoParam = async (Objdata) => {
  try {
    const { data } = await Axios.post(`/discapacitados/search-discapacitadoParam`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const postCaracterizacion = async (Objdata) => {
  try {
    const { data } = await Axios.post(`/caracterizacion/create-caracterizacion`, Objdata);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};
