/* eslint-disable react/jsx-key */
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { InputLabel, NativeSelect } from "@mui/material";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import dataState from "../../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";

function UsuarioEditForm(Props) {
  const DataContext = useContext(dataState);
  const datos = Props.Data;
  const {
    perfiluser,
    handlePerfilUser,
    handleSubmitEditUsuario,
    handleUsuarios,
    DatoEditUser,
    enviando,
    // msgSB,
    // errorSB,
    setErrorSB,
    setInfoEditUSER,
    // infoEditUSER,
    // infoEditSB,
  } = DataContext;

  const closeErrorSB = () => setErrorSB(false);
  const closeInfoSB = () => setInfoEditUSER(false);

  const [controller] = useMaterialUIController();
  const [Dato, setDato] = useState([]);
  const [Id, setDatoId] = useState(0);
  const [Id_perfil, setDatoRoll] = useState(0);
  const [Usuario, setDatoUser] = useState("");
  const [Password, setDatoPass] = useState("");
  const [Documentos, setDatodoc] = useState("");
  const [Nombres, setDatonom] = useState("");
  const [Estado, setDatoEst] = useState("");
  //const { darkMode } = controller;

  useEffect(() => {
    handlePerfilUser();
  }, []);

  useEffect(() => {
    if (enviando === false) {
      handleUsuarios();
    }
  }, [enviando]);

  useEffect(() => {
    handleUsuarios();
  }, []);

  useEffect(() => {
    setDato(datos?.filter((x) => parseInt(x.ID_USER) === parseInt(DatoEditUser)));
    setDatoId(DatoEditUser);
  }, [DatoEditUser]);

  useEffect(() => {
    setDatoRoll(Dato[0]?.ID_ROLL);
    setDatoUser(Dato[0]?.USERNAME);
    setDatodoc(Dato[0]?.DOCUMENTO);
    setDatonom(Dato[0]?.NOMBRES);
    setDatoEst(Dato[0]?.ESTADO);
  }, [Dato]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const datosform = {
      Id_perfil,
      Documentos,
      Nombres,
      Usuario,
      Estado: parseInt(Estado),
      Password: Password !== "" ? Password : 0,
    };
    console.log(datosform);
    handleSubmitEditUsuario(datosform, Id);
  };

  return (
    <Card>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Formulario de Usuarios
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDBox xs={12} md={12} component="form" role="form" onSubmit={handleSubmit}>
                    <MDBox pt={4} pb={3} px={3}>
                      <Grid container spacing={1}>
                        <Grid item xs={6} md={2}>
                          <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Perfil Usuario
                          </InputLabel>
                          <NativeSelect
                            defaultValue={""}
                            inputProps={{ name: "Id_perfil", id: "Id_perfil" }}
                            value={Id_perfil}
                            onChange={(e) => setDatoRoll(e.target.value)}
                            fullWidth
                          >
                            <option key={"validationCustom0013"} value={""}>
                              Seleccione...
                            </option>
                            {perfiluser?.length === 0 ? (
                              <option key={"validationCustom0014"} value={""}>
                                Seleccione...
                              </option>
                            ) : (
                              perfiluser?.map((item) => (
                                <option key={item.ID} value={item.ID}>
                                  {item.NOMBRE}
                                </option>
                              ))
                            )}
                          </NativeSelect>
                          {/*   {formik.touched.Id_perfil && formik.errors.Id_perfil ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Id_perfil} </p>
                            </MDTypography>
                          ) : null} */}
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <MDInput
                            type="text"
                            label="Usuario/Email"
                            variant="standard"
                            name="Usuario"
                            value={Usuario}
                            // onChange={formik.handleChange}
                            onChange={(e) => setDatoUser(e.target.value)}
                            fullWidth
                          />
                          {/*   {formik.touched.Usuario && formik.errors.Usuario ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Usuario} </p>
                            </MDTypography>
                          ) : null} */}
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <MDInput
                            type="text"
                            label="Nueva Contraseña"
                            variant="standard"
                            name="Password"
                            value={Password}
                            onChange={(e) => setDatoPass(e.target.value)}
                            fullWidth
                          />
                          {/*   {formik.touched.Usuario && formik.errors.Usuario ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Usuario} </p>
                            </MDTypography>
                          ) : null} */}
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Estado
                          </InputLabel>
                          <NativeSelect
                            defaultValue={""}
                            inputProps={{ name: "Estado", id: "Estado" }}
                            value={Estado}
                            onChange={(e) => setDatoEst(e.target.value)}
                            fullWidth
                          >
                            <option key={"validationCustom601"} value={""}>
                              Seleccione...
                            </option>
                            <option value={"1"}>{"Activado"}</option>
                            <option value={"0"}>{"Desactivado"}</option>
                          </NativeSelect>
                          {/* {formik.touched.Estado && formik.errors.Estado ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Estado} </p>
                            </MDTypography>
                          ) : null} */}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} style={{ marginTop: "12px" }}>
                        <Grid item xs={6} md={4}>
                          <MDInput
                            type="text"
                            label="Documento(Cedula o Nit)"
                            variant="standard"
                            id="Documentos"
                            name="Documentos"
                            value={Documentos}
                            onChange={(e) => setDatodoc(e.target.value)}
                            fullWidth
                          />
                          {/*  {formik.touched.Documentos && formik.errors.Documentos ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Documentos} </p>
                            </MDTypography>
                          ) : null} */}
                        </Grid>
                        <Grid item xs={6} md={7}>
                          <MDInput
                            type="text"
                            label="Nombres/Razon Social"
                            variant="standard"
                            name="Nombres"
                            value={Nombres}
                            onChange={(e) => setDatonom(e.target.value)}
                            fullWidth
                          />
                          {/* {formik.touched.Nombres && formik.errors.Nombres ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Nombres} </p>
                            </MDTypography>
                          ) : null} */}
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox
                      pt={2}
                      px={2}
                      display="flex-1"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDButton type="submit" variant="gradient" color="success" fullWidth>
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        {enviando === false ? "Editar Usuario" : "Enviando Usuario..."}
                      </MDButton>
                      <MDTypography variant="h6" fontWeight="medium">
                        &nbsp;
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default UsuarioEditForm;
