export const REGISTRO_CUIDADOR = "REGISTRO_CUIDADOR";
export const REGISTRO_CUIDADOR_EXITOSO = "REGISTRO_CUIDADOR_EXITOSO";
export const REGISTRO_CUIDADOR_ERROR = "REGISTRO_CUIDADOR_ERROR";

export const OBTENER_CUIDADOR = "OBTENER_CUIDADOR";
export const OBTENER_CUIDADOR_EXITOSO = "OBTENER_CUIDADOR_EXITOSO";
export const OBTENER_CUIDADOR_ERROR = "OBTENER_CUIDADOR_ERROR";

export const DELETE_CUIDADOR = "DELETCUIDADOR";
export const DELETE_CUIDADOR_EXITOSO = "DELETCUIDADOR_EXITOSO";
export const DELETE_CUIDADOR_ERROR = "OBTENER_CUIDADOR_ERROR";
