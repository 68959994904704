import { useState, useContext, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";

function PlatformSettings() {
  const { setTabForm, setTabValue } = useContext(dataState);
  const localStorageData = localStorage.getItem("R_PRIMARIA");
  const Data = localStorageData ? JSON.parse(localStorageData) : {};

  const [valueRP, setValueRP] = useState(Data.valueRP ? Data.valueRP : "");
  const [valuePP, setValuePP] = useState(Data.valuePP ? Data.valuePP : "");
  const [valueQC, setValueQC] = useState(Data.valueQC ? Data.valueQC : "");
  const [valueRA, setValueRA] = useState(Data.valueRA ? Data.valueRA : "");
  const [valueVC, setValueVC] = useState(Data.valueVC ? Data.valueVC : "");
  const [valueIF, setValueIF] = useState(Data.valueIF ? Data.valueIF : "");
  const [error, setError] = useState(false);
  const [Opcion, setNumOpcion] = useState(0);
  const [Opcion_1, setNumOpcionuno] = useState(0);
  const [msgtext, setMsgtext] = useState(false);
  const [textomsg, setTextomsg] = useState("");
  const closeInfoMT = () => setMsgtext(false);

  useEffect(() => {
    if (Object.keys(Data).length > 0) {
      setNumOpcion(6);
      setNumOpcionuno(1);
    }
  }, [Data]);

  const [stateAT, setStateAT] = useState({
    ATECNICA_1: Data?.datoAT?.ATECNICA_1 ? true : false,
    ATECNICA_2: Data?.datoAT?.ATECNICA_2 ? true : false,
    ATECNICA_3: Data?.datoAT?.ATECNICA_3 ? true : false,
    ATECNICA_4: Data?.datoAT?.ATECNICA_4 ? true : false,
    ATECNICA_5: Data?.datoAT?.ATECNICA_5 ? true : false,
    ATECNICA_6: Data?.datoAT?.ATECNICA_6 ? true : false,
  });
  const datoAT = {
    ATECNICA_1: stateAT.ATECNICA_1 === true ? "MOVILIDAD" : "",
    ATECNICA_2: stateAT.ATECNICA_2 === true ? "AUDICIÓN" : "",
    ATECNICA_3: stateAT.ATECNICA_3 === true ? "VISIÓN" : "",
    ATECNICA_4: stateAT.ATECNICA_4 === true ? "ADAPTACIONES PARA LA VIVIENDA" : "",
    ATECNICA_5: stateAT.ATECNICA_5 === true ? "DESARROLLO DEL CUIDADO PERSONAL" : "",
    ATECNICA_6: stateAT.ATECNICA_6 === true ? "OTROL" : "",
  };

  const handleChangeAT = (event) => {
    setStateAT({
      ...stateAT,
      [event.target.name]: event.target.checked,
    });
    setNumOpcionuno(1);
  };

  const handleRadioChangeRP = (event) => {
    setValueRP(event.target.value);
    if (valueRP === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangePP = (event) => {
    setValuePP(event.target.value);
    if (valuePP === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeQC = (event) => {
    setValueQC(event.target.value);
    if (valueQC === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeRA = (event) => {
    setValueRA(event.target.value);
    if (valueRA === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeVC = (event) => {
    setValueVC(event.target.value);
    if (valueVC === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeIF = (event) => {
    setValueIF(event.target.value);
    if (valueIF === "") setNumOpcion(Opcion + 1);
  };
  //console.log(Opcion);
  const [datotexto, setDatoTexto] = useState({
    otroPP: Data?.datotexto?.otroPP ? Data.datotexto.otroPP : "",
    otroAT: Data?.datotexto?.otroAT ? Data.datotexto.otroAT : "",
    otroVC: Data?.datotexto?.otroVC ? Data.datotexto.otroVC : "",
    otroIF: Data?.datotexto?.otroIF ? Data.datotexto.otroIF : "",
    otroRP: Data?.datotexto?.otroRP ? Data.datotexto.otroRP : "",
  });

  // Leer los datos del caja de texto
  const onChangeTexto = (e) => {
    setDatoTexto({
      ...datotexto,
      [e.target.name]: e.target.value,
    });
  };
  const handleAprobar = () => {
    const openinfo = () => setMsgtext(true);
    if (datotexto.otroPP === "") {
      setTextomsg("NO escribio con cuantas horas cuenta para su cuidado");
      openinfo();
      return false;
    }

    if (datotexto.otroRP === "" && valueRP === "OTRO/CUAL") {
      setTextomsg("No Escribio otra poblacion difrencial y de inclución");
      openinfo();
      return false;
    }
    if (datotexto.otroVC === "" && valueVC === "SI") {
      setTextomsg("NO escribio cual victima de conflicto armado");
      openinfo();
      return false;
    }
    if (datotexto.otroIF === "" && valueIF === "OTRO/CUAL") {
      setTextomsg("NO escribio otra pobalcion de inclución por oficio");
      openinfo();
      return false;
    }

    if (Opcion_1 === 0) {
      setTextomsg("No selecciono ninguna ayuda técnica");
      openinfo();
      return false;
    }

    if (datotexto.otroAT === "" && stateAT.ATECNICA_7 === true) {
      setTextomsg("No escribio otra ayuda técnica");
      openinfo();
      return false;
    }

    const DataForm = {
      valueRP,
      valuePP,
      valueQC,
      valueRA,
      valueVC,
      valueIF,
      datoAT,
      datotexto,
    };
    // console.log(DataForm);
    localStorage.setItem("R_PRIMARIA", JSON.stringify(DataForm));
    //console.log(localStorage.getItem("R_PRIMARIA"));
    setTabForm(5);
    setTabValue(5);
  };

  const renderInfoSB = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content={textomsg}
      open={msgtext}
      onClose={closeInfoMT}
      close={closeInfoMT}
    />
  );

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          RELACIONES CON RED PRIMARIA
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={4}>
            <MDTypography variant="h6">REQUIERE AYUDA PERMANENTE DE OTRA PERSONA:</MDTypography>
          </Grid>
          <Grid item xs={2} md={2} style={{ marginTop: "-5px", marginLeft: "0px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="PP"
              value={valuePP}
              onChange={handleRadioChangePP}
            >
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </Grid>
          <Grid item xs={0} md={5}>
            <MDTypography variant="h6">
              CUANTAS HORAS DEL DÍA CUENTA CON AYUDA PARA SU CUIDADO:
            </MDTypography>
          </Grid>
          <Grid item xs={1} md={0} style={{ marginTop: "0px", marginLeft: "-100px" }}>
            <MDInput
              type="text"
              variant="standard"
              name="otroPP"
              value={datotexto.otroPP}
              onChange={onChangeTexto}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={2}>
            <MDTypography variant="h6">QUIEN LO CUIDA:</MDTypography>
          </Grid>
          <Grid item xs={2} md={8} style={{ marginTop: "-5px", marginLeft: "0px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="QC"
              value={valueQC}
              onChange={handleRadioChangeQC}
            >
              <FormControlLabel value="MADRE" control={<Radio />} label="MADRE" />
              <FormControlLabel value="PADRE" control={<Radio />} label="PADRE" />
              <FormControlLabel
                value="COMPAÑERO(A) PERMANENTE"
                control={<Radio />}
                label="COMPAÑERO(A) PERMANENTE"
              />
              <FormControlLabel value="HIJO(A)" control={<Radio />} label="HIJO(A)" />
              <FormControlLabel value="OTRO FAMILIAR" control={<Radio />} label="OTRO FAMILIAR" />
              <FormControlLabel value="OTRA PERSONA" control={<Radio />} label="OTRA PERSONA" />
              <FormControlLabel value="NINGUNA" control={<Radio />} label="NINGUNA" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={4}>
            <MDTypography variant="h6">
              REQUIERE DE AYUDAS O DISPOSITIVOS PARA SU ASISTENCIA:
            </MDTypography>
          </Grid>
          <Grid item xs={2} md={2} style={{ marginTop: "-5px", marginLeft: "0px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="RA"
              value={valueRA}
              onChange={handleRadioChangeRA}
            >
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={2}>
            <MDTypography variant="h6">AYUDA TÉCNICA PARA LA:</MDTypography>
          </Grid>
          <Grid item xs={2} md={10} style={{ marginTop: "-5px", marginLeft: "0px" }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateAT.ATECNICA_1}
                    onChange={handleChangeAT}
                    name="ATECNICA_1"
                  />
                }
                label="MOVILIDAD"
                labelPlacement="MOVILIDAD"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateAT.ATECNICA_2}
                    onChange={handleChangeAT}
                    name="ATECNICA_2"
                  />
                }
                label="AUDICIÓN"
                labelPlacement="AUDICIÓN"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateAT.ATECNICA_3}
                    onChange={handleChangeAT}
                    name="ATECNICA_3"
                  />
                }
                label="VISIÓN"
                labelPlacement="VISIÓN"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateAT.ATECNICA_4}
                    onChange={handleChangeAT}
                    name="ATECNICA_4"
                  />
                }
                label="ADAPTACIONES PARA LA VIVIENDA"
                labelPlacement="ADAPTACIONES PARA LA VIVIENDA"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateAT.ATECNICA_5}
                    onChange={handleChangeAT}
                    name="ATECNICA_5"
                  />
                }
                label="DESARROLLO DEL CUIDADO PERSONAL"
                labelPlacement="DESARROLLO DEL CUIDADO PERSONAL"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateAT.ATECNICA_6}
                    onChange={handleChangeAT}
                    name="ATECNICA_6"
                  />
                }
                label="OTRO/CUAL:"
                labelPlacement="OTRO/CUAL"
              />
            </FormGroup>
            <MDInput
              type="text"
              variant="standard"
              name="otroAT"
              value={datotexto.otroAT}
              onChange={onChangeTexto}
              disabled={stateAT.ATECNICA_6 === true ? false : true}
              style={{ float: "right", marginTop: "-10px", width: "25%" }}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          POBLACIONES DIFERENCIALES Y DE INCLUSIÓN
        </MDTypography>
      </MDBox>
      {/*BLOQUE  */}
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <FormControl sx={{ m: 1 }} error={error} variant="standard">
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="RP"
            value={valueRP}
            onChange={handleRadioChangeRP}
          >
            <FormControlLabel
              value="SITUACIÓN DE PROTECCIÓN ESPECIAL"
              control={<Radio />}
              label="SITUACIÓN DE PROTECCIÓN ESPECIAL"
            />
            <FormControlLabel
              value="ADOLESCENTE EN EL SIS. DE RESPONSABILIDAD PENAL"
              control={<Radio />}
              label="ADOLESCENTE EN EL SIS. DE RESPONSABILIDAD PENAL"
            />
            <FormControlLabel
              value="PERSONA QUE SE ENCUENTRA AL CUIDADO DE TERCEROS"
              control={<Radio />}
              label="PERSONA QUE SE ENCUENTRA AL CUIDADO DE TERCEROS"
            />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ m: 1 }} error={error} variant="standard">
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="RP"
            value={valueRP}
            onChange={handleRadioChangeRP}
          >
            <FormControlLabel value="REINSERTADO" control={<Radio />} label="REINSERTADO" />
            <FormControlLabel
              value="MENOR TRABAJADOR(A)"
              control={<Radio />}
              label="MENOR TRABAJADOR(A)"
            />
            <FormControlLabel
              value="POBLACIÓN DESPLAZADA"
              control={<Radio />}
              label="POBLACIÓN DESPLAZADA"
            />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ m: 1 }} error={error} variant="standard">
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="RP"
            value={valueRP}
            onChange={handleRadioChangeRP}
          >
            <FormControlLabel
              value="EN SITUACIÓN DE VULNERACIÓN"
              control={<Radio />}
              label="EN SITUACIÓN DE VULNERACIÓN"
            />
            <FormControlLabel
              value="HABITANTE DE CALLE"
              control={<Radio />}
              label="HABITANTE DE CALLE"
            />
            <FormControlLabel
              value="EN SITUACIÓN DE ABANDONO"
              control={<Radio />}
              label="EN SITUACIÓN DE ABANDONO"
            />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ m: 1 }} error={error} variant="standard" style={{ width: "450px" }}>
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="RP"
            value={valueRP}
            onChange={handleRadioChangeRP}
          >
            <Grid xs={6} md={8}>
              <FormControlLabel
                value="NINGUNA DE LAS ANTERIORES"
                control={<Radio />}
                label="NINGUNA DE LAS ANTERIORES"
              />
              <FormControlLabel value="OTRO/CUAL" control={<Radio />} label="OTRO/CUAL" />
            </Grid>
            <Grid xs={6} md={12}>
              <MDInput
                type="text"
                variant="standard"
                name="otroRP"
                value={datotexto.otroRP}
                onChange={onChangeTexto}
                disabled={valueRP === "OTRO/CUAL" ? false : true}
                fullWidth
              />
            </Grid>
          </RadioGroup>
        </FormControl>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={4}>
            <MDTypography variant="h6">VÍCTIMA DE CONFLICTO ARMADO:</MDTypography>
          </Grid>
          <Grid item xs={2} md={2} style={{ marginTop: "-5px", marginLeft: "-35px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="VC"
              value={valueVC}
              onChange={handleRadioChangeVC}
            >
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </Grid>
          <Grid item xs={4} md={4} style={{ marginTop: "-1px", marginLeft: "-120px" }}>
            <MDTypography variant="h6">CUAL:</MDTypography>
            <MDInput
              type="text"
              variant="standard"
              name="otroVC"
              value={datotexto.otroVC}
              onChange={onChangeTexto}
              disabled={valueVC === "SI" ? false : true}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={3}>
            <MDTypography variant="h6">POBLACIÓN DE INCLUISÓN POR OFICIO:</MDTypography>
          </Grid>
          <Grid item xs={2} md={8} style={{ marginTop: "-5px", marginLeft: "0px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="IF"
              value={valueIF}
              onChange={handleRadioChangeIF}
            >
              <FormControlLabel value="RECICLADOR" control={<Radio />} label="RECICLADOR" />
              <FormControlLabel value="CARRETERO" control={<Radio />} label="CARRETERO" />
              <FormControlLabel
                value="VENDEDOR AMBULANTE"
                control={<Radio />}
                label="VENDEDOR AMBULANTE"
              />
              <FormControlLabel
                value="EN EJERCICIO DE PROSTITUCIÓN"
                control={<Radio />}
                label="EN EJERCICIO DE PROSTITUCIÓN"
              />
              <FormControlLabel value="OTRO/CUAL" control={<Radio />} label="OTRO/CUAL:" />
            </RadioGroup>
            <MDInput
              type="text"
              variant="standard"
              name="otroIF"
              value={datotexto.otroIF}
              onChange={onChangeTexto}
              disabled={valueIF === "OTRO/CUAL" ? false : true}
              style={{ float: "right", marginTop: "-5px", width: "25%" }}
              // fullWidth
            />
            {/*   <Grid item xs={4} md={12} style={{ marginTop: "-1px", marginLeft: "0px" }}>
              <MDInput
                type="text"
                variant="standard"
                name="otroIF"
                value={datotexto.otroIF}
                onChange={onChangeTexto}
                disabled={valueIF === "OTRO/CUAL" ? false : true}
                fullWidth
              />
            </Grid> */}
          </Grid>
        </Grid>
      </MDBox>

      <MDBox p={2} style={{ backgroundColor: "#fff", textAlign: "center" }}>
        <MDTypography variant="h2" color="white">
          <MDButton
            color="info"
            onClick={handleAprobar}
            style={{ float: "right" }}
            disabled={Opcion === 6 && Opcion_1 === 1 ? false : true}
          >
            Aprobar y Terminar &nbsp;
            <Icon fontSize="large" sx={{ fontWeight: "bold" }}>
              double_arrow_sharp
            </Icon>
          </MDButton>
        </MDTypography>
      </MDBox>
      {renderInfoSB}
    </Card>
  );
}

export default PlatformSettings;
