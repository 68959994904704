export const REGISTRO_DISCAPACITADO = "REGISTRO_DISCAPACITADO";
export const REGISTRO_DISCAPACITADO_EXITOSO = "REGISTRO_DISCAPACITADO_EXITOSO";
export const REGISTRO_DISCAPACITADO_ERROR = "REGISTRO_DISCAPACITADO_ERROR";

export const OBTENER_DISCAPACITADO = "OBTENER_DISCAPACITADO";
export const OBTENER_DISCAPACITADO_EXITOSO = "OBTENER_DISCAPACITADO_EXITOSO";
export const OBTENER_DISCAPACITADO_ERROR = "OBTENER_DISCAPACITADO_ERROR";

export const BUSCAR_DISCAPACITADO = "BUSCAR_DISCAPACITADO";
export const BUSCAR_DISCAPACITADO_EXITOSO = "BUSCAR_DISCAPACITADO_EXITOSO";
export const BUSCAR_DISCAPACITADO_ERROR = "BUSCAR_DISCAPACITADO_ERROR";

export const REGISTRO_CARACTERIZACION = "REGISTRO_CARACTERIZACION";
export const REGISTRO_CARACTERIZACION_EXITOSO = "REGISTRO_CARACTERIZACION_EXITOSO";
export const REGISTRO_CARACTERIZACION_ERROR = "REGISTRO_CARACTERIZACION_ERROR";

export const OBTENER_CARACTERIZACION = "OBTENER_CARACTERIZACION";
export const OBTENER_CARACTERIZACION_EXITOSO = "OBTENER_CARACTERIZACION_EXITOSO";
export const OBTENER_CARACTERIZACION_ERROR = "OBTENER_CARACTERIZACION_ERROR";

export const DELETE_DISCAPACITADO = "DELETE_DISCAPACITADO";
export const DELETE_DISCAPACITADO_EXITOSO = "DELETE_DISCAPACITADO_EXITOSO";
export const DELETE_DISCAPACITADO_ERROR = "OBTENER_CARACTERIZACION_ERROR";
