export const OBTENER_MUNICIPIO = "OBTENER_MUNICIPIO";
export const OBTENER_MUNICIPIO_SUCCESS = "OBTENER_MUNICIPIO_SUCCESS";
export const OBTENER_MUNICIPIO_ERROR = "OBTENER_MUNICIPIO_ERROR";

export const OBTENER_CORREGIMIENTO = "OBTENER_CORREGIMIENTO";
export const OBTENER_CORREGIMIENTO_SUCCESS = "OBTENER_CORREGIMIENTO_SUCCESS";
export const OBTENER_CORREGIMIENTO_ERROR = "OBTENER_CORREGIMIENTO_ERROR";

export const OBTENER_BARRIO = "OBTENER_BARRIO";
export const OBTENER_BARRIO_SUCCESS = "OBTENER_BARRIO_SUCCESS";
export const OBTENER_BARRIO_ERROR = "OBTENER_BARRIO_ERROR";

export const OBTENER_TIPODOC = "OBTENER_TIPODOC";
export const OBTENER_TIPODOC_SUCCESS = "OBTENER_TIPODOC_SUCCESS";
export const OBTENER_TIPODOC_ERROR = "OBTENER_TIPODOC_ERROR";

export const OBTENER_ESCOLAR = "OBTENER_BESCOLAR";
export const OBTENER_ESCOLAR_SUCCESS = "OBTENER_ESCOLAR_SUCCESS";
export const OBTENER_ESCOLAR_ERROR = "OBTENER_ESCOLAR_ERROR";

export const OBTENER_OSEXUAL = "OBTENER_OSEXUAL";
export const OBTENER_OSEXUAL_SUCCESS = "OBTENER_OSEXUAL_SUCCESS";
export const OBTENER_OSEXUAL_ERROR = "OBTENER_OSEXUAL_ERROR";

export const OBTENER_ETNIAS = "OBTENER_ETNIAS";
export const OBTENER_ETNIAS_SUCCESS = "OBTENER_ETNIAS_SUCCESS";
export const OBTENER_ETNIAS_ERROR = "OBTENER_ETNIAS_ERROR";

export const OBTENER_PARENTESCO = "OBTENER_PARENTESCO";
export const OBTENER_PARENTESCO_SUCCESS = "OBTENER_PARENTESCO_SUCCESS";
export const OBTENER_PARENTESCO_ERROR = "OBTENER_PARENTESCO_ERROR";
