/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import { useState, useEffect, useContext } from "react";
// Images
import img from "assets/images/profile.jpg";

import dataState from "../../../../context/data/dataContext";

export default function data() {
  const DataContext = useContext(dataState);
  const {
    handleCuidador,
    cuidadores,
    setOpenFull,
    setValueIdEliC,
    setValueIdEditC,
    setOpendialog,
    setOpenFormdialog,
  } = DataContext;

  useEffect(() => {
    handleCuidador();
  }, []);

  const handleClickEliminar = (ID) => {
    setValueIdEliC(ID);
    setOpendialog(true);
  };

  const handleEditar = (ID) => {
    setValueIdEditC(ID);
    setOpenFormdialog(1);
    setOpenFull(true);
  };

  //console.log(cuidadores);

  const Cuidador = ({ image, name, documento }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{documento}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Ubicacion = ({ direccion, telefono }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {direccion}
      </MDTypography>
      <MDTypography variant="caption">{telefono}</MDTypography>
    </MDBox>
  );

  const ListaT = cuidadores.map((item) => ({
    Discapacitado: (
      <Cuidador
        image={img}
        name={`${item.nombres} ${item.apellidos}`}
        documento={`${item.Tipo_documento} ${item.documentos}`}
      />
    ),
    Ubicacion: (
      <Ubicacion
        direccion={`${item.Municipio} ${item.direccion}`}
        telefono={`Tel: ${item.telefono}`}
      />
    ),
    Correo: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {`${item.correo}`}
      </MDTypography>
    ),
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={item.estado === "1" ? "Activo" : "No activo"}
          color={item.estado === "1" ? "success" : "dark"}
          variant="gradient"
          size="lg"
        />
      </MDBox>
    ),
    action: (
      <>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          <MDButton color="info" onClick={() => handleEditar(item.ID)}>
            &nbsp;Editar
          </MDButton>
        </MDTypography>
        &nbsp; &nbsp; &nbsp;
        <MDTypography variant="caption" color="text" fontWeight="medium">
          <MDButton color="error" onClick={() => handleClickEliminar(item.ID)}>
            &nbsp;Borrar
          </MDButton>
        </MDTypography>
      </>
    ),
  }));

  return {
    columns: [
      { Header: "Nombre Cuidador", accessor: "Discapacitado", width: "30%", align: "left" },
      { Header: "Datos Ubicacion", accessor: "Ubicacion", width: "30%", align: "left" },
      { Header: "Correo ", accessor: "Correo", align: "center" },
      { Header: "Estado", accessor: "status", align: "center" },
      { Header: "", accessor: "detalle", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],
    rows: ListaT,
  };
}
