/* eslint-disable react/jsx-key */
import { useState, useEffect, useContext } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import ListaUsuario from "layouts/usuarios/components/ListaUsuario";
import dataState from "../../../../context/data/dataContext";
function UsuarioInformation() {
  const DataContext = useContext(dataState);
  const { usuarios, handleUsuarios } = DataContext;

  useEffect(() => {
    handleUsuarios();
  }, []);

  //console.log(usuarios);
  return (
    <Card>
      <MDBox
        mx={2}
        mt={-2}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Información Usuarios
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {usuarios?.map((item, index) => (
            <ListaUsuario
              key={index}
              id={item.ID_USER}
              name={item.NOMBRES}
              user={item.USERNAME}
              documento={item.DOCUMENTO}
              roll={item.USER_ROL}
              idroll={item.ID_ROLL}
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default UsuarioInformation;
