export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO";
export const REGISTRO_ERROR = "REGISTRO_ERROR";

export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const USUARIO_AUTENTICADO = "USUARIO_AUTENTICADO";
export const CERRAR_SESION = "CERRAR_SESION";

export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";

export const OBTENER_USUARIOS = "OBTENER_USUARIOS";
export const OBTENER_USUARIOS_SUCCESS = "OBTENER_USUARIOS_SUCCESS";
export const OBTENER_USUARIOS_ERROR = "OBTENER_USUARIOS_ERROR";

export const REGISTRO_USUARIOS = "REGISTRO_USUARIOS";
export const REGISTRO_USUARIOS_EXITOSO = "REGISTRO_USUARIOS_EXITOSO";
export const REGISTRO_USUARIOS_ERROR = "REGISTRO_USUARIOS_ERROR";

export const ACTUALIZA_USUARIOS = "ACTUALIZ_USUARIOS";
export const ACTUALIZA_USUARIOS_EXITOSO = "ACTUALIZ_USUARIOS_EXITOSO";
export const ACTUALIZA_USUARIOS_ERROR = "ACTUALIZ_USUARIOS_ERROR";

export const REGISTRO_USERPASS = "REGISTRO_USERPASS";
export const REGISTRO_USERPASS_EXITOSO = "REGISTRO_USERPASS_EXITOSO";
export const REGISTRO_USERPASS_ERROR = "REGISTRO_USERPASS_ERROR";

export const DELETE_USUARIOS = "DELETE_USUARIOS";
export const DELETE_USUARIOS_EXITOSO = "DELETE_USUARIOS_EXITOSO";
export const DELETE_USUARIOS_ERROR = "DELETE_USUARIOS_ERROR";

export const OBTENER_ROLUSER = "OBTENER_ROLUSER";
export const OBTENER_ROLUSER_SUCCESS = "OBTENER_ROLUSER_SUCCESS";
export const OBTENER_ROLUSER_ERROR = "OBTENER_ROLUSER_ERROR";
