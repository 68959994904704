/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import Imgprofile from "assets/images/profile.jpg";
import backgroundImage from "assets/images/profile-bg.jpg";

import dataState from "../../../../context/data/dataContext";

function Header({ children }) {
  const {
    handleDiscapacitado,
    discapacitados,
    valuediscapacida,
    valueIdCaratec,
    setValuediscapacida,
    setTabForm,
    tabValue,
    setTabValue,
  } = useContext(dataState);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [discOption, setDiscOption] = useState("");

  useEffect(() => {
    handleDiscapacitado();
  }, []);

  const Discapacitado = discapacitados?.filter((x) => x.ID === valueIdCaratec);
  useEffect(() => {
    let NomDiscapacitado = `${Discapacitado[0]?.documentos} ${Discapacitado[0]?.nombres} ${Discapacitado[0]?.apellidos}`;
    setDiscOption(Discapacitado[0]?.documentos ? NomDiscapacitado : "");
    setValuediscapacida(Discapacitado.length > 0 ? Discapacitado[0]?.ID : 0);
  }, []);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const Array = [];
  for (var i = 0; i < discapacitados.length; i++) {
    var objeto = {
      title:
        discapacitados[i]?.documentos +
        " " +
        discapacitados[i]?.nombres +
        " " +
        discapacitados[i]?.apellidos,

      value: discapacitados[i]?.ID,
    };
    Array.push(objeto);
  }

  const defaultProps = {
    options: Array,
    getOptionLabel: (option) => option?.title,
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={Imgprofile} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Caracterización de personas en Discapacidad
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {valuediscapacida !== 0 ? discOption : ""}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ ml: "100px" }}>
            <MDTypography variant="h5" fontWeight="medium">
              <Autocomplete
                {...defaultProps}
                id="clear-on-blur"
                freeSolo
                onChange={(event, newValue) => {
                  setValuediscapacida(newValue?.value);
                  setDiscOption(newValue?.title);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="personas en condición de discapacidad"
                    variant="standard"
                  />
                )}
              />
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <AppBar position="relative">
              <Tabs
                orientation={tabsOrientation}
                variant="scrollable"
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab
                  onClick={() => setTabForm(0)}
                  label="INFORMACION GENERAL"
                  icon={
                    <Icon fontSize="medium" sx={{ mt: -0.5 }}>
                      add_home_work
                    </Icon>
                  }
                />
                <Tab
                  onClick={() => setTabForm(1)}
                  label="VALORACIÓNES Y ORIGENES"
                  icon={
                    <Icon fontSize="medium" sx={{ mt: -0.25 }}>
                      accessible
                    </Icon>
                  }
                />
                <Tab
                  onClick={() => setTabForm(2)}
                  label="DIFICULTADES Y LIMITACIONES"
                  icon={
                    <Icon fontSize="medium" sx={{ mt: -0.25 }}>
                      connect_without_contact
                    </Icon>
                  }
                />
                <Tab
                  onClick={() => setTabForm(3)}
                  label="INFRESTRUCTURA VIVIENDA"
                  icon={
                    <Icon fontSize="medium" sx={{ mt: -0.25 }}>
                      cottage
                    </Icon>
                  }
                />
                <Tab
                  onClick={() => setTabForm(4)}
                  label="RED PRIMARIA"
                  icon={
                    <Icon fontSize="medium" sx={{ mt: -0.25 }}>
                      reduce_capacity_sharp
                    </Icon>
                  }
                />
                <Tab
                  onClick={() => setTabForm(5)}
                  label="TERMINAR"
                  //disabled
                  icon={
                    <Icon fontSize="medium" sx={{ mt: -0.25 }}>
                      account_tree
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
