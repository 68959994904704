// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function FormGeneral(Props) {
  const DATA = Props.DATA;

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          COMO SE CAPTÓ LA PERSONA CONDICION DE DISCAPACIDAD:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].CAPTO_PERSONA_DISCAPACIDAD}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          IDENTIFICACION DE PERSONA EN CONDICION DE DISCAPACIDAD
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDBox p={1} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            CUENTA CON CERTIFICADO DE DISCAPACIDAD:&nbsp;&nbsp;
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].CUENTA_CERTIFICADO_DISCAPACIDAD}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox p={1} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            ES MIEMBRO DE UNA ORGANIZACIÓN:&nbsp;&nbsp;
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].MIEMBRO_ORGANIZACION}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox p={1} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            NOMBRE DE LA ORGANIZACIÓN:&nbsp;&nbsp;
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].NOMBRE_ORGANIZACION ? DATA[0].NOMBRE_ORGANIZACION : "NO REGISTRA"}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox p={1} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            SITUACION LABORAL ACTUALMENTE:&nbsp;&nbsp;
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].SITUACION_LABORAL_ACTUALMENTE}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox p={1} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            QUIEN APORTA PARA LOS GASTOS EN EL HOGAR:&nbsp;&nbsp;
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].QUIEN_APORTA_PARA_GASTOS_HOGAR}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox p={1} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            SEGURIDAD SOCIAL:&nbsp;&nbsp;
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].SEGURO_SOCIAL}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox p={1} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            RECIBE ALGÚN TIPO DE AYUDA ECONÓMICA DEL GOBIERNO:&nbsp;&nbsp;
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].RECIBE_AYUDA_ECONOMICA_GOBIERNO}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox p={1} style={{ backgroundColor: "#fff", with: "50%", textAlign: "left" }}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            CUANTAS PERSONAS TIENE A SU CARGO:&nbsp;&nbsp;
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].CUANTAS_PERSONAS_TIENEA_CARGO}
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}
export default FormGeneral;
