// react-router components
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UsuarioNavbar from "examples/Navbars/UsuarioNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import FormDialogo from "layouts/discapacitados/FormDialogo";

// Data
import discapacidaTableData from "../discapacitados/data/discapidaTableData";
import dataState from "../../context/data/dataContext";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
function Datos_discapacitados() {
  const navigate = useNavigate();
  const DataContext = useContext(dataState);
  const {
    setOpendialog,
    opendialog,
    handleeliDiscapacitado,
    setErrorSB,
    errorSB,
    infoSB,
    setInfoSB,
  } = DataContext;
  const closeErrorSB = () => setErrorSB(false);
  const closeInfoSB = () => setInfoSB(false);

  const { columns, rows } = discapacidaTableData();

  const handleClose = () => {
    setOpendialog(false);
  };

  const handleQuitar = () => {
    handleeliDiscapacitado();
    setOpendialog(false);
  };

  const renderInfoSB = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content="El registro de la personas en condición de discapacidad se elimino satisfatoriamente..."
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={"El registro no se elimino por que hay caracterizacion vinculada"}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const DialogoEliminar = () => (
    <>
      <Dialog
        open={opendialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "#1a73e8" }}>
          {"Eliminar"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: "24px" }}>
            ¿Esta seguro que desea eliminar esta personas en condición de discapacidad?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleQuitar} autoFocus>
            Aceptar, Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return (
    <DashboardLayout>
      <UsuarioNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Personas en consición de discapacidad
                </MDTypography>
              </MDBox>
              <MDBox
                pt={2}
                px={2}
                display="flex-1"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDButton
                  variant="outlined"
                  color="info"
                  onClick={() => navigate("/form_discapacitados")}
                  fullWidth
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;Agregar personas en condición de discapacidad
                </MDButton>
                <MDTypography variant="h6" fontWeight="medium">
                  &nbsp;
                </MDTypography>
              </MDBox>
              <MDBox pt={0} px={0} display="flex" justifyContent="space-between" alignItems="left">
                <DashboardNavbar />
              </MDBox>
              <MDBox pt={1}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <DialogoEliminar />
      <FormDialogo />
      <Footer />
      {renderInfoSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default Datos_discapacitados;
