import { useState, useEffect, useContext } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDAlert from "components/MDAlert";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Overview page components
import InfoGeneral from "layouts/discapacitados/InfoCaracterizacion/InfoGeneral";
import InfoValororigen from "layouts/discapacitados/InfoCaracterizacion/InfoValororigen";
import InfoDificultades from "layouts/discapacitados/InfoCaracterizacion/InfoDificultadesLimit";
import InfoInfraestructura from "layouts/discapacitados/InfoCaracterizacion/InfoInfraestructuraViv";
import InfoRedPriamaria from "layouts/discapacitados/InfoCaracterizacion/InfoRedPriamaria";

//import dataState from "../../context/data/dataContext";
function InfoCaracterizacion(Props) {
  const datos = Props.Caracterizacion;
  /*   console.log(datos.length); */

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      A ESTA PERSONA EN CONDICIÓN DE DISCAPACIDAD NO SE LE HA APLICADO UNA CARACTERIZACION
    </MDTypography>
  );
  return (
    <Card>
      <MDBox p={1}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <MDBox
              borderRadius="lg"
              justifyContent="space-between"
              alignItems="center"
              p={1}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[0]} solid ${borderColor}`,
              }}
            >
              {datos.length === 0 ? (
                <MDBox p={2}>
                  {" "}
                  <MDAlert color="info">{alertContent()}</MDAlert>
                </MDBox>
              ) : (
                <Grid container>
                  <Grid item xs={12} md={12} xl={12}>
                    <InfoGeneral DATA={datos} />
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <InfoValororigen DATA={datos} />
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <InfoDificultades DATA={datos} />
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <InfoInfraestructura DATA={datos} />
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <InfoRedPriamaria DATA={datos} />
                  </Grid>
                </Grid>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default InfoCaracterizacion;
