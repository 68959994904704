// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
//import MDDatepicker from "components/MDDatepicker";
import MDButton from "components/MDButton";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import dataState from "../../../../context/data/dataContext";
import MDInput from "components/MDInput";
import { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

function Cuidadores() {
  const DataContext = useContext(dataState);
  const [controller] = useMaterialUIController();
  const {
    handleSubmitC,
    handleMunicipio,
    handleCorregimiento,
    handleEscolarida,
    handleTipodoc,
    handletParentesco,
    municipio,
    corregimiento,
    tipodoc,
    escolarida,
    parentesco,
    enviando,
    msgSB,
    errorSB,
    setErrorSB,
    infoSB,
    setInfoSB,
  } = DataContext;

  const closeErrorSB = () => setErrorSB(false);
  const closeInfoSB = () => setInfoSB(false);

  useEffect(() => {
    handleMunicipio();
  }, []);

  useEffect(() => {
    handleCorregimiento();
  }, []);

  useEffect(() => {
    handleEscolarida();
  }, []);

  useEffect(() => {
    handleTipodoc();
  }, []);

  useEffect(() => {
    handletParentesco();
  }, []);

  // console.log(parentesco);

  const formik = useFormik({
    initialValues: {
      Id_municipio: "",
      Id_corregimiento: "",
      Id_tipo_documento: "",
      Id_escolaridad: "",
      Id_parentesco: "",
      Estado_escolaridad: "",
      Documentos: "",
      Nombres: "",
      Apellidos: "",
      Genero: "",
      Correo: "",
      Barrio_vereda: "",
      Direccion: "",
      Telefono: "",
      Estado: "",
      FechaIngreso: "",
    },
    validationSchema: Yup.object({
      Id_tipo_documento: Yup.string().required("Seleccione tipo documento"),
      Documentos: Yup.string().required("El documento no puede ir vacio"),
      Nombres: Yup.string().required("El Nombre no puede ir vacio"),
      Apellidos: Yup.string().required("El Apellido no puede ir vacio"),
      Id_parentesco: Yup.string().required("Seleccione el parentesco"),
      Genero: Yup.string().required("Seleccione el genero"),
      Estado: Yup.string().required("Seleccione el estado"),
      Id_escolaridad: Yup.string().required("Seleccione la escolarida"),
      Estado_escolaridad: Yup.string().required("Seleccione el estado escolar"),
      Direccion: Yup.string().required("La direccion no puede ir vacio"),
      Telefono: Yup.string().required("El telefono no puede ir vacio"),
      Correo: Yup.string().email("El correo no es válido").required("El correo es Obligatorio"),
      Id_municipio: Yup.string().required("Seleccione el Municipio"),
      Id_corregimiento: Yup.string().required("Seleccione el corregimiento"),
      Barrio_vereda: Yup.string().required("El barrio o vereda no puede ir vacio"),
      FechaIngreso: Yup.string().required("La Fecha Ingreso no puede ir vacia"),
    }),
    onSubmit: (datosform) => {
      handleSubmitC(datosform, formik);
    },
  });

  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Notificación"
      content="El registro cuidador se agrego satisfatoriamente..."
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={msgSB}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <Card>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MDBox xs={12} md={12} component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox
                borderRadius="lg"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[2]} solid ${borderColor}`,
                }}
              >
                <MDBox pt={4} pb={3} px={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Tipo Documento
                      </InputLabel>
                      <NativeSelect
                        defaultValue={""}
                        inputProps={{ name: "Id_tipo_documento", id: "Id_tipo_documento" }}
                        value={formik.values.Id_tipo_documento}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom101"} value={""}>
                          Seleccione...
                        </option>
                        {tipodoc?.length === 0 ? (
                          <option key={"validationCustom102"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          tipodoc
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_tipo_documento && formik.errors.Id_tipo_documento ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_tipo_documento} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={7}>
                      <MDInput
                        type="text"
                        label="Documento"
                        variant="standard"
                        id="Documentos"
                        name="Documentos"
                        value={formik.values.Documentos}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Documentos && formik.errors.Documentos ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Documentos} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Estado
                      </InputLabel>
                      <NativeSelect
                        defaultValue={""}
                        inputProps={{ name: "Estado", id: "Estado" }}
                        value={formik.values.Estado}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom601"} value={""}>
                          Seleccione...
                        </option>
                        <option value={"1"}>{"Activado"}</option>
                        <option value={"0"}>{"Desactivado"}</option>
                      </NativeSelect>
                      {formik.touched.Estado && formik.errors.Estado ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Estado} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "12px" }}>
                    <Grid item xs={6} md={6}>
                      <MDInput
                        type="text"
                        label="Nombres"
                        variant="standard"
                        name="Nombres"
                        value={formik.values.Nombres}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Nombres && formik.errors.Nombres ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Nombres} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <MDInput
                        type="text"
                        label="Apellidos"
                        variant="standard"
                        name="Apellidos"
                        value={formik.values.Apellidos}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Apellidos && formik.errors.Apellidos ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Apellidos} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "20px" }}>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Parentesco
                      </InputLabel>
                      <NativeSelect
                        defaultValue={""}
                        inputProps={{ name: "Id_parentesco", id: "Id_parentesco" }}
                        value={formik.values.Id_parentesco}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom0013"} value={""}>
                          Seleccione...
                        </option>
                        {parentesco?.length === 0 ? (
                          <option key={"validationCustom0014"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          parentesco
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_parentesco && formik.errors.Id_parentesco ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_parentesco} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Genero
                      </InputLabel>
                      <NativeSelect
                        defaultValue={""}
                        inputProps={{ name: "Genero", id: "Genero" }}
                        value={formik.values.Genero}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom401"} value={""}>
                          Seleccione...
                        </option>
                        <option value={"Masculino"}>Masculino</option>
                        <option value={"Femenino"}>Femenino</option>
                      </NativeSelect>
                      {formik.touched.Genero && formik.errors.Genero ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Genero} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Escolaridad
                      </InputLabel>
                      <NativeSelect
                        defaultValue={""}
                        inputProps={{ name: "Id_escolaridad", id: "Id_escolaridad" }}
                        value={formik.values.Id_escolaridad}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom015"} value={""}>
                          Seleccione...
                        </option>
                        {escolarida?.length === 0 ? (
                          <option key={"validationCustom06"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          escolarida
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_escolaridad && formik.errors.Id_escolaridad ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_escolaridad} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Estado Escolaridad
                      </InputLabel>
                      <NativeSelect
                        defaultValue={""}
                        inputProps={{ name: "Estado_escolaridad", id: "Estado_escolaridad" }}
                        value={formik.values.Estado_escolaridad}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom401"} value={""}>
                          Seleccione...
                        </option>
                        <option value={"Terminado"}>Terminado</option>
                        <option value={"Cursando"}>Cursando</option>
                        <option value={"Incompleta"}>Incompleta</option>
                      </NativeSelect>
                      {formik.touched.Estado_escolaridad && formik.errors.Estado_escolaridad ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Estado_escolaridad} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "12px" }}>
                    <Grid item xs={6} md={3}>
                      <MDInput
                        type="tel"
                        label="Telefono"
                        variant="standard"
                        name="Telefono"
                        value={formik.values.Telefono}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Telefono && formik.errors.Telefono ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Telefono} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDInput
                        type="text"
                        label="Dirección"
                        variant="standard"
                        name="Direccion"
                        value={formik.values.Direccion}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Direccion && formik.errors.Direccion ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Direccion} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <MDInput
                        type="email"
                        label="Correo"
                        variant="standard"
                        name="Correo"
                        value={formik.values.Correo}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Correo && formik.errors.Correo ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Correo} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "12px" }}>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Municipio
                      </InputLabel>
                      <NativeSelect
                        defaultValue={""}
                        inputProps={{ name: "Id_municipio", id: "Id_municipio" }}
                        value={formik.values.Id_municipio}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom001"} value={""}>
                          Seleccione...
                        </option>
                        {municipio?.length === 0 ? (
                          <option key={"validationCustom002"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          municipio
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_municipio && formik.errors.Id_municipio ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_municipio} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Corregimiento
                      </InputLabel>
                      <NativeSelect
                        defaultValue={""}
                        inputProps={{ name: "Id_corregimiento", id: "Id_corregimiento" }}
                        value={formik.values.Id_corregimiento}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom003"} value={""}>
                          Seleccione...
                        </option>
                        {corregimiento?.length === 0 ? (
                          <option key={"validationCustom004"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          corregimiento
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_corregimiento && formik.errors.Id_corregimiento ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_corregimiento} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MDInput
                        type="text"
                        label="Barrio Vereda"
                        name="Barrio_vereda"
                        variant="standard"
                        value={formik.values.Barrio_vereda}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Barrio_vereda && formik.errors.Barrio_vereda ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Barrio_vereda} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDInput
                        type="date"
                        label="Fecha Ingreso"
                        variant="standard"
                        name="FechaIngreso"
                        value={formik.values.FechaIngreso}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.FechaIngreso && formik.errors.FechaIngreso ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.FechaIngreso} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox
                pt={2}
                px={2}
                display="flex-1"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDButton type="submit" variant="gradient" color="success" fullWidth>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  {enviando == false ? "Agregar nuevo Ragistros" : "Enviando Registro..."}
                </MDButton>
                <MDTypography variant="h6" fontWeight="medium">
                  &nbsp;
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {renderInfoSB}
      {renderErrorSB}
    </Card>
  );
}

export default Cuidadores;
