import axios from "axios";
import { useNavigate } from "react-router-dom";

//const API_URL = `http://asociacion.com`;
const API_URL = `https://siadriohacha.info/apisiad`;
//
const Login = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: `${API_URL}/api`,
});

const Axios = axios.create({
  baseURL: `${API_URL}/api`,
});

Axios.interceptors.request.use(
  (config) => {
    const stringTOKEN = window.localStorage.getItem("currentUser")
      ? window.localStorage.getItem("token")
      : "";
    const TOKEN = `Bearer ${stringTOKEN}`;
    config.headers = {
      ...config.headers,
      Authorization: TOKEN,
    };

    return config;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      const navigate = useNavigate();
      navigate("./");
    }
    return Promise.reject(error);
  }
);

export { Login, Axios };
