import { useState, useContext, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";

function PlatformSettings() {
  const { setTabForm, setTabValue } = useContext(dataState);
  const localStorageData = localStorage.getItem("I_VIVIENDA");
  const Data = localStorageData ? JSON.parse(localStorageData) : {};
  const [Opcion, setNumOpcion] = useState(0);
  const [Opcion_1, setNumOpcionuno] = useState(0);
  const [msgtext, setMsgtext] = useState(false);
  const [textomsg, setTextomsg] = useState("");
  const closeInfoMT = () => setMsgtext(false);

  useEffect(() => {
    if (Object.keys(Data).length > 0) {
      setNumOpcion(4);
      setNumOpcionuno(1);
    }
  }, [Data]);

  const [otrosTV, setOtrosTV] = useState(Data.otrosTV ? Data.otrosTV : "");
  const [valueTV, setValueTV] = useState(Data.valueTV ? Data.valueTV : "");
  const [valueCF, setValueCF] = useState(Data.valueCF ? Data.valueCF : "");
  const [valueLV, setValueLV] = useState(Data.valueLV ? Data.valueLV : "");
  const [valueSP, setValueSP] = useState(Data.valueSP ? Data.valueSP : "");
  const [stateSP, setStateSP] = useState({
    SERVICIOP_1: Data?.datoSP?.SERVICIOP_1 ? true : false,
    SERVICIOP_2: Data?.datoSP?.SERVICIOP_2 ? true : false,
    SERVICIOP_3: Data?.datoSP?.SERVICIOP_3 ? true : false,
    SERVICIOP_4: Data?.datoSP?.SERVICIOP_4 ? true : false,
    SERVICIOP_5: Data?.datoSP?.SERVICIOP_5 ? true : false,
    SERVICIOP_6: Data?.datoSP?.SERVICIOP_6 ? true : false,
  });

  const handleChangeSP = (event) => {
    setStateSP({
      ...stateSP,
      [event.target.name]: event.target.checked,
    });
    setNumOpcionuno(1);
  };
  const datoSP = {
    SERVICIOP_1: stateSP.SERVICIOP_1 === true ? "AGUA" : "",
    SERVICIOP_2: stateSP.SERVICIOP_2 === true ? "LUZ" : "",
    SERVICIOP_3: stateSP.SERVICIOP_3 === true ? "GAS NATURAL" : "",
    SERVICIOP_4: stateSP.SERVICIOP_4 === true ? "ALCANTARILLADO" : "",
    SERVICIOP_5: stateSP.SERVICIOP_5 === true ? "ASEO" : "",
    SERVICIOP_6: stateSP.SERVICIOP_6 === true ? "TV/CABLE" : "",
  };

  const handleRadioChangeTV = (event) => {
    setValueTV(event.target.value);
    if (valueTV === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeLV = (event) => {
    setValueLV(event.target.value);
    if (valueLV === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeCF = (event) => {
    setValueCF(event.target.value);
    if (valueCF === "") setNumOpcion(Opcion + 1);
  };

  const handleRadioChangeSP = (event) => {
    setValueSP(event.target.value);
    if (valueSP === "") setNumOpcion(Opcion + 1);
  };
  const handleAprobar = () => {
    const openinfo = () => setMsgtext(true);
    if (otrosTV === "" && valueTV === "OTROS") {
      setTextomsg("NO escribio cual otro tipo de vivienda");
      openinfo();
      return false;
    }
    if (Opcion_1 === 0 && valueSP === "SI") {
      setTextomsg("No selecciono servicios publico");
      openinfo();
      return false;
    }
    const DataForm = {
      valueTV,
      valueCF,
      valueLV,
      valueSP,
      datoSP,
      otrosTV,
    };
    // console.log(DataForm);
    localStorage.setItem("I_VIVIENDA", JSON.stringify(DataForm));
    //console.log(localStorage.getItem("I_VIVIENDA"));
    setTabForm(4);
    setTabValue(4);
  };

  const renderInfoSB = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content={textomsg}
      open={msgtext}
      onClose={closeInfoMT}
      close={closeInfoMT}
    />
  );

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          INFRESTRUCTURA DE LA VIVIENDA
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={2}>
            <MDTypography variant="h6">TIPO DE VIVIENDA:</MDTypography>
          </Grid>
          <Grid item xs={2} md={4} style={{ marginTop: "-5px", marginLeft: "0px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="TV"
              value={valueTV}
              onChange={handleRadioChangeTV}
            >
              <FormControlLabel value="CASA" control={<Radio />} label="CASA" />
              <FormControlLabel value="APARTAMENTO" control={<Radio />} label="APARTAMENTO" />
              <FormControlLabel value="OTROS" control={<Radio />} label="OTROS CUAL:" />
            </RadioGroup>
          </Grid>
          <Grid item xs={4} md={4} style={{ marginTop: "-1px", marginLeft: "-130px" }}>
            <MDInput
              type="text"
              variant="standard"
              name="otrosTV"
              value={otrosTV}
              onChange={(e) => setOtrosTV(e.target.value)}
              disabled={valueTV === "OTROS" ? false : true}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={2}>
            <MDTypography variant="h6">EL LUGAR DONDE VIVE ES :</MDTypography>
          </Grid>
          <Grid item xs={2} md={4} style={{ marginTop: "-5px", marginLeft: "0px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="LV"
              value={valueLV}
              onChange={handleRadioChangeLV}
            >
              <FormControlLabel value="PROPIO" control={<Radio />} label="PROPIO" />
              <FormControlLabel value="ARRENDADO" control={<Radio />} label="ARRENDADO" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={3}>
            <MDTypography variant="h6">CONDICIONES FISICAS DE LA VIVIENDA:</MDTypography>
          </Grid>
          <Grid item xs={2} md={4} style={{ marginTop: "-5px", marginLeft: "0px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="CF"
              value={valueCF}
              onChange={handleRadioChangeCF}
            >
              <FormControlLabel value="EXCELENTES" control={<Radio />} label="EXCELENTES" />
              <FormControlLabel value="BUENAS" control={<Radio />} label="BUENAS" />
              <FormControlLabel value="REGULAR" control={<Radio />} label="REGULAR" />
              <FormControlLabel value="MALA" control={<Radio />} label="MALA" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: "1px" }}>
          <Grid item xs={2} md={4}>
            <MDTypography variant="h6">LA VIVIENDA CUENTA CON SERVICIOS PUBLICOS:</MDTypography>
          </Grid>
          <Grid item xs={2} md={2} style={{ marginTop: "-5px", marginLeft: "-100px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="SP"
              value={valueSP}
              onChange={handleRadioChangeSP}
            >
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </Grid>
          <Grid item xs={2} md={6}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateSP.SERVICIOP_1}
                    onChange={handleChangeSP}
                    name="SERVICIOP_1"
                  />
                }
                label="AGUA"
                labelPlacement="AGUA"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateSP.SERVICIOP_2}
                    onChange={handleChangeSP}
                    name="SERVICIOP_2"
                  />
                }
                label="LUZ"
                labelPlacement="LUZ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateSP.SERVICIOP_3}
                    onChange={handleChangeSP}
                    name="SERVICIOP_3"
                  />
                }
                label="GAS NATURAL"
                labelPlacement="GAS NATURAL"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateSP.SERVICIOP_4}
                    onChange={handleChangeSP}
                    name="SERVICIOP_4"
                  />
                }
                label="ALCANTARILLADO"
                labelPlacement="ALCANTARILLADO"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateSP.SERVICIOP_5}
                    onChange={handleChangeSP}
                    name="SERVICIOP_5"
                  />
                }
                label="ASEO"
                labelPlacement="ASEO"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateSP.SERVICIOP_6}
                    onChange={handleChangeSP}
                    name="SERVICIOP_6"
                  />
                }
                label="TV/CABLE"
                labelPlacement="TV/CABLE"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#fff", textAlign: "center" }}>
        <MDTypography variant="h2" color="white">
          <MDButton
            color="info"
            onClick={handleAprobar}
            style={{ float: "right" }}
            disabled={Opcion === 4 ? false : true}
          >
            Aprobar y Seguir &nbsp;
            <Icon fontSize="large" sx={{ fontWeight: "bold" }}>
              double_arrow_sharp
            </Icon>
          </MDButton>
        </MDTypography>
      </MDBox>
      {renderInfoSB}
    </Card>
  );
}

export default PlatformSettings;
