import { useState, useEffect, forwardRef, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import FormInformation from "../../discapacitados/FormInformationEdit";
import InformationDetalle from "../../discapacitados/InformationDetalle";
import InfoCaracterizacion from "../../discapacitados/InfoCaracterizacion";

import dataState from "../../../context/data/dataContext";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const DataContext = useContext(dataState);
  const {
    handleCuidador,
    handleCaraterizacion,
    discapacitados,
    cuidadores,
    caracterizacion,
    setOpenFull,
    openFull,
    valueIdEdit,
    openFormdialog,
  } = DataContext;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : true);
  };

  useEffect(() => {
    handleCuidador();
  }, []);

  useEffect(() => {
    handleCaraterizacion();
  }, []);

  const handleClose = () => {
    setOpenFull(false);
  };

  const DataDiscapacitados = discapacitados?.filter((x) => x.ID === valueIdEdit);
  const Cuidador = cuidadores?.filter((x) => x.ID === DataDiscapacitados[0]?.id_cuidador);

  const Caracterizacion = caracterizacion?.filter(
    (x) => x.ID_DISCAPACITADO === DataDiscapacitados[0]?.ID
  );
  //console.log(Caracterizacion);

  return (
    <>
      <Dialog fullScreen open={openFull} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }} style={{ backgroundColor: "#40A2E3" }}>
          <Toolbar style={{ color: "#fff" }}>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              style={{ color: "#fff" }}
            >
              {openFormdialog === 0
                ? "personas en condición de discapacidad: " +
                  DataDiscapacitados[0]?.nombres +
                  " " +
                  DataDiscapacitados[0]?.apellidos
                : "Editar Persona en condición de Discapacidad"}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon /> Cerrar
            </IconButton>
          </Toolbar>
        </AppBar>
        {openFormdialog === 0 ? (
          <Card id="edit-account" p={2}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                marginRight: "10px",
                marginBottom: "5px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{ backgroundColor: "#DDDDDD" }}
              >
                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                  DATOS DE PERSONAS EN CONDICIÓN DE DISCAPACIDAD:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <InformationDetalle Discapacitados={DataDiscapacitados} Cuidador={Cuidador} />
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                marginRight: "10px",
                marginBottom: "50px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                style={{ backgroundColor: "#DDDDDD" }}
              >
                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                  CARACTERIZACION DE PERSONAS EN CONDICIÓN DE DISCAPACIDAD:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <List>
                    <InfoCaracterizacion Caracterizacion={Caracterizacion} />
                  </List>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Card>
        ) : (
          <FormInformation Discapacitados={DataDiscapacitados} IdEdit={valueIdEdit} />
        )}
      </Dialog>
    </>
  );
}
