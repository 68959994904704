/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MDSnackbar from "components/MDSnackbar";
import dataState from "../../../context/data/dataContext";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

function Discapacitados() {
  const [controller] = useMaterialUIController();
  const DataContext = useContext(dataState);
  const { darkMode } = controller;
  const {
    handleSubmitD,
    handleCuidador,
    handleMunicipio,
    handleCorregimiento,
    handleEscolarida,
    handleTipodoc,
    handleOrientacionSexual,
    handletEtnias,
    cuidadores,
    municipio,
    corregimiento,
    orentacionsexual,
    tipodoc,
    escolarida,
    etnias,
    mensaje,
    enviando,
    msgSB,
    errorSB,
    setErrorSB,
    infoSB,
    setInfoSB,
  } = DataContext;

  const [valuebarrio, setValuebarrio] = useState(0);
  const closeErrorSB = () => setErrorSB(false);
  const closeInfoSB = () => setInfoSB(false);

  useEffect(() => {
    handleCuidador();
  }, []);

  useEffect(() => {
    handleMunicipio();
  }, []);

  useEffect(() => {
    handleMunicipio();
  }, []);

  useEffect(() => {
    handleCorregimiento();
  }, []);

  useEffect(() => {
    handleEscolarida();
  }, []);

  useEffect(() => {
    handleTipodoc();
  }, []);

  useEffect(() => {
    handleOrientacionSexual();
  }, []);

  useEffect(() => {
    handletEtnias();
  }, []);

  /*   const Array = [];
  for (var i = 0; i <= barrio.length; i++) {
    var objeto = {
      title: barrio[i]?.Nombre,
      value: barrio[i]?.ID,
    };
    Array.push(objeto);
  }

  const defaultProps = {
    options: Array,
    getOptionLabel: (option) => option?.title,
  }; */

  const formik = useFormik({
    initialValues: {
      Id_cuidador: "",
      Id_municipio: "",
      Id_corregimiento: "",
      Id_tipo_documento: "",
      Id_escolaridad: "",
      Id_etnias: "",
      Id_orientacion_sexual: "",
      Estado_escolaridad: "",
      Estado_civil: "",
      Documentos: "",
      Nombres: "",
      Apellidos: "",
      Genero: "",
      Sexo: "",
      Correo: "",
      Barrio_vereda: "",
      Direccion: "",
      Comuna: "",
      Telefono: "",
      Estado: "",
      Fecha_nacimiento: "",
      Fecha_aceptacion: "",
    },
    validationSchema: Yup.object({
      Id_cuidador: Yup.string().required("Seleccione un cuidador"),
      Id_tipo_documento: Yup.string().required("Seleccione tipo documento"),
      Id_etnias: Yup.string().required("Seleccione una etnia"),
      Id_orientacion_sexual: Yup.string().required("Seleccione orientacion sexual"),
      Id_municipio: Yup.string().required("Seleccione el Municipio"),
      Id_corregimiento: Yup.string().required("Seleccione el corregimiento"),
      Estado_civil: Yup.string().required("Seleccione el estado civil"),
      Documentos: Yup.string().required("El documento no puede ir vacio"),
      Nombres: Yup.string().required("El Nombre no puede ir vacio"),
      Apellidos: Yup.string().required("El Apellido no puede ir vacio"),
      Genero: Yup.string().required("Seleccione el genero"),
      Sexo: Yup.string().required("Seleccione el sexo"),
      Estado: Yup.string().required("Seleccione el estado"),
      Id_escolaridad: Yup.string().required("Seleccione la escolarida"),
      Estado_escolaridad: Yup.string().required("Seleccione el estado escolar"),
      Direccion: Yup.string().required("La direccion no puede ir vacio"),
      Comuna: Yup.string().required("La comuna no puede ir vacio"),
      Telefono: Yup.string().required("El telefono no puede ir vacio"),
      Correo: Yup.string().email("El correo no es válido").required("El correo es Obligatorio"),
      Barrio_vereda: Yup.string().required("El barrio o vereda no puede ir vacio"),
      Fecha_nacimiento: Yup.string().required("La fecha nacimiento no puede ir vacia"),
      Fecha_aceptacion: Yup.string().required("La fecha Ingreso no puede ir vacia"),
    }),
    onSubmit: (datosform) => {
      formik.values.Id_barrio_vereda = valuebarrio;
      console.log(datosform);
      handleSubmitD(datosform, formik);
    },
  });

  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Notificación"
      content="El registro de Discpacitado se agrego satisfatoriamente..."
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={mensaje?.response?.split("-")[1]}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <Card id="delete-account">
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MDBox xs={12} md={12} component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox
                borderRadius="lg"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[2]} solid ${borderColor}`,
                }}
              >
                <MDBox pt={4} pb={3} px={3}>
                  <Grid container spacing={1} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={10}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Nombre del Cuidador
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Id_cuidador", id: "Id_cuidador" }}
                        value={formik.values.Id_cuidador}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom101"} value={""}>
                          Seleccione...
                        </option>
                        {cuidadores?.length === 0 ? (
                          <option key={"validationCustom102"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          cuidadores
                            ?.filter((item) => item.estado !== "0")
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.documentos} {item.nombres} {item.apellidos}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_cuidador && formik.errors.Id_cuidador ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_cuidador} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Estado
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Estado", id: "Estado" }}
                        value={formik.values.Estado}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom601"} value={""}>
                          Seleccione...
                        </option>
                        <option value={"1"}>{"Activado"}</option>
                        <option value={"0"}>{"Desactivado"}</option>
                      </NativeSelect>
                      {formik.touched.Estado && formik.errors.Estado ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Estado} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "12px" }}>
                    <Grid item xs={6} md={2}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Tipo Documento
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Id_tipo_documento", id: "Id_tipo_documento" }}
                        value={formik.values.Id_tipo_documento}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom101"} value={""}>
                          Seleccione...
                        </option>
                        {tipodoc?.length === 0 ? (
                          <option key={"validationCustom102"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          tipodoc
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_tipo_documento && formik.errors.Id_tipo_documento ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_tipo_documento} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDInput
                        type="text"
                        label="Documento"
                        variant="standard"
                        name="Documentos"
                        value={formik.values.Documentos}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Documentos && formik.errors.Documentos ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Documentos} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDInput
                        type="text"
                        label="Nombres"
                        variant="standard"
                        name="Nombres"
                        value={formik.values.Nombres}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Nombres && formik.errors.Nombres ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Nombres} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MDInput
                        type="text"
                        label="Apellidos"
                        variant="standard"
                        name="Apellidos"
                        value={formik.values.Apellidos}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Apellidos && formik.errors.Apellidos ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Apellidos} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "12px" }}>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Estado Civil
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Estado_civil", id: "Estado_civil" }}
                        value={formik.values.Estado_civil}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom401"} value={""}>
                          Seleccione...
                        </option>
                        <option value={"Casado(a)"}>Casado(a)</option>
                        <option value={"Soltero(a)"}>Soltero(a)</option>
                        <option value={"Viudo(a)"}>Viudo(a)</option>
                        <option value={"Union Libre"}>Union Libre</option>
                      </NativeSelect>
                      {formik.touched.Estado_civil && formik.errors.Estado_civil ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Estado_civil} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Etnias
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Id_etnias", id: "Id_etnias" }}
                        value={formik.values.Id_etnias}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom015"} value={""}>
                          Seleccione...
                        </option>
                        {etnias?.length === 0 ? (
                          <option key={"validationCustom06"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          etnias
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_etnias && formik.errors.Id_etnias ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_etnias} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Escolaridad
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Id_escolaridad", id: "Id_escolaridad" }}
                        value={formik.values.Id_escolaridad}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom015"} value={""}>
                          Seleccione...
                        </option>
                        {escolarida?.length === 0 ? (
                          <option key={"validationCustom06"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          escolarida
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_escolaridad && formik.errors.Id_escolaridad ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_escolaridad} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Estado Escolaridad
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Estado_escolaridad", id: "Estado_escolaridad" }}
                        value={formik.values.Estado_escolaridad}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom401"} value={""}>
                          Seleccione...
                        </option>
                        <option value={"Terminado"}>Terminado</option>
                        <option value={"Cursando"}>Cursando</option>
                        <option value={"Incompleta"}>Incompleta</option>
                      </NativeSelect>
                      {formik.touched.Estado_escolaridad && formik.errors.Estado_escolaridad ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Estado_escolaridad} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "20px" }}>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Genero
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Genero", id: "Genero" }}
                        value={formik.values.Genero}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom401"} value={""}>
                          Seleccione...
                        </option>
                        <option value={"Masculino"}>Masculino</option>
                        <option value={"Femenino"}>Femenino</option>
                      </NativeSelect>
                      {formik.touched.Genero && formik.errors.Genero ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Genero} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Sexo
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Sexo", id: "Sexo" }}
                        value={formik.values.Sexo}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom401"} value={""}>
                          Seleccione...
                        </option>
                        <option value={"Hombre"}>Hombre</option>
                        <option value={"Mujer"}>Mujer</option>
                      </NativeSelect>
                      {formik.touched.Sexo && formik.errors.Sexo ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Sexo} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Orientacion Sexual
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Id_orientacion_sexual", id: "Id_orientacion_sexual" }}
                        value={formik.values.Id_orientacion_sexual}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom015"} value={""}>
                          Seleccione...
                        </option>
                        {orentacionsexual?.length === 0 ? (
                          <option key={"validationCustom06"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          orentacionsexual
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_orientacion_sexual &&
                      formik.errors.Id_orientacion_sexual ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_orientacion_sexual} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDInput
                        type="date"
                        label="Fecha Nacimiento"
                        variant="standard"
                        name="Fecha_nacimiento"
                        value={formik.values.Fecha_nacimiento}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Fecha_nacimiento && formik.errors.Fecha_nacimiento ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Fecha_nacimiento} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "20px" }}>
                    <Grid item xs={6} md={3}>
                      <MDInput
                        type="tel"
                        label="Telefono"
                        variant="standard"
                        name="Telefono"
                        value={formik.values.Telefono}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Telefono && formik.errors.Telefono ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Telefono} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDInput
                        type="text"
                        label="Dirección"
                        name="Direccion"
                        variant="standard"
                        value={formik.values.Direccion}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Direccion && formik.errors.Direccion ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Direccion} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDInput
                        type="text"
                        label="Comuna"
                        name="Comuna"
                        variant="standard"
                        value={formik.values.Comuna}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Comuna && formik.errors.Comuna ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Comuna} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MDInput
                        type="email"
                        label="Correo Electronico"
                        variant="standard"
                        name="Correo"
                        value={formik.values.Correo}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Correo && formik.errors.Correo ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Correo} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "20px" }}>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Municipio
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Id_municipio", id: "Id_municipio" }}
                        value={formik.values.Id_municipio}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom001"} value={""}>
                          Seleccione...
                        </option>
                        {municipio?.length === 0 ? (
                          <option key={"validationCustom002"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          municipio
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_municipio && formik.errors.Id_municipio ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_municipio} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Corregimiento
                      </InputLabel>
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{ name: "Id_corregimiento", id: "Id_corregimiento" }}
                        value={formik.values.Id_corregimiento}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        <option key={"validationCustom003"} value={""}>
                          Seleccione...
                        </option>
                        {corregimiento?.length === 0 ? (
                          <option key={"validationCustom004"} value={""}>
                            Seleccione...
                          </option>
                        ) : (
                          corregimiento
                            ?.filter((item) => item.Estado !== null)
                            .map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Nombre}
                              </option>
                            ))
                        )}
                      </NativeSelect>
                      {formik.touched.Id_corregimiento && formik.errors.Id_corregimiento ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Id_corregimiento} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MDInput
                        type="text"
                        label="Barrio Vereda"
                        name="Barrio_vereda"
                        variant="standard"
                        value={formik.values.Barrio_vereda}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Barrio_vereda && formik.errors.Barrio_vereda ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Barrio_vereda} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDInput
                        type="date"
                        label="Fecha Aceptacion"
                        variant="standard"
                        name="Fecha_aceptacion"
                        value={formik.values.Fecha_aceptacion}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                      {formik.touched.Fecha_aceptacion && formik.errors.Fecha_aceptacion ? (
                        <MDTypography variant="subtitle2" color="error">
                          <p style={{ fontSize: "12px" }}>{formik.errors.Fecha_aceptacion} </p>
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox
                pt={2}
                px={2}
                display="flex-1"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDButton type="submit" variant="gradient" color="success" fullWidth>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  {enviando == false ? "Agregar nuevo Ragistros" : "Enviando Registro..."}
                </MDButton>
                <MDTypography variant="h6" fontWeight="medium">
                  &nbsp;
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {renderInfoSB}
      {renderErrorSB}
    </Card>
  );
}

export default Discapacitados;
