/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import { useState, useEffect, useContext } from "react";
// react-router components
import { useNavigate } from "react-router-dom";
// Images
import img from "assets/images/profile.jpg";

import dataState from "../../../context/data/dataContext";
// data
export default function data() {
  const navigate = useNavigate();
  const DataContext = useContext(dataState);
  const {
    handleDiscapacitado,
    handleCaraterizacion,
    discapacitados,
    caracterizacion,
    FormatoFecha,
    setValueIdCaratec,
    setOpendialog,
    setOpenFull,
    setValueIdEli,
    setValueIdEdit,
    setOpenFormdialog,
  } = DataContext;

  useEffect(() => {
    handleDiscapacitado();
  }, []);

  useEffect(() => {
    handleCaraterizacion();
  }, []);

  const handleClickEliminar = (ID) => {
    setValueIdEli(ID);
    setOpendialog(true);
  };

  const handleEditar = (ID) => {
    setValueIdEdit(ID);
    setOpenFormdialog(1);
    setOpenFull(true);
  };

  const handleDetalles = (ID) => {
    setValueIdEdit(ID);
    setOpenFormdialog(0);
    setOpenFull(true);
  };

  const caraterizar = (ID) => {
    setValueIdCaratec(ID);
    navigate("/caracterizar/" + ID);
  };

  const Valcarateriza = (ID) => {
    const caracterizar = caracterizacion?.filter(
      (x) => parseInt(x.ID_DISCAPACITADO) === parseInt(ID)
    );
    return caracterizar?.length;
  };

  // console.log(Valcarateriza);

  const User = JSON.parse(localStorage.getItem("currentUser"));

  const Discapacitado = ({ image, name, documento }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{documento}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Cuidador = ({ name, documento }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{documento}</MDTypography>
    </MDBox>
  );

  const ListaT = discapacitados?.map((item) => ({
    Discapacitado: (
      <Discapacitado
        image={img}
        name={`${item.nombres} ${item.apellidos}`}
        documento={`${item.Tipo_documento} ${item.documentos}`}
      />
    ),
    Cuidador: (
      <Cuidador
        name={`${item.Cuidador_nombres} ${item.Cuidador_apellidos}`}
        documento={`${item.Tipo_documento} ${item.Cuidador_documentos}`}
      />
    ),
    F_aceptacion: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {`${FormatoFecha(item.fecha_aceptacion)}`}
      </MDTypography>
    ),
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={item.estado === "1" ? "Activo" : "No activo"}
          color={item.estado === "1" ? "success" : "dark"}
          variant="gradient"
          size="lg"
        />
      </MDBox>
    ),
    detalle: (
      <MDBox ml={-1}>
        <MDButton
          variant="outlined"
          color={Valcarateriza(item.ID) === 0 ? "info" : "success"}
          onClick={() => handleDetalles(item.ID)}
        >
          &nbsp;Detalles
        </MDButton>
      </MDBox>
    ),
    action: (
      <>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          <MDButton
            variant="outlined"
            color={Valcarateriza(item.ID) === 0 ? "info" : "success"}
            onClick={() => caraterizar(item.ID)}
          >
            {" "}
            &nbsp;{Valcarateriza(item.ID) === 0 ? "Carcterizar" : "Carcterizado"}
          </MDButton>
        </MDTypography>
        &nbsp; &nbsp; &nbsp;
        <MDTypography variant="caption" color="text" fontWeight="medium">
          <MDButton color="info" onClick={() => handleEditar(item.ID)}>
            &nbsp;Editar
          </MDButton>
        </MDTypography>
        &nbsp; &nbsp; &nbsp;
        <MDTypography variant="caption" color="text" fontWeight="medium">
          <MDButton
            color="error"
            onClick={() => handleClickEliminar(item.ID)}
            disabled={parseInt(User?.ID_ROLL) === 1 ? false : true}
          >
            &nbsp;Borrar
          </MDButton>
        </MDTypography>
      </>
    ),
  }));

  return {
    columns: [
      {
        Header: "Nombre de persona en condición de discapacidad",
        accessor: "Discapacitado",
        width: "30%",
        align: "left",
      },
      { Header: "Nombre Cuidador", accessor: "Cuidador", width: "30%", align: "left" },
      { Header: "Fecha aceptacion ", accessor: "F_aceptacion", align: "center" },
      { Header: "Estado", accessor: "status", align: "center" },
      { Header: "Detalle", accessor: "detalle", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],
    rows: ListaT,
  };
}
