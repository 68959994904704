import { useState, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
function FormValororigen(Props) {
  const DATA = Props.DATA;
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          VALORACIÓNES Y ORIGENES
        </MDTypography>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          ALTERACIONES PERMANENTES:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].ALTERACIONES_PERMANENTES}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          TIENE ALGUN CONTROL MEDICO:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].TIENE_ALGUN_CONTROL_MEDICO}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          ORIGEN DE LA DISCAPACIDAD:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].ORIGEN_DISCAPACIDAD}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          TIPO DE DISCAPACIDAD:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].TIPO_DISCAPACIDAD}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default FormValororigen;
