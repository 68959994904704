import { useEffect, useContext } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import UsuarioNavbar from "examples/Navbars/UsuarioNavbar";
import Footer from "examples/Footer";

// Billing page components
import UsuarioPassForm from "./PassForm";
//import dataState from "../../context/data/dataContext";
function AreAdminPass() {
  /*   const DataContext = useContext(dataState);
  const { usuarios, handleUsuarios, DatoEditUser } = DataContext;
  const { id } = useParams();
  useEffect(() => {
    handleUsuarios();
  }, []); */
  return (
    <DashboardLayout>
      <UsuarioNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={8} lg={10}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <UsuarioPassForm />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AreAdminPass;
