import { useState, useContext, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";

function FormEnvioData() {
  const DataContext = useContext(dataState);
  const {
    handleSubmitCRZ,
    valuediscapacida,
    enviando,
    mensaje,
    setErrorSB,
    errorSB,
    infoSB,
    setInfoSB,
  } = DataContext;
  const closeErrorSB = () => setErrorSB(false);
  const closeInfoSB = () => setInfoSB(false);
  const [alertaD, setAlertaD] = useState(false);
  const [alertaC, setAlertaC] = useState(false);
  const [Openvio, setNumOpenvio] = useState(0);
  const [msgtext, setMsgtext] = useState(false);
  const [textomsg, setTextomsg] = useState("");
  const closeInfoENV = () => setMsgtext(false);

  setTimeout(function () {
    setAlertaD(false);
    setAlertaC(false);
  }, 5000);

  const Data_GENERAL = JSON.parse(localStorage.getItem("I_GENERAL"));
  const Data_VIVIENDA = JSON.parse(localStorage.getItem("I_VIVIENDA"));
  const Data_LIMITACION = JSON.parse(localStorage.getItem("D_LIMITACION"));
  const Data_PRIMARIA = JSON.parse(localStorage.getItem("R_PRIMARIA"));
  const Data_ORIGENES = JSON.parse(localStorage.getItem("V_ORIGENES"));

  useEffect(() => {
    if (
      Data_GENERAL !== null &&
      Data_VIVIENDA !== null &&
      Data_LIMITACION !== null &&
      Data_PRIMARIA !== null &&
      Data_ORIGENES !== null
    ) {
      setNumOpenvio(1);
    }
  }, [Data_GENERAL, Data_VIVIENDA, Data_LIMITACION, Data_PRIMARIA, Data_ORIGENES]);

  const handleEnviar = () => {
    const openinfo = () => setMsgtext(true);
    if (valuediscapacida > 0) {
      if (Data_GENERAL && Data_VIVIENDA && Data_LIMITACION && Data_PRIMARIA && Data_ORIGENES) {
        const DataForm = {
          ID_DISCAPACITADO: valuediscapacida,
          CAPTO_PERSONA_DISCAPACIDAD:
            Data_GENERAL?.valueIG !== "OTRO"
              ? Data_GENERAL?.valueIG
              : Data_GENERAL?.datotexto.otroPE,
          MIEMBRO_ORGANIZACION: Data_GENERAL.valueMO,
          NOMBRE_ORGANIZACION: Data_GENERAL.datotexto.otroMO,
          CUENTA_CERTIFICADO_DISCAPACIDAD: Data_GENERAL.valueCD,
          SEGURO_SOCIAL: Data_GENERAL.valueSG,
          SITUACION_LABORAL_ACTUALMENTE: Data_GENERAL.valueLA,
          QUIEN_APORTA_PARA_GASTOS_HOGAR: Data_GENERAL.valueHO,
          OTRO_APORTA_PARA_GASTOS_HOGAR: Data_GENERAL.datotexto.otroHO,
          RECIBE_AYUDA_ECONOMICA_GOBIERNO: Data_GENERAL.valueAE,
          CUAL_AYUDA_ECONOMICA_GOBIERNO: Data_GENERAL.datotexto.cual,
          CUANTAS_PERSONAS_TIENEA_CARGO: Data_GENERAL.datotexto.cargos,
          ORIGEN_DISCAPACIDAD: handleOrigen(Data_ORIGENES.datoO),
          TIPO_DISCAPACIDAD: handleTipoD(Data_ORIGENES.datoD),
          ALTERACIONES_PERMANENTES: handleAlteraciones(Data_ORIGENES.datoA),
          TIENE_ALGUN_CONTROL_MEDICO: Data_ORIGENES.valueCM,
          CUAL_ALGUN_CONTROL_MEDICO: Data_ORIGENES.cualCM, // ojo
          DIFICULTADES_PERMANENTES: handleDificultades(Data_LIMITACION.datoD),
          LIMITACIONES_ACTIVIDADES_PARTICIPACION: handleLimitaciones(Data_LIMITACION.datoL),
          TIPO_VIVIENDA: Data_VIVIENDA.valueTV,
          OTRO_TIPO_VIVIENDA: Data_VIVIENDA.otrosTV, //OJO
          TENENCIA_VIVIENDA: Data_VIVIENDA.valueLV,
          CONDICIONES_FISICAS_VIVIENDA: Data_VIVIENDA.valueCF,
          VIVIENDA_CUENTA_SERVICIOS_PUBLICOS: Data_VIVIENDA.valueSP,
          VIVIENDA_SERVICIOS_PUBLICOS: handleServiciosP(Data_VIVIENDA.datoSP),
          REQUIERE_AYUDA_PERMANENTE_PERSONA: Data_PRIMARIA.valuePP,
          CUANTAS_HORAS_DIA_CUENTACON_CUIDADO: Data_PRIMARIA.datotexto.otroPP,
          QUIEN_LOCUIDA: Data_PRIMARIA.valueQC,
          REQUIERE_AYUDAS_DISPOSITIVOS_ASISTENCIA: Data_PRIMARIA.valueRA,
          AYUDA_TECNICA_PARA: handleATencica(Data_PRIMARIA.datoAT),
          OTRO_AYUDA_TECNICA_PARA: Data_PRIMARIA.datotexto.otroAT, //OJO
          POBLACIONES_DIFERENCIALES_INCLUSION: Data_PRIMARIA.valueRP,
          CUAL_POBLACIONES_DIFERENCIALES_INCLUSION: Data_PRIMARIA.datotexto.otroRP, ///OJO
          POBLACION_INCLUSION_POROFICIO: Data_PRIMARIA.valueIF,
          CUAL_POBLACION_INCLUSION_POROFICIO: Data_PRIMARIA.datotexto.otroIF, ///OJO
          VICTIMA_CONFLICTO_ARMADO: Data_PRIMARIA.valueVC,
          CUAL_VICTIMA_CONFLICTO_ARMADO: Data_PRIMARIA.datotexto.otroVC, ///OJO
        };

        //  console.log(DataForm);
        handleSubmitCRZ(JSON.parse(JSON.stringify(DataForm)));
      } else {
        setAlertaC(true);
      }
    } else {
      setTextomsg("Selecciono la persona en condición de discapacidad para enviar datos.");
      openinfo();
      //setAlertaD(true);
    }
  };

  const handleAlteraciones = (DATO) => {
    let cadena =
      DATO.ALTERACIONES_1 +
      "," +
      DATO.ALTERACIONES_2 +
      "," +
      DATO.ALTERACIONES_3 +
      "," +
      DATO.ALTERACIONES_4 +
      "," +
      DATO.ALTERACIONES_5 +
      "," +
      DATO.ALTERACIONES_6 +
      "," +
      DATO.ALTERACIONES_7 +
      "," +
      DATO.ALTERACIONES_8 +
      "," +
      DATO.ALTERACIONES_9 +
      "," +
      DATO.ALTERACIONES_10;
    /*   +
      "," +
      DATO.ALTERACIONES_11; */
    var vector = cadena.split(",");
    var lista = "";
    for (var i = 0; i < vector.length; i++) {
      if (vector[i] !== "") {
        lista = lista + vector[i] + ";";
      }
    }
    return lista;
  };

  const handleTipoD = (DATO) => {
    let cadena =
      DATO.TIPOD_1 +
      "," +
      DATO.TIPOD_2 +
      "," +
      DATO.TIPOD_3 +
      "," +
      DATO.TIPOD_4 +
      "," +
      DATO.TIPOD_5 +
      "," +
      DATO.TIPOD_6 +
      "," +
      DATO.TIPOD_7;

    var vector = cadena.split(",");
    var lista = "";
    for (var i = 0; i < vector.length; i++) {
      if (vector[i] !== "") {
        lista = lista + vector[i] + ";";
      }
    }
    return lista;
  };
  const handleOrigen = (DATO) => {
    let cadena =
      DATO.ORIGEN_1 +
      "," +
      DATO.ORIGEN_2 +
      "," +
      DATO.ORIGEN_3 +
      "," +
      DATO.ORIGEN_4 +
      "," +
      DATO.ORIGEN_5 +
      "," +
      DATO.ORIGEN_6 +
      "," +
      DATO.ORIGEN_7 +
      "," +
      DATO.ORIGEN_8 +
      "," +
      DATO.ORIGEN_9 +
      "," +
      DATO.ORIGEN_10 +
      "," +
      DATO.ORIGEN_11 +
      "," +
      DATO.ORIGEN_12;
    /*  +
      "," +
      DATO.ORIGEN_13; */

    var vector = cadena.split(",");
    var lista = "";
    for (var i = 0; i < vector.length; i++) {
      if (vector[i] !== "") {
        lista = lista + vector[i] + ";";
      }
    }

    return lista;
  };

  const handleDificultades = (DATO) => {
    let cadena =
      DATO.DIFUCULTADES_1 +
      "," +
      DATO.DIFUCULTADES_2 +
      "," +
      DATO.DIFUCULTADES_3 +
      "," +
      DATO.DIFUCULTADES_4 +
      "," +
      DATO.DIFUCULTADES_5 +
      "," +
      DATO.DIFUCULTADES_6 +
      "," +
      DATO.DIFUCULTADES_7 +
      "," +
      DATO.DIFUCULTADES_8 +
      "," +
      DATO.DIFUCULTADES_9 +
      "," +
      DATO.DIFUCULTADES_10 +
      "," +
      DATO.DIFUCULTADES_11 +
      "," +
      DATO.DIFUCULTADES_12 +
      "," +
      DATO.DIFUCULTADES_13 +
      "," +
      DATO.DIFUCULTADES_14;

    var vector = cadena.split(",");
    var lista = "";
    for (var i = 0; i < vector.length; i++) {
      if (vector[i] !== "") {
        lista = lista + vector[i] + ";";
      }
    }

    return lista;
  };
  const handleLimitaciones = (DATO) => {
    let cadena =
      DATO.LIMITACIONES_1 +
      "," +
      DATO.LIMITACIONES_2 +
      "," +
      DATO.LIMITACIONES_3 +
      "," +
      DATO.LIMITACIONES_4 +
      "," +
      DATO.LIMITACIONES_5 +
      "," +
      DATO.LIMITACIONES_6 +
      "," +
      DATO.LIMITACIONES_7 +
      "," +
      DATO.LIMITACIONES_8 +
      "," +
      DATO.LIMITACIONES_9;

    var vector = cadena.split(",");
    var lista = "";
    for (var i = 0; i < vector.length; i++) {
      if (vector[i] !== "") {
        lista = lista + vector[i] + ";";
      }
    }
    return lista;
  };

  const handleServiciosP = (DATO) => {
    let cadena =
      DATO.SERVICIOP_1 +
      "," +
      DATO.SERVICIOP_2 +
      "," +
      DATO.SERVICIOP_3 +
      "," +
      DATO.SERVICIOP_4 +
      "," +
      DATO.SERVICIOP_5 +
      "," +
      DATO.SERVICIOP_6;

    var vector = cadena.split(",");
    var lista = "";
    for (var i = 0; i < vector.length; i++) {
      if (vector[i] !== "") {
        lista = lista + vector[i] + ";";
      }
    }
    return lista;
  };

  const handleATencica = (DATO) => {
    let cadena =
      DATO.ATECNICA_1 +
      "," +
      DATO.ATECNICA_2 +
      "," +
      DATO.ATECNICA_3 +
      "," +
      DATO.ATECNICA_4 +
      "," +
      DATO.ATECNICA_5 +
      "," +
      DATO.ATECNICA_6 +
      "," +
      DATO.ATECNICA_7;

    var vector = cadena.split(",");
    var lista = "";
    for (var i = 0; i < vector.length; i++) {
      if (vector[i] !== "") {
        lista = lista + vector[i] + ";";
      }
    }
    return lista;
  };

  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Notificación"
      content="El registro de Discpacitado se agrego satisfatoriamente..."
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );
  //mensaje?.response?.split("-")[1]
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={"No selecciono a una Personas en consición de discapacidad"}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderInfoENV = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content={textomsg}
      open={msgtext}
      onClose={closeInfoENV}
      close={closeInfoENV}
    />
  );

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} bgColor="info">
            <MDButton onClick={handleEnviar} disabled={Openvio === 1 ? false : true} fullWidth>
              <MDBox
                mx={12}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ width: "100%" }}
              >
                <MDTypography
                  bgColor="info"
                  variant="h4"
                  color="white"
                  style={{ textAlign: "center" }}
                >
                  {/*  <Icon sx={{ fontWeight: "bold" }}>close</Icon> */}
                  {enviando == false
                    ? "ENVIAR DATOS CARACTERIZACION"
                    : "ENVIANDO DATOS CARACTERIZACION..."}
                </MDTypography>
              </MDBox>
            </MDButton>
            <MDTypography style={{ width: "50%", textAlign: "center", marginLeft: "25%" }}>
              <Stack sx={{ width: "100%" }}>
                {/* {alertaD && (
                  <>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setAlertaD(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      No selecciono la personas en condición de discapacidad.
                    </Alert>
                  </>
                )} */}
              </Stack>
              <Stack sx={{ width: "100%" }}>
                {alertaC && (
                  <>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setAlertaC(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      No gestiono informacion de la caracterización.
                    </Alert>
                  </>
                )}
              </Stack>
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      {renderInfoSB}
      {renderErrorSB}
      {renderInfoENV}
    </Card>
  );
}

export default FormEnvioData;
