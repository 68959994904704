import { useState, useEffect, useContext } from "react";

// react-router components
import { Link, useNavigate, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import Logo from "../../../assets/images/logoAlcaldiaRiohachaPadilla.png";
// Custom styles for DashboardNavbar
import { navbarRow } from "examples/Navbars/DashboardNavbar/styles";
import dataState from "../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";

function DefaultNavbar({ transparent, light, action }) {
  const [controller] = useMaterialUIController();

  const DataContext = useContext(dataState);

  const { darkMode } = controller;
  const navigate = useNavigate();
  const { buscarDiscapacitado, txtDocumento, setTxtDocumento, setMsgvalidaB } = DataContext;

  const location = useLocation();
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [salir, setSalir] = useState(false);

  const [infoSB, setInfoSB] = useState(false);
  const closeInfoSB = () => setInfoSB(false);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    window.addEventListener("resize", displayMobileNavbar);
    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    if (location.pathname === "/search/") {
      setSalir(true);
    }
  }, [location]);

  const Busqueda = () => {
    if (txtDocumento === 0) {
      setInfoSB(true);
      return;
    }
    navigate("/search/");
    buscarDiscapacitado({ Documento: txtDocumento });
    setMsgvalidaB(true);
  };

  const Cerrar = () => {
    setTxtDocumento();
    navigate("/authentication/sign-in");
    setSalir(false);
  };

  const renderInfoSB = (
    <MDSnackbar
      color="info"
      icon="warning"
      title="Notificación"
      content={"Ingrese el Documento de la personas en condición de discapacidad"}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={3}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        zIndex={3}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MDBox py={transparent ? 1.5 : 0.75} lineHeight={1} pl={{ xs: 0, lg: 1 }}>
          <MDTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
            <MDBox component="img" src={Logo} alt="Brand" width="7rem" />
          </MDTypography>
        </MDBox>
        <MDBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          <MDTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
            <MDBox sx={(theme) => navbarRow(theme, false)}>
              <MDBox pr={1} pf={1}>
                <MDInput
                  label="Ingrese documento de personas en condición de discapacidad"
                  size="large"
                  fullWidth
                  type="search"
                  name={"txtDocumento"}
                  values={txtDocumento}
                  onChange={(e) => setTxtDocumento(e.target.value)}
                  style={{ width: salir === false ? "700px" : "580px" }}
                />
              </MDBox>
              <MDBox pr={2}>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="medium"
                  type="button"
                  onClick={() => Busqueda()}
                >
                  <Icon>search</Icon>&nbsp; Buscar personas en condición de discapacidad
                </MDButton>
              </MDBox>
              {salir && (
                <MDBox pr={2}>
                  <MDButton color="info" size="medium" type="button" onClick={() => Cerrar()}>
                    <Icon>settings_power</Icon>&nbsp;Salir
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          </MDTypography>
        </MDBox>
        {action &&
          (action.type === "internal" ? (
            <MDBox display={{ xs: "none", lg: "inline-block" }}>
              <MDButton
                component={Link}
                to={action.route}
                variant="gradient"
                color={action.color ? action.color : "info"}
                size="small"
              >
                {action.label}
              </MDButton>
            </MDBox>
          ) : (
            <MDBox display={{ xs: "none", lg: "inline-block" }}>
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={action.color ? action.color : "info"}
                size="small"
                sx={{ mt: -0.3 }}
              >
                {action.label}
              </MDButton>
            </MDBox>
          ))}
      </MDBox>
      {renderInfoSB}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
