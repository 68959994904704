/* eslint-disable react/prop-types */
import React, { useReducer, useState } from "react";
import authContext from "./authContext";
import authReducer from "./authReducer";
import { useNavigate } from "react-router-dom";
import {
  REGISTRO_EXITOSO,
  REGISTRO_ERROR,
  OCULTAR_ALERTA,
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  USUARIO_AUTENTICADO,
  CERRAR_SESION,
} from "../../types";

import { Login, Axios } from "../../config/clienteAxios";

import { postLogin } from "../../hooks";

const AuthState = ({ children }) => {
  const [msgSB, setSB] = useState("");
  const [enviando, setEnviando] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const navigate = useNavigate();
  // Definir un state inicial
  const initialState = {
    token: typeof window !== "undefined" ? localStorage.getItem("token") : "",
    autenticado: null,
    usuario: null,
    mensaje: null,
    cargando: null,
  };

  // Definir el reducer
  const [stateReducer, dispatch] = useReducer(authReducer, initialState);
  // Autenticar Usuarios
  const iniciarSesion = async (datos) => {
    const openErrorSB = () => setErrorSB(true);
    setEnviando(true);
    postLogin(datos).then((Result) => {
      const { code, data } = Result;
      if (code === 200) {
        const access_token = data?.access_token;
        const access_data = data?.access_data;
        localStorage.setItem("currentUser", JSON.stringify(access_data));
        dispatch({
          type: LOGIN_EXITOSO,
          payload: access_token,
        });
      } else {
        console.log(Result?.response);
        dispatch({
          type: LOGIN_ERROR,
          payload: Result?.response.data.response,
        });
        setEnviando(false);
        if (Result !== undefined) {
          const { data } = Result?.response;
          const idmsg = data.response.split("-")[0];
          setSB(
            idmsg == "1"
              ? "El correo electrónico es incorrecto"
              : "La Contraseña o password incorrecto"
          );
          openErrorSB();
        }
      }
    });
  };

  // Retorne el Usuario autenticado en base al JWT
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      tokenAuth(token);
    }

    try {
      const { data } = await Login.get("/api/auth");
      if (data.usuario) {
        dispatch({
          type: USUARIO_AUTENTICADO,
          payload: data.usuario,
        });
      }
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: error.response.data.msg,
      });
    }
  };

  // Cerrar la sesión
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
    navigate("./");
  };

  return (
    <authContext.Provider
      value={{
        token: stateReducer.token,
        autenticado: stateReducer.autenticado,
        usuario: stateReducer.usuario,
        mensaje: stateReducer.mensaje,
        cargando: stateReducer.cargando,
        // registrarUsuario,
        iniciarSesion,
        usuarioAutenticado,
        cerrarSesion,
        msgSB,
        setSB,
        errorSB,
        setErrorSB,
        enviando,
        setEnviando,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthState;
