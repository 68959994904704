/* eslint-disable react/jsx-key */
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { InputLabel, NativeSelect } from "@mui/material";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import dataState from "../../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";

function UsuarioPassForm() {
  const DataContext = useContext(dataState);
  const {
    handleSubmitPassUsuario,
    handleUsuarios,
    enviando,
    msgSB,
    errorSB,
    setErrorSB,
    infoSB,
    setInfoSB,
  } = DataContext;
  //const { id } = useParams();
  const closeErrorSB = () => setErrorSB(false);
  const closeInfoSB = () => setInfoSB(false);
  const [controller] = useMaterialUIController();
  const [DatoUser, setDatoUser] = useState({});
  const [errorCC, setErrorCC] = useState(false);

  const closeInfoCC = () => setErrorCC(false);

  useEffect(() => {
    setDatoUser(JSON.parse(localStorage.getItem("currentUser")));
  }, []);

  useEffect(() => {
    if (enviando === false) {
      handleUsuarios();
    }
  }, [enviando]);

  const formik = useFormik({
    initialValues: {
      PASS_ACTUAL: "",
      PASS_NUEVO: "",
      PASS_NUEVO_REPETIR: "",
    },
    validationSchema: Yup.object({
      PASS_ACTUAL: Yup.string().required("La Contraseña Actual no puede ir vacia"),
      PASS_NUEVO: Yup.string().required("La Contraseña a cambiar no puede ir vacia"),
      PASS_NUEVO_REPETIR: Yup.string().required("La Contraseña a cambiar no puede ir vacia"),
    }),
    onSubmit: (datosform) => {
      const openErrorCC = () => setErrorCC(true);
      if (datosform.PASS_NUEVO !== datosform.PASS_NUEVO_REPETIR) {
        openErrorCC();
        return false;
      }

      handleSubmitPassUsuario(datosform, DatoUser.ID_USER, formik);
    },
  });

  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Notificación"
      content="La contraseña se cambio satisfatoriamente..."
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={"Las contraseñas actual no pertenece a este Usuario"}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderErrorCC = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={"Las contraseñas no coinciden"}
      open={errorCC}
      onClose={closeInfoCC}
      close={closeInfoCC}
    />
  );

  return (
    <Card>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Formulario cambio contraseña del Usuario ({DatoUser.NOMBRES})
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDBox
                    xs={12}
                    md={12}
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <MDBox pt={4} pb={3} px={3}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <MDInput
                            type="password"
                            label="Contraseña Actual"
                            variant="standard"
                            id="PASS_ACTUAL"
                            name="PASS_ACTUAL"
                            value={formik.values.PASS_ACTUAL}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.touched.PASS_ACTUAL && formik.errors.PASS_ACTUAL ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.PASS_ACTUAL} </p>
                            </MDTypography>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} style={{ marginTop: "12px" }}>
                        <Grid item xs={12} md={12}>
                          <MDInput
                            type="password"
                            label="Contraseña a cambiar"
                            variant="standard"
                            id="PASS_NUEVO"
                            name="PASS_NUEVO"
                            value={formik.values.PASS_NUEVO}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.touched.PASS_NUEVO && formik.errors.PASS_NUEVO ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.PASS_NUEVO} </p>
                            </MDTypography>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} style={{ marginTop: "12px" }}>
                        <Grid item xs={12} md={12}>
                          <MDInput
                            type="password"
                            label="Repetir Contraseña a cambiar"
                            variant="standard"
                            id="PASS_NUEVO_REPETIR"
                            name="PASS_NUEVO_REPETIR"
                            value={formik.values.PASS_NUEVO_REPETIR}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.touched.PASS_NUEVO_REPETIR && formik.errors.PASS_NUEVO_REPETIR ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>
                                {formik.errors.PASS_NUEVO_REPETIR}{" "}
                              </p>
                            </MDTypography>
                          ) : null}
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox
                      pt={2}
                      px={2}
                      display="flex-1"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDButton type="submit" variant="gradient" color="success" fullWidth>
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        {enviando === false ? "Enviar Cotraseña" : "Enviando..."}
                      </MDButton>
                      <MDTypography variant="h6" fontWeight="medium">
                        &nbsp;
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {renderInfoSB}
      {renderErrorSB}
      {renderErrorCC}
    </Card>
  );
}

export default UsuarioPassForm;
