// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Search from "layouts/search";
import Caracterizacion from "layouts/caracterizacion";
import Cuidadores from "layouts/cuidadores";
import Discapacitados from "layouts/discapacitados";
import FormInformation from "layouts/discapacitados/Formulario";
import FormCuidador from "layouts/cuidadores/Formulario";
import Administrativa from "layouts/usuarios";
import Adminpassword from "layouts/usuarios/components/UsuarioPassForm";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";
//import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
const routes = [
  {
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    key: "form_discapacitados",
    route: "/form_discapacitados",
    component: <FormInformation />,
  },
  {
    key: "form_cuidador",
    route: "/form_cuidador",
    component: <FormCuidador />,
  },
  {
    key: "buscar",
    route: "/search",
    component: <Search />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Area Administrativa",
    key: "Administrativa",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/Administrativa",
    component: <Administrativa />,
  },
  {
    type: "collapse",
    name: "Cuidadores",
    key: "cuidadores",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/cuidadores",
    component: <Cuidadores />,
  },
  {
    type: "collapse",
    name: "Personas en Discapacidad",
    key: "discapacitados",
    icon: <Icon fontSize="small">accessible</Icon>,
    route: "/discapacitados",
    component: <Discapacitados />,
  },
  {
    type: "collapse",
    name: "Caracterización",
    key: "Caracterizacion",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/caracterizacion",
    component: <Caracterizacion />,
  },
  {
    key: "caracterizar",
    route: "/caracterizar/:id",
    component: <Caracterizacion />,
  },
  {
    key: "administrativalink",
    route: "/administrativa/:id",
    component: <Administrativa />,
  },
  {
    key: "adminpassword",
    route: "/adminpassword",
    component: <Adminpassword />,
  },
];

export default routes;
