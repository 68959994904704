/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
//import authState from "../../../context/auth/authContext";
import DataTable from "examples/Tables/DataTable";
import { useFormik } from "formik";
import * as Yup from "yup";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDAlert from "components/MDAlert";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import bgImage from "assets/images/image_riohacha.jpeg";
import Datadiscapacitado from "layouts/search/Datadiscapacitado";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import dataState from "../../context/data/dataContext";
function Search() {
  const DataContext = useContext(dataState);
  const { discapacitadosB, txtDocumento, msgvalidaB, setMsgvalidaB } = DataContext;
  const [msgBuscado, setMsgBuscado] = useState("");

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      Con el numero documento escrito &nbsp;no se encontro ninguna personas en condición de discapacidad
    </MDTypography>
  );
  console.log(discapacitadosB);
  return (
    <BasicLayout image={bgImage}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Persona en condición de Discapacidad
                </MDTypography>
              </MDBox>
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  {discapacitadosB.length === 0 ? (
                    <MDBox p={2}>
                      <MDAlert color="secondary">{alertContent()}</MDAlert>
                    </MDBox>
                  ) : (
                    <Datadiscapacitado Data={discapacitadosB} />
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </BasicLayout>
  );
}

export default Search;
