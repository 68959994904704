import { useState, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";

function PlatformSettings(Props) {
  const DATA = Props.DATA;

  const { setTabForm, setTabValue } = useContext(dataState);
  const localStorageData = localStorage.getItem("I_VIVIENDA");
  const Data = localStorageData ? JSON.parse(localStorageData) : {};

  const [otrosTV, setOtrosTV] = useState(Data.otrosTV ? Data.otrosTV : "");
  const [valueTV, setValueTV] = useState(Data.valueTV ? Data.valueTV : "");
  const [valueCF, setValueCF] = useState(Data.valueCF ? Data.valueCF : "");
  const [valueLV, setValueLV] = useState(Data.valueLV ? Data.valueLV : "");
  const [valueSP, setValueSP] = useState(Data.valueSP ? Data.valueSP : "");
  const [stateSP, setStateSP] = useState({
    SERVICIOP_1: Data?.datoSP?.SERVICIOP_1 ? true : false,
    SERVICIOP_2: Data?.datoSP?.SERVICIOP_2 ? true : false,
    SERVICIOP_3: Data?.datoSP?.SERVICIOP_3 ? true : false,
    SERVICIOP_4: Data?.datoSP?.SERVICIOP_4 ? true : false,
    SERVICIOP_5: Data?.datoSP?.SERVICIOP_5 ? true : false,
    SERVICIOP_6: Data?.datoSP?.SERVICIOP_6 ? true : false,
  });

  const handleChangeSP = (event) => {
    setStateSP({
      ...stateSP,
      [event.target.name]: event.target.checked,
    });
  };
  const datoSP = {
    SERVICIOP_1: stateSP.SERVICIOP_1 === true ? "AGUA" : "",
    SERVICIOP_2: stateSP.SERVICIOP_2 === true ? "LUZ" : "",
    SERVICIOP_3: stateSP.SERVICIOP_3 === true ? "GAS NATURAL" : "",
    SERVICIOP_4: stateSP.SERVICIOP_4 === true ? "ALCANTARILLADO" : "",
    SERVICIOP_5: stateSP.SERVICIOP_5 === true ? "ASEO" : "",
    SERVICIOP_6: stateSP.SERVICIOP_6 === true ? "TV/CABLE" : "",
  };

  const handleRadioChangeTV = (event) => {
    setValueTV(event.target.value);
  };

  const handleRadioChangeLV = (event) => {
    setValueLV(event.target.value);
  };

  const handleRadioChangeCF = (event) => {
    setValueCF(event.target.value);
  };

  const handleRadioChangeSP = (event) => {
    setValueSP(event.target.value);
  };
  const handleAprobar = () => {
    const DataForm = {
      valueTV,
      valueCF,
      valueLV,
      valueSP,
      datoSP,
      otrosTV,
    };
    // console.log(DataForm);
    localStorage.setItem("I_VIVIENDA", JSON.stringify(DataForm));
    console.log(localStorage.getItem("I_VIVIENDA"));
    setTabForm(4);
    setTabValue(4);
  };
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          INFRESTRUCTURA DE LA VIVIENDA
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          TIPO DE VIVIENDA:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].OTRO_TIPO_VIVIENDA === "" ? DATA[0].TIPO_VIVIENDA : DATA[0].OTRO_TIPO_VIVIENDA}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          EL LUGAR DONDE VIVE ES:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].TENENCIA_VIVIENDA}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          CONDICIONES FISICAS DE LA VIVIENDA:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].CONDICIONES_FISICAS_VIVIENDA}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          LA VIVIENDA CUENTA CON SERVICIOS PUBLICOS:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].VIVIENDA_CUENTA_SERVICIOS_PUBLICOS},
            <MDTypography
              variant="button"
              color="text"
              fontWeight="regular"
              style={{ marginLeft: "10px" }}
            >
              {DATA[0].VIVIENDA_SERVICIOS_PUBLICOS}
            </MDTypography>
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
