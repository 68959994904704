import { useState, useContext, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import MDInput from "components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";

function PlatformSettings() {
  const { setTabForm, setTabValue } = useContext(dataState);

  const localStorageData = localStorage.getItem("D_LIMITACION");
  const Data = localStorageData ? JSON.parse(localStorageData) : {};

  const [Opcion_1, setNumOpcionuno] = useState(0);
  const [Opcion_2, setNumOpciondos] = useState(0);

  useEffect(() => {
    if (Object.keys(Data).length > 0) {
      setNumOpcionuno(1);
      setNumOpciondos(1);
    }
  }, [Data]);

  const [stateD, setStateD] = useState({
    DIFUCULTADES_1: Data?.datoD?.DIFUCULTADES_1 ? true : false,
    DIFUCULTADES_2: Data?.datoD?.DIFUCULTADES_2 ? true : false,
    DIFUCULTADES_3: Data?.datoD?.DIFUCULTADES_3 ? true : false,
    DIFUCULTADES_4: Data?.datoD?.DIFUCULTADES_4 ? true : false,
    DIFUCULTADES_5: Data?.datoD?.DIFUCULTADES_5 ? true : false,
    DIFUCULTADES_6: Data?.datoD?.DIFUCULTADES_6 ? true : false,
    DIFUCULTADES_7: Data?.datoD?.DIFUCULTADES_7 ? true : false,
    DIFUCULTADES_8: Data?.datoD?.DIFUCULTADES_8 ? true : false,
    DIFUCULTADES_9: Data?.datoD?.DIFUCULTADES_9 ? true : false,
    DIFUCULTADES_10: Data?.datoD?.DIFUCULTADES_10 ? true : false,
    DIFUCULTADES_11: Data?.datoD?.DIFUCULTADES_11 ? true : false,
    DIFUCULTADES_12: Data?.datoD?.DIFUCULTADES_12 ? true : false,
    DIFUCULTADES_13: Data?.datoD?.DIFUCULTADES_13 ? true : false,
    DIFUCULTADES_14: Data?.datoD?.DIFUCULTADES_14 ? true : false,
  });

  const [stateL, setStateL] = useState({
    LIMITACIONES_1: Data?.datoL?.LIMITACIONES_1 ? true : false,
    LIMITACIONES_2: Data?.datoL?.LIMITACIONES_2 ? true : false,
    LIMITACIONES_3: Data?.datoL?.LIMITACIONES_3 ? true : false,
    LIMITACIONES_4: Data?.datoL?.LIMITACIONES_4 ? true : false,
    LIMITACIONES_5: Data?.datoL?.LIMITACIONES_5 ? true : false,
    LIMITACIONES_6: Data?.datoL?.LIMITACIONES_6 ? true : false,
    LIMITACIONES_7: Data?.datoL?.LIMITACIONES_7 ? true : false,
    LIMITACIONES_8: Data?.datoL?.LIMITACIONES_8 ? true : false,
    LIMITACIONES_9: Data?.datoL?.LIMITACIONES_9 ? true : false,
  });

  const handleChangeD = (event) => {
    setStateD({
      ...stateD,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked === true) setNumOpcionuno(1);
  };

  const handleChangeL = (event) => {
    setStateL({
      ...stateL,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked === true) setNumOpciondos(1);
  };
  const datoD = {
    DIFUCULTADES_1: stateD.DIFUCULTADES_1 === true ? "PENSAR, MEMORIZAR" : "",
    DIFUCULTADES_2:
      stateD.DIFUCULTADES_2 === true
        ? "PERCIBIR LA LUZ, DISTINGUIR OBJETOS O PERSONAS A PESAR DE USAR LENTES O GAFAS"
        : "",
    DIFUCULTADES_3: stateD.DIFUCULTADES_3 === true ? "OIR AÚN CON APARATOS ESPECIALES" : "",
    DIFUCULTADES_4:
      stateD.DIFUCULTADES_4 === true
        ? "MASTICAR, TRAGAR, ASIMILAR Y TRANSFORMAR LOS ALIMENTOS"
        : "",
    DIFUCULTADES_5:
      stateD.DIFUCULTADES_5 === true
        ? "RETENER O EXPULSAR LA ORINA, TENER RELACIONES SEXUALES, TENER HIJOS"
        : "",
    DIFUCULTADES_6:
      stateD.DIFUCULTADES_6 === true
        ? "DESPLAZARSE EN TRECHOS CORTOS POR PROBLEMAS RESPIRATORIOS O DE CORAZÓN"
        : "",
    DIFUCULTADES_7:
      stateD.DIFUCULTADES_7 === true ? "CAMBIAR Y MANTENER LAS POSICIONES DEL CUERPO" : "",
    DIFUCULTADES_8: stateD.DIFUCULTADES_8 === true ? "DISTINGUIR SABORES U OLORES" : "",
    DIFUCULTADES_9: stateD.DIFUCULTADES_9 === true ? "HABLAR Y COMUNICARSE" : "",
    DIFUCULTADES_10: stateD.DIFUCULTADES_10 === true ? "CAMINAR, CORRER, SALTAR" : "",
    DIFUCULTADES_11: stateD.DIFUCULTADES_11 === true ? "MANTENER PIEL, UÑAS Y CABELLO SANOS" : "",
    DIFUCULTADES_12:
      stateD.DIFUCULTADES_12 === true ? "RELACIONARSE CON LAS DEMÁS PERSONAS Y EL ENTORNO" : "",
    DIFUCULTADES_13:
      stateD.DIFUCULTADES_13 === true ? "LLEVAR, MOVER, UTILIZAR OBJETOS CON LAS MANOS" : "",
    DIFUCULTADES_14:
      stateD.DIFUCULTADES_14 === true ? "ALIMENTARSE, ASEARSE, VESTIRSE POR SI MISMO" : "",
  };

  const datoL = {
    LIMITACIONES_1:
      stateL.LIMITACIONES_1 === true ? "APRENDIZAJE Y LA APLICACIÓN DEL CONOCIMIENTO" : "",
    LIMITACIONES_2: stateL.LIMITACIONES_2 === true ? "CUIDADO PERSONAL" : "",
    LIMITACIONES_3: stateL.LIMITACIONES_3 === true ? "MOVILIDAD" : "",
    LIMITACIONES_4: stateL.LIMITACIONES_4 === true ? "AREAS PERSONALES DE LA VIDA" : "",
    LIMITACIONES_5:
      stateL.LIMITACIONES_5 === true ? "INTERACCIONES E INTERELACIONES PERSONALES" : "",
    LIMITACIONES_6: stateL.LIMITACIONES_6 === true ? "VIDA COMUNITARIA, SOCIAL Y CÍVICA" : "",
    LIMITACIONES_7: stateL.LIMITACIONES_7 === true ? "TAREAS Y DEMANDAS GENERALES" : "",
    LIMITACIONES_8: stateL.LIMITACIONES_8 === true ? "COMUNICACIÓN" : "",
    LIMITACIONES_9: stateL.LIMITACIONES_9 === true ? "VIDA DOMÉSTICA" : "",
  };

  const handleAprobar = () => {
    const DataForm = {
      datoL,
      datoD,
    };

    localStorage.setItem("D_LIMITACION", JSON.stringify(DataForm));
    //console.log(localStorage.getItem("D_LIMITACION"));
    setTabForm(3);
    setTabValue(3);
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          DIFICULTADES PERMANENTES
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_1}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_1"
                />
              }
              label="PENSAR, MEMORIZAR"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_2}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_2"
                />
              }
              label="PERCIBIR LA LUZ, DISTINGUIR OBJETOS O PERSONAS A PESAR DE USAR LENTES O GAFAS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_3}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_3"
                />
              }
              label="OIR AÚN CON APARATOS ESPECIALES"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_4}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_4"
                />
              }
              label="MASTICAR, TRAGAR, ASIMILAR Y TRANSFORMAR LOS ALIMENTOS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_5}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_5"
                />
              }
              label="RETENER O EXPULSAR LA ORINA, TENER RELACIONES SEXUALES, TENER HIJOS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_6}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_6"
                />
              }
              label="DESPLAZARSE EN TRECHOS CORTOS POR PROBLEMAS RESPIRATORIOS O DE CORAZÓN"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_7}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_7"
                />
              }
              label="CAMBIAR Y MANTENER LAS POSICIONES DEL CUERPO"
            />
          </FormGroup>
        </FormControl>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_8}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_8"
                />
              }
              label="DISTINGUIR SABORES U OLORES"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_9}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_9"
                />
              }
              label="HABLAR Y COMUNICARSE"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_10}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_10"
                />
              }
              label="CAMINAR, CORRER, SALTAR"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_11}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_11"
                />
              }
              label="MANTENER PIEL, UÑAS Y CABELLO SANOS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_12}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_12"
                />
              }
              label="RELACIONARSE CON LAS DEMÁS PERSONAS Y EL ENTORNO"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_13}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_13"
                />
              }
              label="LLEVAR, MOVER, UTILIZAR OBJETOS CON LAS MANOS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateD.DIFUCULTADES_14}
                  onChange={handleChangeD}
                  name="DIFUCULTADES_14"
                />
              }
              label="ALIMENTARSE, ASEARSE, VESTIRSE POR SI MISMO"
            />
          </FormGroup>
        </FormControl>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          LIMITACIONES PARA EL DESEMPEÑO DE ACTIVADES Y PARTICIPACIÓN
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_1}
                  onChange={handleChangeL}
                  name="LIMITACIONES_1"
                />
              }
              label="APRENDIZAJE Y LA APLICACIÓN DEL CONOCIMIENTO"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_2}
                  onChange={handleChangeL}
                  name="LIMITACIONES_2"
                />
              }
              label="CUIDADO PERSONAL"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_3}
                  onChange={handleChangeL}
                  name="LIMITACIONES_3"
                />
              }
              label="MOVILIDAD"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" sx={{ m: 1 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_4}
                  onChange={handleChangeL}
                  name="LIMITACIONES_4"
                />
              }
              label="AREAS PERSONALES DE LA VIDA"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_5}
                  onChange={handleChangeL}
                  name="LIMITACIONES_5"
                />
              }
              label="INTERACCIONES E INTERELACIONES PERSONALES"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_6}
                  onChange={handleChangeL}
                  name="LIMITACIONES_6"
                />
              }
              label="VIDA COMUNITARIA, SOCIAL Y CÍVICA"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" sx={{ m: 1 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_7}
                  onChange={handleChangeL}
                  name="LIMITACIONES_7"
                />
              }
              label="TAREAS Y DEMANDAS GENERALES"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_8}
                  onChange={handleChangeL}
                  name="LIMITACIONES_8"
                />
              }
              label="COMUNICACIÓN"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateL.LIMITACIONES_9}
                  onChange={handleChangeL}
                  name="LIMITACIONES_9"
                />
              }
              label="VIDA DOMÉSTICA"
            />
          </FormGroup>
        </FormControl>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#fff", textAlign: "center" }}>
        <MDTypography variant="h2" color="white">
          <MDButton
            color="info"
            onClick={handleAprobar}
            style={{ float: "right" }}
            disabled={Opcion_1 === 1 && Opcion_2 === 1 ? false : true}
          >
            Aprobar y Seguir &nbsp;
            <Icon fontSize="large" sx={{ fontWeight: "bold" }}>
              double_arrow_sharp
            </Icon>
          </MDButton>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
