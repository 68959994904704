import { useState, useEffect, useContext } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
/* import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram"; */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
//import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import UsuarioNavbar from "examples/Navbars/UsuarioNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/caracterizacion/components/Header";
import FormGeneral from "layouts/caracterizacion/components/FormGeneral";
import FormEnvioData from "layouts/caracterizacion/components/FormEnvioData";
import FormValororigen from "layouts/caracterizacion/components/FormValororigen";
import FormDificultades from "layouts/caracterizacion/components/FormDificultadesLimit";
import FormInfraestructura from "layouts/caracterizacion/components/FormInfraestructuraViv";
import FormRedPriamaria from "layouts/caracterizacion/components/FormRedPriamaria";

// Data
//import profilesListData from "layouts/profile/data/profilesListData";
import dataState from "../../context/data/dataContext";
function Caracterizacion() {
  const { tabForm } = useContext(dataState);
  //console.log(tabForm);
  return (
    <DashboardLayout>
      <UsuarioNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {tabForm === 0 && (
              <Grid item xs={12} md={12} xl={12}>
                <FormGeneral />
              </Grid>
            )}
            {tabForm === 1 && (
              <Grid item xs={12} md={12} xl={12}>
                <FormValororigen />
              </Grid>
            )}
            {tabForm === 2 && (
              <Grid item xs={12} md={12} xl={12}>
                <FormDificultades />
              </Grid>
            )}
            {tabForm === 3 && (
              <Grid item xs={12} md={12} xl={12}>
                <FormInfraestructura />
              </Grid>
            )}
            {tabForm === 4 && (
              <Grid item xs={12} md={12} xl={12}>
                <FormRedPriamaria />
              </Grid>
            )}
            {tabForm === 5 && (
              <Grid item xs={12} md={12} xl={12}>
                <FormEnvioData />
              </Grid>
            )}
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Caracterizacion;
