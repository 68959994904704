import { useState, useContext, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import MDInput from "components/MDInput";
import RadioGroup from "@mui/material/RadioGroup";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";
function FormValororigen() {
  const { setTabForm, setTabValue } = useContext(dataState);
  const localStorageData = localStorage.getItem("V_ORIGENES");
  const Data = localStorageData ? JSON.parse(localStorageData) : {};
  const [cualCM, setCualCM] = useState(Data?.cualCM ? Data?.cualCM : "");
  const [valueCM, setValueCM] = useState(Data?.valueCM ? Data?.valueCM : "");
  const [Opcion, setNumOpcion] = useState(0);
  const [Opcion_1, setNumOpcionuno] = useState(0);
  const [Opcion_2, setNumOpciondos] = useState(0);
  const [Opcion_3, setNumOpciontres] = useState(0);
  const [msgtext, setMsgtext] = useState(false);
  const [textomsg, setTextomsg] = useState("");
  const closeInfoMT = () => setMsgtext(false);

  useEffect(() => {
    if (Object.keys(Data).length > 0) {
      setNumOpcion(1);
      setNumOpcionuno(1);
      setNumOpciondos(1);
      setNumOpciontres(1);
    }
  }, [Data]);

  const [stateA, setStateA] = useState({
    ALTERACIONES_1: Data?.datoA?.ALTERACIONES_1 ? true : false,
    ALTERACIONES_2: Data?.datoA?.ALTERACIONES_2 ? true : false,
    ALTERACIONES_3: Data?.datoA?.ALTERACIONES_3 ? true : false,
    ALTERACIONES_4: Data?.datoA?.ALTERACIONES_4 ? true : false,
    ALTERACIONES_5: Data?.datoA?.ALTERACIONES_5 ? true : false,
    ALTERACIONES_6: Data?.datoA?.ALTERACIONES_6 ? true : false,
    ALTERACIONES_7: Data?.datoA?.ALTERACIONES_7 ? true : false,
    ALTERACIONES_8: Data?.datoA?.ALTERACIONES_8 ? true : false,
    ALTERACIONES_9: Data?.datoA?.ALTERACIONES_9 ? true : false,
    ALTERACIONES_10: Data?.datoA?.ALTERACIONES_10 ? true : false,
  });
  const [stateO, setStateO] = useState({
    ORIGEN_1: Data?.datoO?.ORIGEN_1 ? true : false,
    ORIGEN_2: Data?.datoO?.ORIGEN_2 ? true : false,
    ORIGEN_3: Data?.datoO?.ORIGEN_3 ? true : false,
    ORIGEN_4: Data?.datoO?.ORIGEN_4 ? true : false,
    ORIGEN_5: Data?.datoO?.ORIGEN_5 ? true : false,
    ORIGEN_6: Data?.datoO?.ORIGEN_6 ? true : false,
    ORIGEN_7: Data?.datoO?.ORIGEN_7 ? true : false,
    ORIGEN_8: Data?.datoO?.ORIGEN_8 ? true : false,
    ORIGEN_9: Data?.datoO?.ORIGEN_9 ? true : false,
    ORIGEN_10: Data?.datoO?.ORIGEN_10 ? true : false,
    ORIGEN_11: Data?.datoO?.ORIGEN_11 ? true : false,
    ORIGEN_12: Data?.datoO?.ORIGEN_12 ? true : false,
  });
  const [stateD, setStateD] = useState({
    TIPOD_1: Data?.datoD?.TIPOD_1 ? true : false,
    TIPOD_2: Data?.datoD?.TIPOD_2 ? true : false,
    TIPOD_3: Data?.datoD?.TIPOD_3 ? true : false,
    TIPOD_4: Data?.datoD?.TIPOD_4 ? true : false,
    TIPOD_5: Data?.datoD?.TIPOD_5 ? true : false,
    TIPOD_6: Data?.datoD?.TIPOD_6 ? true : false,
    TIPOD_7: Data?.datoD?.TIPOD_7 ? true : false,
  });

  const datoA = {
    ALTERACIONES_1: stateA.ALTERACIONES_1 === true ? "EL SISTEMA NERVIOSO" : "",
    ALTERACIONES_2: stateA.ALTERACIONES_2 === true ? "LOS OJOS" : "",
    ALTERACIONES_3: stateA.ALTERACIONES_3 === true ? "LOS OÍDOS" : "",
    ALTERACIONES_4: stateA.ALTERACIONES_4 === true ? "LA PIEL, LAS UÑAS, EL CABELLO" : "",
    ALTERACIONES_5:
      stateA.ALTERACIONES_5 === true
        ? "LOS DE MÁS ORGANOS DE LOS SENTIDOS (Olfato, Tacto y Gusto)"
        : "",
    ALTERACIONES_6: stateA.ALTERACIONES_6 === true ? "LA VOZ Y EL HABLA" : "",
    ALTERACIONES_7:
      stateA.ALTERACIONES_7 === true ? "SISTEMA CARDIO RESPIRATORIO Y LAS DEFENSAS" : "",
    ALTERACIONES_8:
      stateA.ALTERACIONES_8 === true ? "LA DIGESTIÓN, EL METABOLISMO, LAS HORMONAS" : "",
    ALTERACIONES_9: stateA.ALTERACIONES_9 === true ? "SISTEMA GENITAL, URINARIO, REPRODUCTIVO" : "",
    ALTERACIONES_10:
      stateA.ALTERACIONES_10 === true ? "EL MOVIMIENTO DEL CUERPO, MANOS, BRAZOS, PIERNAS" : "",
  };

  const datoO = {
    ORIGEN_1:
      stateO.ORIGEN_1 === true ? "CONDICIONES DE SALUD DE LA MADRE DURANTE EL EMBARAZO" : "",
    ORIGEN_2: stateO.ORIGEN_2 === true ? "ALTERACIÓN GENÉTICA/HEREDITARIA" : "",
    ORIGEN_3: stateO.ORIGEN_3 === true ? "CONSUMO DE PSICOACTIVOS" : "",
    ORIGEN_4: stateO.ORIGEN_4 === true ? "VÍCTIMA DE VIOLENCIA" : "",
    ORIGEN_5: stateO.ORIGEN_5 === true ? "COMPLICACIONES DURANTE EL PARTO" : "",
    ORIGEN_6: stateO.ORIGEN_6 === true ? "LESIÓN AUTOINFLINGIDA" : "",
    ORIGEN_7: stateO.ORIGEN_7 === true ? "DESASTRE NATURAL" : "",
    ORIGEN_8: stateO.ORIGEN_8 === true ? "DIFICULTAD EN LA PRESTACIÓN DE LOS SERVICIOS" : "",
    ORIGEN_9: stateO.ORIGEN_9 === true ? "ENFERMEDAD GENERAL" : "",
    ORIGEN_10: stateO.ORIGEN_10 === true ? "ENFERMEDAD PROFESIONAL" : "",
    ORIGEN_11: stateO.ORIGEN_11 === true ? "ACCIDENTE" : "",
    ORIGEN_12: stateO.ORIGEN_12 === true ? "CONFLICTO ARMADO" : "",
  };

  const datoD = {
    TIPOD_1: stateD.TIPOD_1 === true ? "FISICA" : "",
    TIPOD_2: stateD.TIPOD_2 === true ? "INTELECTUAL" : "",
    TIPOD_3: stateD.TIPOD_3 === true ? "AUDITIVA" : "",
    TIPOD_4: stateD.TIPOD_4 === true ? "VISUAL" : "",
    TIPOD_5: stateD.TIPOD_5 === true ? "SORDOCEGUERA" : "",
    TIPOD_6: stateD.TIPOD_6 === true ? "PSICOSOCIAL" : "",
    TIPOD_7: stateD.TIPOD_7 === true ? "MULTIPLE" : "",
  };

  const handleChangeA = (event) => {
    setStateA({
      ...stateA,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked === true) setNumOpciontres(1);
  };

  const handleChangeO = (event) => {
    setStateO({
      ...stateO,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked === true) setNumOpciondos(1);
  };

  const handleChangeD = (event) => {
    setStateD({
      ...stateD,
      [event.target.name]: event.target.checked,
    });

    if (event.target.checked === true) setNumOpcionuno(1);
  };

  const handleRadioChangeCM = (event) => {
    setValueCM(event.target.value);
    if (valueCM === "") setNumOpcion(Opcion + 1);
  };

  const handleAprobar = () => {
    const openinfo = () => setMsgtext(true);
    if (cualCM === "" && valueCM === "SI") {
      setTextomsg("NO escribio cual control medico");
      openinfo();
      return false;
    }
    const DataForm = {
      datoA,
      datoO,
      datoD,
      valueCM,
      cualCM,
    };

    localStorage.setItem("V_ORIGENES", JSON.stringify(DataForm));
    //console.log(localStorage.getItem("V_ORIGENES"));
    setTabForm(2);
    setTabValue(2);
  };

  const renderInfoSB = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content={textomsg}
      open={msgtext}
      onClose={closeInfoMT}
      close={closeInfoMT}
    />
  );

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          ALTERACIONES PERMANENTES
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_1}
                  onChange={handleChangeA}
                  name="ALTERACIONES_1"
                />
              }
              label="EL SISTEMA NERVIOSO"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_2}
                  onChange={handleChangeA}
                  name="ALTERACIONES_2"
                />
              }
              label="LOS OJOS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_3}
                  onChange={handleChangeA}
                  name="ALTERACIONES_3"
                />
              }
              label="LOS OÍDOS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_4}
                  onChange={handleChangeA}
                  name="ALTERACIONES_4"
                />
              }
              label="LA PIEL, LAS UÑAS, EL CABELLO"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" sx={{ m: 1 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_5}
                  onChange={handleChangeA}
                  name="ALTERACIONES_5"
                />
              }
              label="LOS DE MÁS ORGANOS DE LOS SENTIDOS (Olfato, Tacto y Gusto)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_6}
                  onChange={handleChangeA}
                  name="ALTERACIONES_6"
                />
              }
              label="LA VOZ Y EL HABLA"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_7}
                  onChange={handleChangeA}
                  name="ALTERACIONES_7"
                />
              }
              label="SISTEMA CARDIO RESPIRATORIO Y LAS DEFENSAS"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" sx={{ m: 1 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_8}
                  onChange={handleChangeA}
                  name="ALTERACIONES_8"
                />
              }
              label="LA DIGESTIÓN, EL METABOLISMO, LAS HORMONAS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_9}
                  onChange={handleChangeA}
                  name="ALTERACIONES_9"
                />
              }
              label="SISTEMA GENITAL, URINARIO, REPRODUCTIVO"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateA.ALTERACIONES_10}
                  onChange={handleChangeA}
                  name="ALTERACIONES_10"
                />
              }
              label="EL MOVIMIENTO DEL CUERPO, MANOS, BRAZOS, PIERNAS"
            />
          </FormGroup>
        </FormControl>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={1} style={{ marginTop: "-21px" }}>
          <Grid item xs={2} md={3} style={{ paddingRight: "0px" }}>
            <MDTypography variant="h6">TIENE ALGUN CONTROL MEDICO:</MDTypography>
          </Grid>
          <Grid item xs={2} md={2} style={{ marginTop: "-5px", marginLeft: "-30px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="CM"
              value={valueCM}
              onChange={handleRadioChangeCM}
            >
              <FormControlLabel value="SI" control={<Radio />} label="SI" />
              <FormControlLabel value="NO" control={<Radio />} label="NO" />
            </RadioGroup>
          </Grid>
          <Grid item xs={4} md={4} style={{ marginTop: "-15px", marginLeft: "-50px" }}>
            <MDTypography variant="h6">CUAL:</MDTypography>
            <MDInput
              type="text"
              variant="standard"
              name="cualCM"
              value={cualCM}
              onChange={(e) => setCualCM(e.target.value)}
              disabled={valueCM === "SI" ? false : true}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          ORIGEN DE LA DISCAPACIDAD
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_1} onChange={handleChangeO} name="ORIGEN_1" />
              }
              label="CONDICIONES DE SALUD DE LA MADRE DURANTE EL EMBARAZO"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_2} onChange={handleChangeO} name="ORIGEN_2" />
              }
              label="ALTERACIÓN GENÉTICA/HEREDITARIA"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_3} onChange={handleChangeO} name="ORIGEN_3" />
              }
              label="CONSUMO DE PSICOACTIVOS"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_4} onChange={handleChangeO} name="ORIGEN_4" />
              }
              label="VÍCTIMA DE VIOLENCIA"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" sx={{ m: 1 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_5} onChange={handleChangeO} name="ORIGEN_5" />
              }
              label="COMPLICACIONES DURANTE EL PARTO"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_6} onChange={handleChangeO} name="ORIGEN_6" />
              }
              label="LESIÓN AUTOINFLINGIDA"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_7} onChange={handleChangeO} name="ORIGEN_7" />
              }
              label="DESASTRE NATURAL"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_8} onChange={handleChangeO} name="ORIGEN_8" />
              }
              label="DIFICULTAD EN LA PRESTACIÓN DE LOS SERVICIOS"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" sx={{ m: 1 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_9} onChange={handleChangeO} name="ORIGEN_9" />
              }
              label="ENFERMEDAD GENERAL"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_10} onChange={handleChangeO} name="ORIGEN_10" />
              }
              label="ENFERMEDAD PROFESIONAL"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_11} onChange={handleChangeO} name="ORIGEN_11" />
              }
              label="ACCIDENTE"
            />
            <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_12} onChange={handleChangeO} name="ORIGEN_12" />
              }
              label="CONFLICTO ARMADO"
            />
            {/* <FormControlLabel
              control={
                <Checkbox checked={stateO.ORIGEN_13} onChange={handleChangeO} name="ORIGEN_13" />
              }
              label="OTRA"
            /> */}
          </FormGroup>
        </FormControl>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={1} style={{ marginTop: "-21px" }}>
          <Grid item xs={2} md={3} style={{ paddingRight: "0px" }}>
            <MDTypography variant="h6">TIPO DE DISCAPACIDAD:</MDTypography>
          </Grid>
          <Grid item xs={2} md={9} style={{ marginTop: "-5px", marginLeft: "-50px" }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox checked={stateD.TIPOD_1} onChange={handleChangeD} name="TIPOD_1" />
                }
                label="FISICA"
                labelPlacement="FISICA"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={stateD.TIPOD_2} onChange={handleChangeD} name="TIPOD_2" />
                }
                label="INTELECTUAL"
                labelPlacement="INTELECTUAL"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={stateD.TIPOD_3} onChange={handleChangeD} name="TIPOD_3" />
                }
                label="AUDITIVA"
                labelPlacement="AUDITIVA"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={stateD.TIPOD_4} onChange={handleChangeD} name="TIPOD_4" />
                }
                label="VISUAL"
                labelPlacement="VISUAL"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={stateD.TIPOD_5} onChange={handleChangeD} name="TIPOD_5" />
                }
                label="SORDOCEGUERA"
                labelPlacement="SORDOCEGUERA"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={stateD.TIPOD_6} onChange={handleChangeD} name="TIPOD_6" />
                }
                label="PSICOSOCIAL"
                labelPlacement="PSICOSOCIAL"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={stateD.TIPOD_7} onChange={handleChangeD} name="TIPOD_7" />
                }
                label="MULTIPLE"
                labelPlacement="MULTIPLE"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2} style={{ backgroundColor: "#fff", textAlign: "center" }}>
        <MDTypography variant="h2" color="white">
          <MDButton
            color="info"
            onClick={handleAprobar}
            style={{ float: "right" }}
            disabled={
              Opcion === 1 && Opcion_1 === 1 && Opcion_2 === 1 && Opcion_3 === 1 ? false : true
            }
          >
            Aprobar y Seguir &nbsp;
            <Icon fontSize="large" sx={{ fontWeight: "bold" }}>
              double_arrow_sharp
            </Icon>
          </MDButton>
        </MDTypography>
      </MDBox>
      {renderInfoSB}
    </Card>
  );
}

export default FormValororigen;
