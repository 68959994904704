/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import dataState from "../../../../context/data/dataContext";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDSnackbar from "components/MDSnackbar";

function ListaUsuario({ id, name, user, documento, roll, idroll, noGutter }) {
  const DataContext = useContext(dataState);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [valueIdEli, setValueIdEli] = useState(0);
  const navigate = useNavigate();
  const {
    usuarios,
    handleUsuarios,
    handleeliusers,
    setOpendialog,
    opendialog,
    infoEU,
    setInfoEU,
    setDatoEditUser,
  } = DataContext;

  // const closeErrorSB = () => setErrorSB(false);
  const closeInfoEU = () => setInfoEU(false);

  useEffect(() => {
    handleUsuarios();
  }, []);

  const handleClose = () => {
    setOpendialog(false);
  };

  const handleOpen = (id) => {
    // console.log(id);
    setValueIdEli(id);
    setOpendialog(true);
  };

  const handleQuitar = () => {
    handleeliusers(valueIdEli);
    setOpendialog(false);
  };

  const handleEditar = (Id) => {
    setDatoEditUser(Id);
    // navigate("/administrativa/" + Id);
  };

  const DialogoEliminar = () => (
    <>
      <Dialog
        open={opendialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "#1a73e8" }}>
          {"Eliminar"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: "24px" }}>
            ¿Esta seguro que desea eliminar este Usuario?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleQuitar} autoFocus>
            Aceptar, Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const renderInfoEU = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content="El usuario se elimino satisfatoriamente..."
      open={infoEU}
      onClose={closeInfoEU}
      close={closeInfoEU}
    />
  );

  return (
    <>
      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mb={noGutter ? 0 : 1}
        mt={2}
      >
        <MDBox width="100%" display="flex" flexDirection="column">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
          >
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {name}
            </MDTypography>

            <MDBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}
            >
              <MDBox mr={1}>
                {parseInt(idroll) !== 1 && (
                  <MDButton variant="text" color="error" onClick={() => handleOpen(id)}>
                    <Icon>delete</Icon>&nbsp;ELIMINAR
                  </MDButton>
                )}
              </MDBox>
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={() => handleEditar(id)}
              >
                <Icon>edit</Icon>&nbsp;EDITAR
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Usuario:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                {user}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Documento:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {documento}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDTypography variant="caption" color="text">
            Perfil Usuario:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {roll}
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      <DialogoEliminar />
      {renderInfoEU}
      {/*   {renderErrorSB} */}
    </>
  );
}

// Setting default values for the props of Bill
ListaUsuario.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
/* ListaUsuario.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  documento: PropTypes.string.isRequired,
  roll: PropTypes.string.isRequired,
  idroll: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
}; */

export default ListaUsuario;
