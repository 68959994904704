// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../context/data/dataContext";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { useState, useEffect, useContext } from "react";

function Discapacitados(Props) {
  const DataContext = useContext(dataState);
  const Data = Props.Data.DataD;
  const Cuidador = Props.Data.DataC;
  const { FormatoFecha } = DataContext;

  return (
    <>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MDBox xs={12} md={12} component="form" role="form">
              <MDBox
                borderRadius="lg"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "left" }}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    PERSONAS EN DISCAPACIDAD: {Data.nombres} {Data.apellidos}
                  </MDTypography>
                </MDBox>
                <MDBox pt={0} pb={3} px={3}>
                  <Grid container spacing={1} style={{ marginTop: "12px" }}>
                    <Grid item xs={6} md={2}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Tipo Documento:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.Tipo_documento}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Documento:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.documentos}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Nombres Completos:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.nombres}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Apellidos Completos:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.apellidos}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "12px" }}>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Estado Civil:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.estado_civil}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Etnias:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.Etnias}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Escolaridad:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.Escolaridad}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Estado Escolaridad:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.estado_escolaridad}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "20px" }}>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Genero:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.genero}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Sexo:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.sexo}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Orientacion Sexual:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.Orientacion_sexual}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Fecha de Nacimiento:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {FormatoFecha(Data.fecha_nacimiento)}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "20px" }}>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Telefono:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.telefono}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Dirección:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.direccion}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Comuna:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            N° {Data.comuna}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Correo Electronico:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.correo}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: "20px" }}>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Municipio:
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.Municipio}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Corregimiento
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.Corregimiento}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Barrio Vereda
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {Data.barrio_vereda}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox
                        borderRadius="lg"
                        alignItems="left"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <MDBox width="10%" mr={2} />
                        <MDBox height="100%" mt={0.1} lineHeight={0}>
                          <MDTypography variant="h5" fontWeight="">
                            Fecha Aceptacion
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {FormatoFecha(Data.fecha_aceptacion)}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox
              borderRadius="lg"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              mt={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <Grid container spacing={1} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={12}>
                  <MDBox
                    p={2}
                    mb={1.5}
                    style={{ backgroundColor: "#4971f18a", textAlign: "center" }}
                  >
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      CUIDADOR DE LA PERSONAS EN CONDICIÓN DE DISCAPACIDAD
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={6} md={2}>
                  <MDBox
                    borderRadius="lg"
                    alignItems="left"
                    p={1}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDBox height="100%" mt={0.1} lineHeight={0}>
                      <MDTypography variant="h5" fontWeight="">
                        Tipo Documento:
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {Cuidador.Tipo_documento}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <MDBox
                    borderRadius="lg"
                    alignItems="left"
                    p={1}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDBox height="100%" mt={0.1} lineHeight={0}>
                      <MDTypography variant="h5" fontWeight="">
                        Documento:
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {Cuidador.documentos}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <MDBox
                    borderRadius="lg"
                    alignItems="left"
                    p={1}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDBox height="100%" mt={0.1} lineHeight={0}>
                      <MDTypography variant="h5" fontWeight="">
                        Nombres Completos:
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {Cuidador.nombres}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={6} md={4}>
                  <MDBox
                    borderRadius="lg"
                    alignItems="left"
                    p={1}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDBox width="10%" mr={2} />
                    <MDBox height="100%" mt={0.1} lineHeight={0}>
                      <MDTypography variant="h5" fontWeight="">
                        Apellidos Completos:
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {Cuidador.apellidos}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginBottom: "20px" }}>
                <Grid item xs={6} md={2}>
                  <MDBox
                    borderRadius="lg"
                    alignItems="left"
                    p={1}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDBox width="10%" mr={2} />
                    <MDBox height="100%" mt={0.1} lineHeight={0}>
                      <MDTypography variant="h5" fontWeight="">
                        Parentesco:
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {Cuidador.Parentesco}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <MDBox
                    borderRadius="lg"
                    alignItems="left"
                    p={1}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDBox width="10%" mr={2} />
                    <MDBox height="100%" mt={0.1} lineHeight={0}>
                      <MDTypography variant="h5" fontWeight="">
                        Telefono:
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {Cuidador.telefono}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={6} md={3}>
                  <MDBox
                    borderRadius="lg"
                    alignItems="left"
                    p={1}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDBox width="10%" mr={2} />
                    <MDBox height="100%" mt={0.1} lineHeight={0}>
                      <MDTypography variant="h5" fontWeight="">
                        Correo Electronico:
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {Cuidador.correo}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default Discapacitados;
