import { useState, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";

function PlatformSettings(Props) {
  const DATA = Props.DATA;
  const { setTabForm, setTabValue } = useContext(dataState);
  const localStorageData = localStorage.getItem("R_PRIMARIA");
  const Data = localStorageData ? JSON.parse(localStorageData) : {};

  const [valueRP, setValueRP] = useState(Data.valueRP ? Data.valueRP : "");
  const [valuePP, setValuePP] = useState(Data.valuePP ? Data.valuePP : "");
  const [valueQC, setValueQC] = useState(Data.valueQC ? Data.valueQC : "");
  const [valueRA, setValueRA] = useState(Data.valueRA ? Data.valueRA : "");
  const [valueVC, setValueVC] = useState(Data.valueVC ? Data.valueVC : "");
  const [valueIF, setValueIF] = useState(Data.valueIF ? Data.valueIF : "");
  const [error, setError] = useState(false);

  const [stateAT, setStateAT] = useState({
    ATECNICA_1: Data?.datoAT?.ATECNICA_1 ? true : false,
    ATECNICA_2: Data?.datoAT?.ATECNICA_2 ? true : false,
    ATECNICA_3: Data?.datoAT?.ATECNICA_3 ? true : false,
    ATECNICA_4: Data?.datoAT?.ATECNICA_4 ? true : false,
    ATECNICA_5: Data?.datoAT?.ATECNICA_5 ? true : false,
    ATECNICA_6: Data?.datoAT?.ATECNICA_6 ? true : false,
    ATECNICA_7: Data?.datoAT?.ATECNICA_7 ? true : false,
  });
  const datoAT = {
    ATECNICA_1: stateAT.ATECNICA_1 === true ? "MOVILIDAD" : "",
    ATECNICA_2: stateAT.ATECNICA_2 === true ? "AUDICIÓN" : "",
    ATECNICA_3: stateAT.ATECNICA_3 === true ? "VISIÓN" : "",
    ATECNICA_4: stateAT.ATECNICA_4 === true ? "ADAPTACIONES PARA LA VIVIENDA" : "",
    ATECNICA_5: stateAT.ATECNICA_5 === true ? "DESARROLLO DEL CUIDADO PERSONAL" : "",
    ATECNICA_6: stateAT.ATECNICA_6 === true ? "DESARROLLO DEL CUIDADO PERSONAL" : "",
    ATECNICA_7: stateAT.ATECNICA_7 === true ? "OTRO" : "",
  };

  const handleChangeAT = (event) => {
    setStateAT({
      ...stateAT,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRadioChangeRP = (event) => {
    setValueRP(event.target.value);
  };

  const handleRadioChangePP = (event) => {
    setValuePP(event.target.value);
  };

  const handleRadioChangeQC = (event) => {
    setValueQC(event.target.value);
  };

  const handleRadioChangeRA = (event) => {
    setValueRA(event.target.value);
  };

  const handleRadioChangeVC = (event) => {
    setValueVC(event.target.value);
  };

  const handleRadioChangeIF = (event) => {
    setValueIF(event.target.value);
  };
  const [datotexto, setDatoTexto] = useState({
    otroPP: Data?.datotexto?.otroPP ? Data.datotexto.otroPP : "",
    otroAT: Data?.datotexto?.otroAT ? Data.datotexto.otroAT : "",
    otroVC: Data?.datotexto?.otroVC ? Data.datotexto.otroVC : "",
    otroIF: Data?.datotexto?.otroIF ? Data.datotexto.otroIF : "",
    otroRP: Data?.datotexto?.otroRP ? Data.datotexto.otroRP : "",
  });

  // Leer los datos del caja de texto
  const onChangeTexto = (e) => {
    setDatoTexto({
      ...datotexto,
      [e.target.name]: e.target.value,
    });
  };
  const handleAprobar = () => {
    const DataForm = {
      valueRP,
      valuePP,
      valueQC,
      valueRA,
      valueVC,
      valueIF,
      datoAT,
      datotexto,
    };
    // console.log(DataForm);
    localStorage.setItem("R_PRIMARIA", JSON.stringify(DataForm));
    console.log(localStorage.getItem("R_PRIMARIA"));
    setTabForm(5);
    setTabValue(5);
  };
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          RELACIONES CON RED PRIMARIA
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          REQUIERE AYUDA PERMANENTE DE OTRA PERSONA:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].REQUIERE_AYUDA_PERMANENTE_PERSONA}
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].CUANTAS_HORAS_DIA_CUENTACON_CUIDADO}
            </MDTypography>
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          QUIEN LO CUIDA:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].QUIEN_LOCUIDA}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          REQUIERE DE AYUDAS O DISPOSITIVOS PARA SU ASISTENCIA:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].REQUIERE_AYUDAS_DISPOSITIVOS_ASISTENCIA}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          AYUDA TÉCNICA PARA LA:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].AYUDA_TECNICA_PARA},
            <MDTypography variant="button" color="text" fontWeight="regular">
              {DATA[0].OTRO_AYUDA_TECNICA_PARA}
            </MDTypography>
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          VÍCTIMA DE CONFLICTO ARMADO:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].CUAL_VICTIMA_CONFLICTO_ARMADO === ""
              ? DATA[0].VICTIMA_CONFLICTO_ARMADO
              : DATA[0].CUAL_VICTIMA_CONFLICTO_ARMADO}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          POBLACIÓN DE INCLUISÓN POR OFICIO:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].CUAL_POBLACION_INCLUSION_POROFICIO === ""
              ? DATA[0].POBLACION_INCLUSION_POROFICIO
              : DATA[0].CUAL_POBLACION_INCLUSION_POROFICIO}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          POBLACIONES DIFERENCIALES Y DE INCLUSIÓN:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].CUAL_POBLACIONES_DIFERENCIALES_INCLUSION === ""
              ? DATA[0].POBLACIONES_DIFERENCIALES_INCLUSION
              : DATA[0].CUAL_POBLACIONES_DIFERENCIALES_INCLUSION}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
