import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UsuarioNavbar from "examples/Navbars/UsuarioNavbar";
import Footer from "examples/Footer";

// Billing page components
import UsuarioForm from "layouts/usuarios/components/UsuarioForm";
import UsuarioEditForm from "layouts/usuarios/components/UsuarioEditForm";
import UsuarioInformation from "layouts/usuarios/components/UsuarioInformation";
import dataState from "../../context/data/dataContext";
function AreAdmin() {
  const DataContext = useContext(dataState);
  const { usuarios, handleUsuarios, DatoEditUser } = DataContext;
  const { id } = useParams();
  useEffect(() => {
    handleUsuarios();
  }, []);
  return (
    <DashboardLayout>
      <UsuarioNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {DatoEditUser === 0 && <UsuarioForm />}
                  {DatoEditUser > 0 && <UsuarioEditForm Data={usuarios} />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              <UsuarioInformation />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AreAdmin;
