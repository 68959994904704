/* eslint-disable react/prop-types */
import React, { useReducer, useState } from "react";
import dataContext from "./dataContext";
import dataReduce from "./dataReduce";
import * as moment from "moment";

import {
  postUsuarios,
  putUsuarios,
  putUsuariosPass,
  deleteUsersId,
  postCuidadores,
  putCuidadores,
  postDiscapacitados,
  putDiscapacitados,
  postCaracterizacion,
  postDiscapacitadoB,
  postDiscapacitadoParam,
  getCuidadores,
  deleteCuidadoresId,
  getUsuarios,
  getPerfilUser,
  getDiscapacitados,
  deleteDiscapacitadoId,
  getCaracterizacion,
  getDiscapacitadosId,
  getCuidadoresId,
  postCuidadorParam,
  getMunicipio,
  getEscolaridad,
  getVeredas_barrios,
  getCorregimiento,
  getTipodocumento,
  getOrientacionSexual,
  getEtnias,
  getParentesco,
} from "../../hooks";

import {
  REGISTRO_USUARIOS,
  REGISTRO_USUARIOS_EXITOSO,
  REGISTRO_USUARIOS_ERROR,
  REGISTRO_USERPASS,
  REGISTRO_USERPASS_EXITOSO,
  REGISTRO_USERPASS_ERROR,
  ACTUALIZA_USUARIOS,
  ACTUALIZA_USUARIOS_EXITOSO,
  ACTUALIZA_USUARIOS_ERROR,
  OBTENER_USUARIOS,
  OBTENER_USUARIOS_SUCCESS,
  OBTENER_USUARIOS_ERROR,
  OBTENER_ROLUSER,
  DELETE_USUARIOS,
  DELETE_USUARIOS_EXITOSO,
  DELETE_USUARIOS_ERROR,
  OBTENER_ROLUSER_SUCCESS,
  OBTENER_ROLUSER_ERROR,
  REGISTRO_CUIDADOR,
  REGISTRO_CUIDADOR_EXITOSO,
  REGISTRO_CUIDADOR_ERROR,
  OBTENER_CUIDADOR,
  OBTENER_CUIDADOR_EXITOSO,
  OBTENER_CUIDADOR_ERROR,
  DELETE_CUIDADOR,
  DELETE_CUIDADOR_EXITOSO,
  DELETE_CUIDADOR_ERROR,
  REGISTRO_DISCAPACITADO,
  REGISTRO_DISCAPACITADO_EXITOSO,
  REGISTRO_DISCAPACITADO_ERROR,
  OBTENER_DISCAPACITADO,
  OBTENER_DISCAPACITADO_EXITOSO,
  OBTENER_DISCAPACITADO_ERROR,
  DELETE_DISCAPACITADO,
  DELETE_DISCAPACITADO_EXITOSO,
  DELETE_DISCAPACITADO_ERROR,
  BUSCAR_DISCAPACITADO,
  BUSCAR_DISCAPACITADO_EXITOSO,
  BUSCAR_DISCAPACITADO_ERROR,
  REGISTRO_CARACTERIZACION,
  REGISTRO_CARACTERIZACION_EXITOSO,
  REGISTRO_CARACTERIZACION_ERROR,
  OBTENER_CARACTERIZACION,
  OBTENER_CARACTERIZACION_EXITOSO,
  OBTENER_CARACTERIZACION_ERROR,
  OBTENER_MUNICIPIO,
  OBTENER_MUNICIPIO_SUCCESS,
  OBTENER_MUNICIPIO_ERROR,
  OBTENER_CORREGIMIENTO,
  OBTENER_CORREGIMIENTO_SUCCESS,
  OBTENER_CORREGIMIENTO_ERROR,
  OBTENER_BARRIO,
  OBTENER_BARRIO_SUCCESS,
  OBTENER_BARRIO_ERROR,
  OBTENER_TIPODOC,
  OBTENER_TIPODOC_SUCCESS,
  OBTENER_TIPODOC_ERROR,
  OBTENER_ESCOLAR,
  OBTENER_ESCOLAR_SUCCESS,
  OBTENER_ESCOLAR_ERROR,
  OBTENER_OSEXUAL,
  OBTENER_OSEXUAL_SUCCESS,
  OBTENER_OSEXUAL_ERROR,
  OBTENER_ETNIAS,
  OBTENER_ETNIAS_SUCCESS,
  OBTENER_ETNIAS_ERROR,
  OBTENER_PARENTESCO,
  OBTENER_PARENTESCO_SUCCESS,
  OBTENER_PARENTESCO_ERROR,
} from "../../types";

const DataState = ({ children }) => {
  const [enviando, setEnviando] = useState(false);
  const [msgSB, setMsgSB] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [infoEU, setInfoEU] = useState(false);
  const [infoEditSB, setInfoEditSB] = useState(false);
  const [infoEditUSER, setInfoEditUSER] = useState(false);
  const [opendialog, setOpendialog] = useState(false);
  const [openFull, setOpenFull] = useState(false);
  const [openFormdialog, setOpenFormdialog] = useState(0);
  const [msgvalidaB, setMsgvalidaB] = useState(false);
  const [txtDocumento, setTxtDocumento] = useState(0);
  const [DatoEditUser, setDatoEditUser] = useState(0);
  //Full-screen dialogs
  const [tabForm, setTabForm] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [valuediscapacida, setValuediscapacida] = useState(0);

  const [valueIdEli, setValueIdEli] = useState(0);
  const [valueIdEdit, setValueIdEdit] = useState(0);
  const [valueIdCaratec, setValueIdCaratec] = useState(0);

  const [valueIdEliC, setValueIdEliC] = useState(0);
  const [valueIdEditC, setValueIdEditC] = useState(0);

  const handleResetU = ({ values }) => {
    (values.Id_perfil = ""), (values.Usuario = ""), (values.Nombres = ""), (values.Documentos = "");
  };

  const handleResetPASS = ({ values }) => {
    (values.PASS_ACTUAL = ""), (values.PASS_NUEVO = ""), (values.PASS_NUEVO_REPETIR = "");
  };

  const handleReset = ({ values }) => {
    (values.Id_cuidador = ""),
      (values.Id_municipio = ""),
      (values.Id_corregimiento = ""),
      (values.Id_tipo_documento = ""),
      (values.Id_escolaridad = ""),
      (values.Id_etnias = ""),
      (values.Id_orientacion_sexual = ""),
      (values.Estado_escolaridad = ""),
      (values.Estado_civil = ""),
      (values.Documentos = ""),
      (values.Nombres = ""),
      (values.Apellidos = ""),
      (values.Genero = ""),
      (values.Sexo = ""),
      (values.Correo = ""),
      (values.Barrio_vereda = ""),
      (values.Direccion = ""),
      (values.Comuna = ""),
      (values.Telefono = ""),
      (values.Estado = ""),
      (values.Fecha_nacimiento = ""),
      (values.FechaIngreso = ""),
      (values.Fecha_aceptacion = "");
  };

  const initialState = {
    usuarios: [],
    perfiluser: [],
    cuidadores: [],
    discapacitados: [],
    discapacitadoB: [],
    caracterizacion: [],
    municipio: [],
    corregimiento: [],
    barrio: [],
    tipodoc: [],
    escolarida: [],
    orentacionsexual: [],
    etnias: [],
    parentesco: [],
    cargando: null,
    mensaje: null,
    eliminar: null,
  };

  // Definir el reducer
  const [state, dispatch] = useReducer(dataReduce, initialState);

  const FormatoFecha = (fech) => {
    const fecha = moment(fech).format("DD/MM/YYYY");
    return fecha;
  };

  const handleSubmitUsuario = (ObjDatos, formik) => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoSB = () => setInfoSB(true);
    setEnviando(true);
    dispatch({
      type: REGISTRO_USUARIOS,
      payload: true,
    });
    postUsuarios(ObjDatos).then((Result) => {
      const { code, data } = Result;
      if (code === 201) {
        dispatch({
          type: REGISTRO_USUARIOS_EXITOSO,
          //payload: data,
        });
        handleUsuarios();
        setEnviando(false);
        openInfoSB();
        handleResetU(formik);
      } else {
        dispatch({
          type: REGISTRO_USUARIOS_ERROR,
          payload: Result,
        });
        setMsgSB(Result.response.split("-")[1]);
        setEnviando(false);
        openErrorSB();
      }
    });
  };

  const handleSubmitEditUsuario = (ObjDatos, Id) => {
    const openErrorSB = () => setErrorSB(true);
    //  const openInfoSB = () => setInfoSB(true);
    const openInfoeditSB = () => setInfoEditSB(true);
    setEnviando(true);
    dispatch({
      type: ACTUALIZA_USUARIOS,
      payload: true,
    });
    putUsuarios(ObjDatos, Id).then((Result) => {
      // console.log(Result);
      const { code, data } = Result;
      if (code === 200) {
        dispatch({
          type: ACTUALIZA_USUARIOS_EXITOSO,
        });

        handleUsuarios();
        setEnviando(false);
        setDatoEditUser(0);
        openInfoeditSB();
      } else {
        dispatch({
          type: ACTUALIZA_USUARIOS_ERROR,
          payload: Result,
        });
        setEnviando(false);
        openErrorSB();
      }
    });
  };

  const handleSubmitPassUsuario = (ObjDatos, Id, formik) => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoSB = () => setInfoSB(true);
    setEnviando(true);
    dispatch({
      type: REGISTRO_USERPASS,
      payload: true,
    });
    putUsuariosPass(ObjDatos, Id).then((Result) => {
      // console.log(Result);
      const { code } = Result;
      if (code === 200) {
        dispatch({
          type: REGISTRO_USERPASS_EXITOSO,
        });
        handleResetPASS(formik);
        setEnviando(false);
        openInfoSB();
      } else {
        dispatch({
          type: REGISTRO_USERPASS_ERROR,
          payload: Result,
        });
        setEnviando(false);
        openErrorSB();
      }
    });
  };

  const handleSubmitC = (ObjDatos, formik) => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoSB = () => setInfoSB(true);
    setEnviando(true);
    dispatch({
      type: REGISTRO_CUIDADOR,
      payload: true,
    });
    postCuidadores(ObjDatos).then((Result) => {
      const { code, data } = Result;
      if (code === 201) {
        dispatch({
          type: REGISTRO_CUIDADOR_EXITOSO,
          payload: data,
        });
        handleCuidador();
        setEnviando(false);
        openInfoSB();
        handleReset(formik);
      } else {
        dispatch({
          type: REGISTRO_CUIDADOR_ERROR,
          payload: Result,
        });
        setEnviando(false);
        openErrorSB();
      }
    });
  };

  const handleSubmitEditC = (ObjDatos, Id) => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoeditSB = () => setInfoEditSB(true);
    setEnviando(true);
    //console.log(Id);
    putCuidadores(ObjDatos, Id).then((Result) => {
      console.log(Result);
      const { code, data } = Result;
      if (code === 200) {
        handleCuidador();
        setEnviando(false);
        openInfoeditSB();
        setTimeout(() => {
          setInfoEditSB(false);
          setOpenFull(false);
        }, 2500);
      } else {
        setEnviando(false);
        openErrorSB();
      }
    });
  };

  const handleSubmitD = (ObjDatos, formik) => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoSB = () => setInfoSB(true);
    setEnviando(true);
    dispatch({
      type: REGISTRO_DISCAPACITADO,
      payload: true,
    });
    postDiscapacitados(ObjDatos).then((Result) => {
      const { code, data } = Result;
      if (code === 201) {
        dispatch({
          type: REGISTRO_DISCAPACITADO_EXITOSO,
          payload: data,
        });
        handleDiscapacitado();
        setEnviando(false);
        openInfoSB();
        handleReset(formik);
      } else {
        dispatch({
          type: REGISTRO_DISCAPACITADO_ERROR,
          payload: Result,
        });
        setEnviando(false);
        openErrorSB();
      }
    });
  };

  const handleSubmitEditD = (ObjDatos, Id) => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoeditSB = () => setInfoEditSB(true);
    setEnviando(true);
    putDiscapacitados(ObjDatos, Id).then((Result) => {
      const { code, data } = Result;
      if (code === 200) {
        handleDiscapacitado();
        setEnviando(false);
        openInfoeditSB();
        setTimeout(() => {
          setInfoEditSB(false);
          setOpenFull(false);
        }, 2500);
      } else {
        setEnviando(false);
        openErrorSB();
      }
    });
  };

  const handleSubmitCRZ = (ObjDatos) => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoSB = () => setInfoSB(true);
    setEnviando(true);
    dispatch({
      type: REGISTRO_CARACTERIZACION,
      payload: true,
    });
    postCaracterizacion(ObjDatos).then((Result) => {
      console.log(Result);
      const { code, data } = Result;
      if (code === 201) {
        dispatch({
          type: REGISTRO_CARACTERIZACION_EXITOSO,
          //payload: data,
        });
        setEnviando(false);
        setValuediscapacida(0);
        openInfoSB();
        localStorage.removeItem("I_GENERAL");
        localStorage.removeItem("I_VIVIENDA");
        localStorage.removeItem("D_LIMITACION");
        localStorage.removeItem("R_PRIMARIA");
        localStorage.removeItem("V_ORIGENES");
      } else {
        dispatch({
          type: REGISTRO_CARACTERIZACION_ERROR,
          payload: Result,
        });
        setEnviando(false);
        openErrorSB();
      }
    });
  };

  // Retorne el usuarios
  const handleUsuarios = () => {
    dispatch({ type: OBTENER_USUARIOS });
    getUsuarios().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_USUARIOS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_USUARIOS_ERROR,
          payload: datos,
        });
      }
    });
  };

  // Retorne el perfil usuarios
  const handlePerfilUser = () => {
    dispatch({ type: OBTENER_ROLUSER });
    getPerfilUser().then((datos) => {
      //console.log(datos);
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_ROLUSER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_ROLUSER_ERROR,
          payload: datos,
        });
      }
    });
  };

  // Retorne el Municipio
  const handleMunicipio = () => {
    dispatch({ type: OBTENER_MUNICIPIO });
    getMunicipio().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_MUNICIPIO_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_MUNICIPIO_ERROR,
          payload: datos,
        });
      }
    });
  };

  // Retorne el Corremiento
  const handleCorregimiento = () => {
    dispatch({ type: OBTENER_CORREGIMIENTO });
    getCorregimiento().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_CORREGIMIENTO_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_CORREGIMIENTO_ERROR,
          payload: datos,
        });
      }
    });
  };

  // Retorne el barrios
  const handleVeredas_barrios = () => {
    dispatch({ type: OBTENER_BARRIO });
    getVeredas_barrios().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_BARRIO_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_BARRIO_ERROR,
          payload: datos,
        });
      }
    });
  };

  // Retorne el barrios
  const handleTipodoc = () => {
    dispatch({ type: OBTENER_TIPODOC });
    getTipodocumento().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_TIPODOC_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_TIPODOC_ERROR,
          payload: datos,
        });
      }
    });
  };

  // Retorne el barrios
  const handleEscolarida = () => {
    dispatch({ type: OBTENER_ESCOLAR });
    getEscolaridad().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_ESCOLAR_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_ESCOLAR_ERROR,
          payload: datos,
        });
      }
    });
  };

  const handleOrientacionSexual = () => {
    dispatch({ type: OBTENER_OSEXUAL });
    getOrientacionSexual().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_OSEXUAL_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_OSEXUAL_ERROR,
          payload: datos,
        });
      }
    });
  };

  const handletEtnias = () => {
    dispatch({ type: OBTENER_ETNIAS });
    getEtnias().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_ETNIAS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_ETNIAS_ERROR,
          payload: datos,
        });
      }
    });
  };

  const handletParentesco = () => {
    dispatch({ type: OBTENER_PARENTESCO });
    getParentesco().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_PARENTESCO_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_PARENTESCO_ERROR,
          payload: datos,
        });
      }
    });
  };

  const handleCuidador = () => {
    dispatch({ type: OBTENER_CUIDADOR });
    getCuidadores().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_CUIDADOR_EXITOSO,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_CUIDADOR_ERROR,
          payload: datos,
        });
      }
    });
  };

  const buscarCuidadorParam = (ObjParam) => {
    setEnviando(true);
    dispatch({ type: OBTENER_CUIDADOR });
    postCuidadorParam(ObjParam).then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        setEnviando(false);
        dispatch({
          type: OBTENER_CUIDADOR_EXITOSO,
          payload: data,
        });
      } else {
        setEnviando(false);
        dispatch({
          type: OBTENER_CUIDADOR_ERROR,
          payload: datos.response,
        });
      }
    });
  };

  const handleDiscapacitado = () => {
    dispatch({ type: OBTENER_DISCAPACITADO });
    getDiscapacitados().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_DISCAPACITADO_EXITOSO,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_DISCAPACITADO_ERROR,
          payload: datos,
        });
      }
    });
  };

  const handleCaraterizacion = () => {
    dispatch({ type: OBTENER_CARACTERIZACION });
    getCaracterizacion().then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_CARACTERIZACION_EXITOSO,
          payload: data,
        });
      } else {
        dispatch({
          type: OBTENER_CARACTERIZACION_ERROR,
          payload: datos,
        });
      }
    });
  };

  const buscarDiscapacitado = (ObjParam) => {
    //msgvalidaB, setMsgvalidaB
    dispatch({ type: BUSCAR_DISCAPACITADO });
    postDiscapacitadoB(ObjParam).then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: BUSCAR_DISCAPACITADO_EXITOSO,
          payload: data,
        });
        setTxtDocumento(0);
        // setMsgvalidaB(true);
      } else {
        dispatch({
          type: BUSCAR_DISCAPACITADO_EXITOSO,
          payload: datos.response,
        });
        //  setTxtDocumento("");
        // setMsgvalidaB(true);
      }
    });
  };

  const buscarDiscapacitadoParam = (ObjParam) => {
    setEnviando(true);
    dispatch({ type: OBTENER_DISCAPACITADO });
    postDiscapacitadoParam(ObjParam).then((datos) => {
      const { code, data } = datos;
      if (code === 200) {
        dispatch({
          type: OBTENER_DISCAPACITADO_EXITOSO,
          payload: data,
        });
        setEnviando(false);
      } else {
        dispatch({
          type: OBTENER_DISCAPACITADO_ERROR,
          payload: datos.response,
        });
        setEnviando(false);
      }
    });
  };

  const handleeliDiscapacitado = () => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoSB = () => setInfoSB(true);
    dispatch({ type: DELETE_CUIDADOR });
    deleteDiscapacitadoId(valueIdEli).then((datos) => {
      const { code } = datos;
      handleDiscapacitado();
      if (code === 200) {
        dispatch({
          type: DELETE_CUIDADOR_EXITOSO,
        });
        openInfoSB();
      } else {
        dispatch({
          type: DELETE_CUIDADOR_ERROR,
          payload: datos,
        });
        openErrorSB();
      }
    });
  };

  const handleeliCuidadores = () => {
    const openErrorSB = () => setErrorSB(true);
    const openInfoSB = () => setInfoSB(true);
    dispatch({ type: DELETE_DISCAPACITADO });
    deleteCuidadoresId(valueIdEliC).then((datos) => {
      const { code } = datos;
      handleCuidador();
      if (code === 200) {
        dispatch({
          type: DELETE_DISCAPACITADO_EXITOSO,
        });
        openInfoSB();
      } else {
        dispatch({
          type: DELETE_DISCAPACITADO_ERROR,
          payload: datos,
        });
        openErrorSB();
      }
    });
  };

  const handleeliusers = (Id) => {
    //const openErrorSB = () => setErrorSB(true);
    const openInfoSB = () => setInfoEU(true);
    dispatch({ type: DELETE_USUARIOS });
    deleteUsersId(Id).then((datos) => {
      const { code } = datos;
      handleUsuarios();
      if (code === 200) {
        dispatch({
          type: DELETE_USUARIOS_EXITOSO,
        });
        openInfoSB();
      } else {
        dispatch({
          type: DELETE_USUARIOS_ERROR,
          payload: datos,
        });
        // openErrorSB();
      }
    });
  };

  /*     const handleEditarUser = (Data,Id) => {


    };
 */
  return (
    <dataContext.Provider
      value={{
        usuarios: state.usuarios,
        perfiluser: state.perfiluser,
        cuidadores: state.cuidadores,
        discapacitados: state.discapacitados,
        caracterizacion: state.caracterizacion,
        discapacitadosB: state.discapacitadoB,
        municipio: state.municipio,
        corregimiento: state.corregimiento,
        barrio: state.barrio,
        tipodoc: state.tipodoc,
        escolarida: state.escolarida,
        orentacionsexual: state.orentacionsexual,
        etnias: state.etnias,
        parentesco: state.parentesco,
        mensaje: state.mensaje,
        enviando,
        FormatoFecha,
        handleUsuarios,
        handleeliusers,
        handlePerfilUser,
        handleCuidador,
        buscarCuidadorParam,
        handleeliCuidadores,
        handleDiscapacitado,
        handleeliDiscapacitado,
        handleMunicipio,
        handleCorregimiento,
        handleVeredas_barrios,
        handleEscolarida,
        handleTipodoc,
        handleOrientacionSexual,
        handletEtnias,
        handletParentesco,
        handleSubmitUsuario,
        handleSubmitEditUsuario,
        handleSubmitPassUsuario,
        handleSubmitC,
        handleSubmitEditC,
        handleSubmitD,
        handleSubmitEditD,
        handleSubmitCRZ,
        handleCaraterizacion,
        buscarDiscapacitado,
        buscarDiscapacitadoParam,
        msgSB,
        setMsgSB,
        errorSB,
        setErrorSB,
        infoSB,
        setInfoSB,
        infoEditSB,
        setInfoEditSB,
        infoEditUSER,
        setInfoEditUSER,
        infoEU,
        setInfoEU,
        tabForm,
        setTabForm,
        tabValue,
        setTabValue,
        valuediscapacida,
        setValuediscapacida,
        txtDocumento,
        setTxtDocumento,
        DatoEditUser,
        setDatoEditUser,
        msgvalidaB,
        setMsgvalidaB,
        opendialog,
        setOpendialog,
        openFull,
        setOpenFull,
        setValueIdEli,
        valueIdEdit,
        setValueIdEdit,
        valueIdEliC,
        setValueIdEliC,
        valueIdEditC,
        setValueIdEditC,
        valueIdCaratec,
        setValueIdCaratec,
        openFormdialog,
        setOpenFormdialog,
      }}
    >
      {children}
    </dataContext.Provider>
  );
};

export default DataState;
