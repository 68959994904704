import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import authState from "../../../context/auth/authContext";

import { useFormik } from "formik";
import * as Yup from "yup";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
import bgImage from "assets/images/image_riohacha.jpeg";
import MDSnackbar from "components/MDSnackbar";

function Login() {
  const AuthContext = useContext(authState);
  const { msgSB, errorSB, setErrorSB, enviando, setEnviando, autenticado, iniciarSesion } =
    AuthContext;
  const closeErrorSB = () => setErrorSB(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (autenticado) {
      setEnviando(false);
      navigate("/dashboard");
    }
  }, [autenticado]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("El email no es válido").required("El Email es Obligatorio"),
      password: Yup.string().required("El password no puede ir vacio"),
    }),
    onSubmit: (datosform) => {
      iniciarSesion({ users: datosform.email, password: datosform.password });
    },
  });

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Notificación"
      content={msgSB}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            SIAD
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Sistema para Administrar Información de personas en Discapacidad
            </MDTypography>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Correo electrónico"
                fullWidth
              />
              {formik.touched.email && formik.errors.email ? (
                <MDTypography variant="subtitle2" color="error">
                  <p style={{ fontSize: "12px" }}>{formik.errors.email} </p>
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Contraseña"
                fullWidth
              />
              {formik.touched.password && formik.errors.password ? (
                <MDTypography variant="subtitle2" color="error">
                  <p style={{ fontSize: "12px" }}>{formik.errors.password} </p>
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                {enviando == false ? "Iniciar sesión" : "Iniciando sesión..."}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Ingresa tu Correo electrónico y Contraseña para iniciar sesión{" "}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {renderErrorSB}
    </CoverLayout>
  );
}

export default Login;
