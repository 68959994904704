import { useState, useEffect, useContext } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UsuarioNavbar from "examples/Navbars/UsuarioNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import dataState from "../../context/data/dataContext";
// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";

function Dashboard() {
  const DataContext = useContext(dataState);
  const { handleDiscapacitado, handleCaraterizacion, discapacitados, caracterizacion } =
    DataContext;
  const [fechaRestar, setFechaRestar] = useState("");
  const buscarFecha = () => {
    let hoy = new Date();
    let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 7;
    let suma = hoy.getTime() - semanaEnMilisegundos; //getTime devuelve milisegundos de esa
    let ANNIO = new Date(suma).getFullYear();
    let f_mes = new Date(suma).getMonth() + 1;
    let f_dia = new Date(suma).getDay();
    let MES = f_mes >= 9 ? f_mes : "0" + f_mes;
    let DIAS = f_dia >= 9 ? f_dia : "0" + f_dia;
    let fechaDDF = ANNIO + "-" + MES + "-" + DIAS;
    setFechaRestar(fechaDDF);
  };

  useEffect(() => {
    handleDiscapacitado();
  }, []);
  useEffect(() => {
    handleCaraterizacion();
  }, []);

  useEffect(() => {
    buscarFecha();
  }, []);

  const D_estado = discapacitados?.filter((x) => x.estado === "0");
  const C_fecha = caracterizacion?.filter((x) => x.FECHA >= fechaRestar);

  // console.log(D_fecha);
  return (
    <>
      <DashboardLayout>
        <UsuarioNavbar />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="block"
                  title="Total contados"
                  count={D_estado.length}
                  percentage={{
                    color: "success",
                    label: "Personas Desabilitadas",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="addchart"
                  title="Total contados"
                  count={C_fecha.length}
                  percentage={{
                    color: "success",
                    //amount: "3%",
                    label: "Caracterizados ultima senama",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="person_add"
                  title="Total contados"
                  count={caracterizacion.length}
                  percentage={{
                    color: "success",
                    label: "Personas Caracterizadas",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="accessible"
                  title="Total contados"
                  count={discapacitados.length}
                  percentage={{
                    color: "success",
                    label: "Personas en Discapacidad",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={4.5}>
            <Grid container spacing={3}></Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={12}>
                <Projects />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {/*  <Footer /> */}
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
