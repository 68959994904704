// react-router components
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbarC";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import FormDialogo from "layouts/cuidadores/FormDialogo";
// Data
import cuidadorTableData from "../data/cuidadorTableData";
import dataState from "../../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";

function CuidadoresInformation() {
  const DataContext = useContext(dataState);
  const { handleeliCuidadores, setOpendialog, opendialog, setErrorSB, errorSB, infoSB, setInfoSB } =
    DataContext;
  const closeErrorSB = () => setErrorSB(false);
  const closeInfoSB = () => setInfoSB(false);
  const navigate = useNavigate();
  const { columns, rows } = cuidadorTableData();

  const handleClose = () => {
    setOpendialog(false);
  };

  const handleQuitar = () => {
    handleeliCuidadores();
    setOpendialog(false);
  };

  const DialogoEliminar = () => (
    <>
      <Dialog
        open={opendialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: "#1a73e8" }}>
          {"Eliminar"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontSize: "24px" }}>
            ¿Esta seguro que desea eliminar este Cuidador?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleQuitar} autoFocus>
            Aceptar, Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const renderInfoSB = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content="El registro de la personas en condición de discapacidad se elimino satisfatoriamente..."
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={
        "El registro no se elimino por que hay personas en condición de discapacidad vinculada"
      }
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Información Cuidadores
        </MDTypography>
      </MDBox>
      <MDBox pt={2} px={2} display="flex-1" justifyContent="space-between" alignItems="center">
        <MDButton
          variant="outlined"
          color="info"
          onClick={() => navigate("/form_Cuidador")}
          fullWidth
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;Agregar Nuevo Cuidador
        </MDButton>
        <MDTypography variant="h6" fontWeight="medium">
          &nbsp;
        </MDTypography>
      </MDBox>
      <DashboardNavbar />
      <MDBox pt={1} pb={2} px={2}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={false}
          noEndBorder
        />
      </MDBox>
      <DialogoEliminar />
      <FormDialogo />
      {renderInfoSB}
      {renderErrorSB}
    </Card>
  );
}

export default CuidadoresInformation;
