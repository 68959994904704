/* eslint-disable react/jsx-key */
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { InputLabel, NativeSelect } from "@mui/material";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import dataState from "../../../../context/data/dataContext";
import MDSnackbar from "components/MDSnackbar";

function UsuarioForm() {
  const DataContext = useContext(dataState);
  const {
    perfiluser,
    handlePerfilUser,
    handleSubmitUsuario,
    handleUsuarios,
    enviando,
    msgSB,
    errorSB,
    setErrorSB,
    infoSB,
    infoEditSB,
    setInfoSB,
    setInfoEditSB,
  } = DataContext;
  //const { id } = useParams();
  const closeErrorSB = () => setErrorSB(false);
  const closeInfoSB = () => setInfoSB(false);
  const closeInfoEditSB = () => setInfoEditSB(false);
  const [controller] = useMaterialUIController();

  useEffect(() => {
    handlePerfilUser();
  }, []);

  useEffect(() => {
    if (enviando === false) {
      handleUsuarios();
    }
  }, [enviando]);

  const formik = useFormik({
    initialValues: {
      Id_perfil: "",
      Documentos: "",
      Nombres: "",
      Usuario: "",
    },
    validationSchema: Yup.object({
      Documentos: Yup.string().required("El documento no puede ir vacio"),
      Nombres: Yup.string().required("El Nombre no puede ir vacio"),
      Id_perfil: Yup.string().required("Seleccione el perfil"),
      Usuario: Yup.string().required("El Usuario es Obligatorio"),
    }),
    onSubmit: (datosform) => {
      handleSubmitUsuario(datosform, formik);
    },
  });

  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Notificación"
      content="El usuario se agrego satisfatoriamente..."
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderInfoEditSB = (
    <MDSnackbar
      color="info"
      icon="check"
      title="Notificación"
      content="El usuario se actualizo satisfatoriamente..."
      open={infoEditSB}
      onClose={closeInfoEditSB}
      close={closeInfoEditSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={msgSB}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <Card>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Formulario de Usuarios
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDBox
                    xs={12}
                    md={12}
                    component="form"
                    role="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <MDBox pt={4} pb={3} px={3}>
                      <Grid container spacing={1}>
                        <Grid item xs={6} md={4}>
                          <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Perfil Usuario
                          </InputLabel>
                          <NativeSelect
                            defaultValue={""}
                            inputProps={{ name: "Id_perfil", id: "Id_perfil" }}
                            value={formik.values.Id_perfil}
                            onChange={formik.handleChange}
                            fullWidth
                          >
                            <option key={"validationCustom0013"} value={""}>
                              Seleccione...
                            </option>
                            {perfiluser?.length === 0 ? (
                              <option key={"validationCustom0014"} value={""}>
                                Seleccione...
                              </option>
                            ) : (
                              perfiluser?.map((item) => (
                                <option key={item.ID} value={item.ID}>
                                  {item.NOMBRE}
                                </option>
                              ))
                            )}
                          </NativeSelect>
                          {formik.touched.Id_perfil && formik.errors.Id_perfil ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Id_perfil} </p>
                            </MDTypography>
                          ) : null}
                        </Grid>
                        <Grid item xs={6} md={7}>
                          <MDInput
                            type="text"
                            label="Usuario/Email"
                            variant="standard"
                            name="Usuario"
                            value={formik.values.Usuario}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.touched.Usuario && formik.errors.Usuario ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Usuario} </p>
                            </MDTypography>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} style={{ marginTop: "12px" }}>
                        <Grid item xs={6} md={4}>
                          <MDInput
                            type="text"
                            label="Documento(Cedula o Nit)"
                            variant="standard"
                            id="Documentos"
                            name="Documentos"
                            value={formik.values.Documentos}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.touched.Documentos && formik.errors.Documentos ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Documentos} </p>
                            </MDTypography>
                          ) : null}
                        </Grid>
                        <Grid item xs={6} md={7}>
                          <MDInput
                            type="text"
                            label="Nombres/Razon Social"
                            variant="standard"
                            name="Nombres"
                            value={formik.values.Nombres}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.touched.Nombres && formik.errors.Nombres ? (
                            <MDTypography variant="subtitle2" color="error">
                              <p style={{ fontSize: "12px" }}>{formik.errors.Nombres} </p>
                            </MDTypography>
                          ) : null}
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox
                      pt={2}
                      px={2}
                      display="flex-1"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDButton type="submit" variant="gradient" color="success" fullWidth>
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        {enviando === false ? "Agregar Usuario" : "Enviando Usuario..."}
                      </MDButton>
                      <MDTypography variant="h6" fontWeight="medium">
                        &nbsp;
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {renderInfoSB}
      {renderInfoEditSB}
      {renderErrorSB}
    </Card>
  );
}

export default UsuarioForm;
