import { useState, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dataState from "../../../../context/data/dataContext";

function PlatformSettings(Props) {
  const DATA = Props.DATA;
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2} style={{ backgroundColor: "#4971f18a", textAlign: "center" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          DIFICULTADES Y LIMITACIONES
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          DIFICULTADES PERMANENTES:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].DIFICULTADES_PERMANENTES}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox p={1} style={{ backgroundColor: "#fff", with: "100%", textAlign: "left" }}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          LIMITACIONES PARA EL DESEMPEÑO DE ACTIVADES Y PARTICIPACIÓN:&nbsp;&nbsp;
          <MDTypography variant="button" color="text" fontWeight="regular">
            {DATA[0].LIMITACIONES_ACTIVIDADES_PARTICIPACION}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
