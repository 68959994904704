import { Axios } from "../config/clienteAxios";

export const getUsuarios = async () => {
  try {
    const { data } = await Axios.get(`/usuarios/view-usuario`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getPerfilUser = async () => {
  try {
    const { data } = await Axios.get(`/usuarios/view-usuario-roll`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const deleteUsersId = async (Id) => {
  try {
    const { data } = await Axios.delete(`/usuarios/delete-usuario/${Id}`);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getDiscapacitados = async () => {
  try {
    const { data } = await Axios.get(`/discapacitados/view-discapacitado`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getCaracterizacion = async () => {
  try {
    const { data } = await Axios.get(`/caracterizacion/view-caracterizacion`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getDiscapacitadosId = async (Id) => {
  try {
    const { data } = await Axios.get(`/discapacitados/view-discapacitado/${Id}`);
    return data.datos;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const deleteDiscapacitadoId = async (Id) => {
  try {
    const { data } = await Axios.delete(`/discapacitados/delete-discapacitado/${Id}`);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getDiscapacitadosDocNom = async (dato) => {
  try {
    const { data } = await Axios.get(
      `/discapacitados/view-discapacitadosDocNombres/${dato}/${dato}/${dato}`
    );
    return data.datos;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getCuidadores = async () => {
  try {
    const { data } = await Axios.get(`/cuidador/view-cuidador`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getCuidadoresId = async (Id) => {
  try {
    const { data } = await Axios.get(`/cuidador/view-cuidador/${Id}`);
    return data.datos;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const deleteCuidadoresId = async (Id) => {
  try {
    const { data } = await Axios.delete(`/cuidador/delete-cuidador/${Id}`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getMunicipio = async () => {
  try {
    const { data } = await Axios.get(`/parametros/view-municipio`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getCorregimiento = async () => {
  try {
    const { data } = await Axios.get(`/parametros/view-corregimiento`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getVeredas_barrios = async () => {
  try {
    const { data } = await Axios.get(`/parametros/view-veredas_barrios`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getEscolaridad = async () => {
  try {
    const { data } = await Axios.get(`/parametros/view-escolaridad`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getTipodocumento = async () => {
  try {
    const { data } = await Axios.get(`/parametros/view-tipodocumento`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getOrientacionSexual = async () => {
  try {
    const { data } = await Axios.get(`/parametros/view-orientacion_sexual`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getEtnias = async () => {
  try {
    const { data } = await Axios.get(`/parametros/view-etnias`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const getParentesco = async () => {
  try {
    const { data } = await Axios.get(`/parametros/view-parentesco`);
    return data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};
